import * as actions from '../actions/targets.actions'
import { produce } from 'immer'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

const targetInit = {
  list: [],
}

const find = (list, id) => (list || []).find(item => item.id === id)
const findIndex = (list, id) => (list || []).findIndex(item => item.id === id)

const targetReudcer = produce((state, { type, data }) => {
  const { list } = state
  switch (type) {
    case actions.UPDATE_TARGETS:
      state.list = data
      break
    case actions.ADD_TARGET:
      list.push(data)
      break
    case actions.DELETE_TARGET:
      const idx = findIndex(list, data.targeting_id)
      idx !== -1 && list.splice(idx, 1)
      break
    case actions.MODIFY_TARGET: {
      const { id, targeting_id = id, ...rest } = data
      const target = find(list, targeting_id)
      target && Object.assign(target, rest)
      break
    }
    case actions.ADD_PERSONA: {
      const target = find(list, data.targeting_id)
      target?.personas.push(data)
      break
    }
    case actions.MODIFY_PERSONA: {
      const target = find(list, data.targeting_id)
      const idx = findIndex(target?.personas, data.persona_id)
      idx !== -1 && (target.personas[idx] = data)
      break
    }
    case actions.DELETE_PERSONA: {
      const target = find(list, data.targeting_id)
      const idx = findIndex(target?.personas, data.persona_id)
      idx !== -1 && target.personas.splice(idx, 1)
      break
    }
    default:
      break
  }
}, targetInit)

export default targetReudcer

const selector = createSelector(
  state => state.target.list,
  targets => targets.filter(target => target.adcard_number)
)

export const useActiveTargets = () => useSelector(selector)
