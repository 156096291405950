import { request } from './request'

export function fetchTargetList() {
  return request({ url: '/targeting/list' })
}

export function fetchSuggest(type, keyword) {
  return request({
    url: '/targeting/suggest',
    params: { type, keyword },
    cancelable: true,
  })
}

export function createTarget(data) {
  return request({
    method: 'POST',
    url: '/targeting/create',
    data,
  })
}

export function updateTarget(action, data) {
  return request({
    method: 'POST',
    url: '/targeting/update',
    params: { action },
    data,
  })
}

export function renameTarget(id, name) {
  return updateTarget('rename', { id, name })
}

export function updateTargetStatus(id, action) {
  return request({
    url: '/targeting/status',
    params: { id, action },
  })
}

export function deleteTarget(id) {
  return request({
    url: '/targeting/delete',
    params: { id },
  })
}

// ========== deprecated =============

export function submitTarget(data) {
  return request({
    method: 'POST',
    url: '/targeting/submit',
    data,
  })
}

export function replaceTarget(old_targeting_id, new_targeting_id) {
  return request({
    method: 'POST',
    url: '/targeting/replace',
    data: { old_targeting_id, new_targeting_id },
  })
}

export function submitPersonal(data) {
  return request({
    method: 'POST',
    url: '/targeting_persona/submit',
    data,
  })
}

export function deletePersona(persona_id) {
  return request({
    url: '/targeting_persona/delete',
    params: { persona_id },
  })
}

export function submitTargetMarket(data) {
  return request({
    method: 'POST',
    url: '/targeting/market_submit',
    data,
  })
}

export function getTestingWeight(targeting_id) {
  return request({
    url: '/targeting/testing_weight',
    params: { targeting_id },
  })
}
