import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import { Empty } from 'antd'
import cls from 'classnames'
import styles from './index.module.scss'
import { useLocation, useHistory, Prompt } from 'react-router-dom'
import { useReactive, useRequest, useMemoizedFn } from 'ahooks'
import { useSelector, useDispatch } from 'react-redux'
import Lottie from 'lottie-react'
import robot from './robot.json'
import { updateGroups } from '@/store/actions/user.actions'
import {
  Strategy,
  Onboard,
  Strategist,
  Sidebar,
  Precreate,
  PrecreatedCampaigns,
} from './components'
import * as _ from '@/utils'
import Icons from './icons'
import { CContext as C } from '@/context'
import * as api from '@/service'
import { useSubscribe } from '@/subscribe'
import { capitalize } from 'lodash'

export default () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { is_started_group, is_pending, is_started, meta, google } =
    useSelector(state => state.user.current)
  const { ev } = React.useContext(C)

  const bm = React.useRef(null)
  const state = useReactive({
    type: -1,
    currentChatType: 'strategy',
    currentSessionId: '',
    precreate: null,
    precreateHasChange: false,
  })
  const visibleStrategist =
    state.currentChatType == 'strategy' && is_started_group
  const showPrecreate = visibleStrategist && !!state.precreate
  const showPrecreatedCampaigns = state.currentChatType === 'campaign'

  const {
    loading: loadingOrderList,
    runAsync: fetchOrderList,
    data: orderList = [],
    mutate: setOrderList,
  } = useRequest(async () => {
    if (!is_started_group) return
    const { data } = await api.fetchOrderList()
    return data
  })

  const {
    loading: loadingOrder,
    runAsync: fetchOrder,
    data: campaigns = [],
    mutate: setCampaigns,
  } = useRequest(
    async order_id => {
      const { data } = await api.fetchOrder(order_id)
      return data
    },
    { manual: true }
  )

  useSubscribe(({ type }) => {
    if (type === 'group_change') {
      state.currentChatType = 'strategy'
      state.currentSessionId = ''
      setOrderList([])
      fetchOrderList()
    }
  })

  useEffect(() => {
    ;(
      window.__BEFORE_GROUP_CHANGE_HOOKS__ ||
      (window.__BEFORE_GROUP_CHANGE_HOOKS__ = [])
    ).push(checkPrecreate)

    ev.on('precreate', data => {
      state.precreate = data
      state.precreateHasChange = false
    })
    ev.on('precreateSuccess', order_id => {
      state.precreate = null
      fetchOrderList()
      handleChange(order_id)
    })

    return history.listen(() => {
      if (state.precreate) {
        state.precreate = null
      }
    })
  }, [])

  useEffect(() => {
    if (!is_started || !_.getToken()) {
      state.type = 1
      return
    }
    if (!is_started_group) {
      state.type = 2
      return
    }
    state.type = -1
    state.currentChatType = 'strategy'
  }, [is_started_group, is_started])

  const checkPrecreate = useMemoizedFn(async () => {
    if (!showPrecreate) return
    if (state.precreateHasChange) {
      await _.Modal.confirm({
        type: 'delete',
        title: 'Are you sure to leave?',
        subtitle: 'Your changes may not have been saved',
      })
    }
    state.precreate = null
  })

  const handleChange = async (id, category = 'campaign') => {
    if (state.currentSessionId === id) return
    await checkPrecreate()
    state.currentChatType = category
    state.currentSessionId = id
    setCampaigns([])
    fetchOrder(id)
    bm.current && (bm.current.scrollTop = 0)
  }

  const Li = ({ v }) => {
    const _html = (
      <div
        onClick={() => handleChange(v.order_id)}
        key={v.order_id}
        className={cls(
          styles.li,
          {
            [styles.active]: state.currentSessionId == v.order_id,
            [styles.unread]: v.unread && state.currentSessionId != v.order_id,
          },
          styles.campaignTheme
        )}
      >
        {/* <Icons.Delete
          className={styles.delete}
          onClick={e => handleDelete(e, v)}
        /> */}

        <div className={styles.title}>
          <span className={styles.name}>{v.stage_name}</span>
          {v.platform === 'meta' ? <Icons.NewMeta2 /> : <Icons.NewGoogle2 />}
        </div>
        <div className={styles.type}>
          <span>{v.completed_by}</span>
          <div className={styles.typeIcon}>
            <Icons.CreateIcon1 className={styles.idea} />
            {capitalize(v.type)}
          </div>
        </div>
      </div>
    )

    return _html
  }

  return (
    <>
      {showPrecreate && state.precreateHasChange && (
        <Prompt message="Are you sure to leave?__Your changes may not have been saved" />
      )}
      <div className={styles.chat}>
        <div className={styles.left}>
          <div className={styles.leftMain}>
            <div className={cls(styles.actions)}>
              {!is_started ? (
                <div className={styles.line}>
                  <div
                    className={cls(styles.btn, styles.active)}
                    onClick={() => (state.type = 1)}
                  >
                    {/* <Lamp /> */}
                    <Icons.Chat />
                    <span>Onboard Assistant</span>
                  </div>
                </div>
              ) : !is_started_group ? (
                <div
                  className={cls(styles.line, {
                    // [styles.inuse]: !is_started_group,
                  })}
                >
                  <div
                    className={styles.btn}
                    onClick={() => {
                      state.type = 2
                    }}
                  >
                    <Icons.Chat />
                    <span>Account Strategist</span>
                  </div>
                </div>
              ) : (
                <div className={styles.line}>
                  <div
                    className={cls(styles.btn, {
                      [styles.active]: state.currentChatType == 'strategy',
                    })}
                    onClick={async () => {
                      await checkPrecreate()
                      state.currentChatType = 'strategy'
                      state.currentSessionId = ''
                    }}
                  >
                    {/* <Lamp /> */}
                    <div className={styles.icons}>
                      <Icons.Chat />
                    </div>
                    <span>Account Strategist</span>
                  </div>
                </div>
              )}
            </div>
            {is_started_group && (
              <div className={styles.notice}>
                <div className={styles.noticeTitle}>
                  <div className={styles.tleft}>
                    Inbox
                    {orderList.some(v => v.unread) && (
                      <span>{orderList.filter(v => v.unread).length}</span>
                    )}
                  </div>
                </div>
                <div
                  className={cls(styles.list, {
                    [styles.loading]: loadingOrderList,
                  })}
                >
                  {orderList.map(v => (
                    <div key={v.id}>
                      <Li v={v} />
                    </div>
                  ))}
                  {loadingOrderList ? (
                    <img
                      src={Icons.Chatloading2}
                      className={styles.sessionLoading}
                    />
                  ) : !orderList.length ? (
                    <Empty />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <div ref={bm} className={styles.main}>
            {state.type == 1 ? (
              <Onboard />
            ) : state.type == 2 ? (
              <Strategy>
                <Lottie animationData={robot} />
              </Strategy>
            ) : showPrecreate ? (
              <Precreate
                platform={state.precreate.platform}
                stage={state.precreate.stage_type}
                onCancel={checkPrecreate}
                onChange={() => (state.precreateHasChange = true)}
              />
            ) : showPrecreatedCampaigns ? (
              <PrecreatedCampaigns data={campaigns} loading={loadingOrder} />
            ) : visibleStrategist ? (
              <Strategist />
            ) : null}
          </div>
        </div>
        {visibleStrategist && !state.precreate && <Sidebar />}
      </div>
    </>
  )
}
