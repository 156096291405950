import { useMemo, useRef, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useActivate, useUnactivate } from 'react-activation'
import {
  useEventListener,
  useUpdateEffect,
  useInViewport,
  useMemoizedFn,
} from 'ahooks'
import useEffectWithTarget from 'ahooks/es/utils/useEffectWithTarget'
import { getTargetElement } from 'ahooks/es/utils/domTarget'
import { shallowEqual } from 'react-redux'

export function useQuery(name) {
  const { search } = useLocation()
  const query = useMemo(() => {
    const obj = {}
    new URLSearchParams(search).forEach((val, key) => (obj[key] = val))
    return obj
  }, [search])

  return name === undefined ? query : query[name]
}

export const useActivated = () => {
  const activated = useRef(true)
  useActivate(() => (activated.current = true))
  useUnactivate(() => (activated.current = false))
  return activated
}

export const useActivatedState = () => {
  const [activated, setActivated] = useState(true)
  // useActivate(() => setActivated(true))
  // useUnactivate(() => setActivated(false))
  const history = useHistory()
  const pathname = history.location.pathname
  useEffect(
    () =>
      history.listen(location => setActivated(location.pathname === pathname)),
    []
  )
  return activated
}

export const createSubscription = () => {
  const subscribers = new Set([])

  const publish = (...args) => {
    subscribers.forEach(fn => fn(...args))
  }

  const useSubscribe = fn => {
    const fnRef = useRef()
    fnRef.current = fn

    useEffect(() => {
      const subscriber = (...args) => fnRef.current(...args)
      subscribers.add(subscriber)

      return () => subscribers.delete(subscriber)
    }, [])
  }

  return { publish, useSubscribe }
}

export const useVideoPlayer = () => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isStarted, setIsStarted] = useState(false)

  const play = () => {
    videoRef.current?.play()
  }

  const pause = () => {
    videoRef.current?.pause()
  }

  const togglePlay = () => {
    if (isPlaying) {
      pause()
    } else {
      play()
    }
  }

  useEventListener(
    'play',
    () => {
      setIsPlaying(true)
      setIsStarted(true)
    },
    { target: videoRef }
  )
  useEventListener('pause', () => setIsPlaying(false), { target: videoRef })
  useEventListener(
    'ended',
    () => {
      setIsPlaying(false)
      setIsStarted(false)
    },
    { target: videoRef }
  )

  return { videoRef, isPlaying, isStarted, play, pause, togglePlay }
}

export const useTrackState = source => {
  const [value, setValue] = useState(source)
  useUpdateEffect(() => setValue(source), [source])
  return [value, setValue]
}

export const useOverflow = ({ root, length }) => {
  const edgeEls = useRef()
  edgeEls.current = []
  const setEdgeEl = (el, index) => {
    if ((index === 0 || index === length - 1) && el) {
      edgeEls.current[index && 1] = el
    }
  }
  const [v1] = useInViewport(() => edgeEls.current[0], { threshold: 1, root })
  const [v2] = useInViewport(() => edgeEls.current[1] || edgeEls.current[0], {
    threshold: 0.98,
    root,
  })
  const overflow = !length
    ? ''
    : !v1
    ? !v2
      ? 'both'
      : 'start'
    : !v2
    ? 'end'
    : ''

  return { overflow, edgeEls, setEdgeEl }
}

export const useShallow = value => {
  const ref = useRef()
  if (!shallowEqual(ref.current, value)) {
    ref.current = value
  }
  return ref.current
}

export const useWatch = (source, handler, immediate = false) => {
  const fn = useMemoizedFn(handler)
  const prev = useRef()
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current || immediate) {
      fn(source, prev.current)
      prev.current = source
    }
    mounted.current = true
  }, [source])
}

export const useCssVar = (
  name,
  target,
  { initialValue, resetOnUnmount = false } = {}
) => {
  const [value, setValue] = useState(initialValue)
  const targetRef = useRef()
  useEffectWithTarget(
    () => {
      const targetElement = (targetRef.current = getTargetElement(
        target,
        document.documentElement
      ))
      if (!targetElement?.style) return
      const initialStyleVal = targetElement.style.getPropertyValue(name)
      const initialComputedVal = getComputedStyle(targetElement)
        .getPropertyValue(name)
        ?.trim()

      if (initialValue) {
        targetElement.style.setProperty(name, initialValue)
      } else if (initialComputedVal) {
        setValue(initialComputedVal)
      }

      return () => {
        if (resetOnUnmount) {
          initialStyleVal
            ? targetElement.style.setProperty(name, initialStyleVal)
            : targetElement.style.removeProperty(name)
        }
      }
    },
    [],
    target
  )

  useUpdateEffect(
    () => targetRef.current?.style.setProperty(name, value),
    [value]
  )

  return [value, setValue]
}
