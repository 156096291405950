import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import 'animate.css'

const AnimatedSwitch = ({ children }) => {
  const location = useLocation()

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames={{
          enter: 'animate__animated',
          enterActive: 'animate__fadeIn',
          exit: 'animate__animated',
          exitActive: 'animate__fadeOut',
        }}
        timeout={500}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default AnimatedSwitch
