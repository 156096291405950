import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { Container } from './styled'
import Message from '@/utils/message'
import { request, userConnectPlatform } from '@/service'
import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory()
  const getPlatform = () => {
    const url = location.href.toLocaleLowerCase()
    return url.includes('google')
      ? 'google'
      : url.includes('tiktok')
      ? 'tiktok'
      : 'shopify'
  }

  const doConnect = async () => {
    const { message } = await request({
      method: 'POST',
      url: userConnectPlatform,
      data: {
        platform: getPlatform(),
        link: location.href,
      },
    })
    if (message) {
      console.log(message)
      if (getPlatform() === 'shopify') {
        alert('Shopify ' + message)
        return history.replace('/profile')
      }
      window.close()
    } else {
      Message.warn("Connecting didn't work. Please try again.")
    }
  }

  useEffect(() => {
    setTimeout(() => {
      console.log('Start api call')
      doConnect()
    }, 500)
  }, [])

  return (
    <Container>
      <Spin tip="Loading" size="large" />
    </Container>
  )
}
