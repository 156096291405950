import React, { memo, useEffect } from 'react'
import styles from './index.module.scss'
import { Button, Form, Input, Radio, Space, Select } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import { useReactive, useEventEmitter } from 'ahooks'
import {
  request,
  userSettingsUpdate,
  userCancel,
  userConnectPlatform,
} from '@/service'
import cls from 'classnames'
import Message from '@/utils/message'
import fb from '@/assets/images/profile/facebook.png'
import Check from 'ico/check.svg'
import Success from 'ico/success.svg'
import ShopifyLogo from 'ico/shopify.svg'
import sha1 from 'sha1'
import { getToken, setToken } from '@/utils/auth'
import * as _ from '@/utils'
const { Fbevent } = _

const {
  REACT_APP_ENV: ENV,
  REACT_APP_SHOPIFY_URL,
  REACT_APP_SHOPIFY_ID,
} = process.env
const { TextArea } = Input
const { Option } = Select

const Footer = memo(
  ({
    onClose,
    onOk,
    loading = false,
    text,
    className = '',
    disabled = false,
    cancel = true,
  }) => {
    return (
      <div className={cls(styles.modalMainFooter, className)}>
        {cancel && (
          <Button
            type="primary"
            shape="round"
            size="large"
            className={styles.modalMainFooterCancel}
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
        <Button
          type="primary"
          shape="round"
          size="large"
          disabled={disabled}
          loading={loading}
          className={styles.modalMainFooterSave}
          onClick={onOk}
        >
          {text}
        </Button>
      </div>
    )
  }
)

export const ModalMain = memo(
  ({
    title = 'Default Title',
    initVals,
    type = '',
    cancel = true,
    onClose = () => {},
    info = {},
  }) => {
    const [form] = Form.useForm()
    const event$ = useEventEmitter()
    const state = useReactive({ disabled: false })
    const { loading = false, hideTitle = false, text = 'Save' } = state

    const CmMain = () => {
      switch (type) {
        case 'shopify':
          return <Shopify state={state} />
        case 'fb':
          return <Account state={state} />
        case 'billing':
          return <Billing event={event$} form={form} onSuccess={onSuccess} />
        case 'subsription':
          return <Buy info={info} />
        case 'profile':
          return <Profile />
        case 5:
          return <Sad />
      }
    }

    const onSuccess = () => {
      handleSubmit()
    }

    const handleSubmit = async () => {
      if (type == 'shopify' && form.getFieldValue('website')) {
        console.log('Start Shopify')
        location.href = `https://${form.getFieldValue(
          'website'
        )}.myshopify.com/admin/apps/cactivate`
        return
      }
      if (type == 'fb') {
        if (state.updateFb) location.href = location.origin
        return
      }
      if (type == 'billing' && !form.getFieldValue('stripe_token')) {
        return event$.emit('getToken')
      }
      if (
        type == 'profile' &&
        form.getFieldValue('passwd') != form.getFieldValue('passwd2')
      )
        return Message.warn('The two passwords entered are different')
      if (type == 'profile' && form.getFieldValue('passwd')?.length < 8)
        return Message.warn('The password must be at least 8 digits')

      await form.validateFields().catch(err => {
        Message.warn('Password can not be empty')
        return Promise.reject(err)
      })

      state.loading = true
      try {
        let data = {
          data:
            type == 'profile'
              ? { passwd: sha1(form.getFieldValue('passwd')) }
              : form.getFieldsValue(true),
        }
        data =
          type != 5
            ? { ...data, rtype: type }
            : {
                reason: Object.values(data.data)[
                  Object.values(data.data).length - 1
                ],
              }
        const { message, error } = await request({
          method: 'POST',
          url: type == 5 ? userCancel : userSettingsUpdate,
          data,
        })
        state.loading = false
        if (type == 'billing' && message == 'Successfully Updated!') {
          event$.emit('onSuccess')
          state.hideTitle = true
          return
        }
        onClose()
        Message.success(message)
        if (type == 'profile' && message == 'Successfully Updated!') {
          setToken(getToken().split(',')[0] + ',' + data.data.passwd)
        }

        if (type == 5 && !error) {
          location.replace('/')
        }
      } catch {
        state.loading = false
      }
    }

    return (
      <div className={styles.modalMain}>
        {type != 'fb' && !hideTitle && (
          <div className={styles.modalMainTitle}>
            <span>{title}</span>
          </div>
        )}
        <div className={styles.modalMainBox}>
          <Form layout="vertical" form={form} initialValues={initVals}>
            {CmMain()}
            {type != 5 && !hideTitle && (
              <Footer
                onClose={onClose}
                onOk={handleSubmit}
                loading={loading}
                cancel={cancel}
                disabled={state.disabled}
                text={text}
              />
            )}
            <div
              className={cls(styles.modalMainFooter, { ['hide']: type != 5 })}
            >
              <span className={styles.modalMainFooterTip} onClick={onClose}>
                Nevermind, I don’t want to cancel.
              </span>
              <Button
                type="primary"
                shape="round"
                size="large"
                loading={loading}
                onClick={handleSubmit}
                className={styles.modalMainFooterSave}
              >
                Cancel Service
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }
)

export const Account = ({ state }) => {
  const handleChange = async e => {
    if (ENV == 'dev') {
      const {
        message,
        data: { result },
      } = await request({
        method: 'POST',
        url: userConnectPlatform,
        data: {},
      })

      if (message == 'connected' && result) {
        state.updateFb = true
      }
      return
    }
    FB ?? Fbevent.init()
    Fbevent.do_facebook(() => {
      state.updateFb = true
    })
  }

  return (
    <div className={styles.fb}>
      <h3>You have updated your password, please reconnect</h3>

      <div className={styles.fbAccount} onClick={handleChange}>
        <img src={fb} />
        <span>Facebook</span>
        <em />
      </div>
      <div className={styles.fbAccount}>
        <span>Connected</span>
        <em className={cls({ [styles.active]: state.updateFb })}>
          <Check />
        </em>
      </div>
      {/* <Radio.Group name="radiogroup" onChange={handleChange} defaultValue={0}>
        <Radio value={1}>facebook</Radio>
        <Radio value={2}>Connected</Radio>
      </Radio.Group> */}
    </div>
  )
}

export const DefaultSetting = () => {
  return (
    <>
      <Form.Item
        label="Objective:"
        name="objective"
        rules={[{ required: true, message: 'Cannot be empty!' }]}
      >
        <Select
          getPopupContainer={triggerNode => triggerNode.parentNode}
          defaultOpen
        >
          <Option value="OUTCOME_LEADS">OUTCOME_LEADS</Option>
          <Option value="CONVERSIONS">CONVERSIONS</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Pixel ID:"
        name="pixel_id"
        rules={[{ required: true, message: 'Cannot be empty!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Pixel Event:"
        name="pixel_event"
        rules={[{ required: true, message: 'Cannot be empty!' }]}
      >
        <Input />
      </Form.Item>
    </>
  )
}

export const Billing = ({ event, form, onSuccess }) => {
  const token =
    ENV == 'dev'
      ? 'pk_test_51NNdfME6P0ymZMbfkas7iYlRWgksEX5m7sKd2DPDQLpD5okP4dX0ZMqv53DQQCNFLnq1T1JZorqdYrAWxOz9SY8A00uJGHLuDx'
      : 'pk_live_c440NmerulW2YkACJoSEEnQx'

  const state = useReactive({})

  const { stripe_instance = null, stripe_card = null, success = false } = state

  useEffect(() => {
    !(async () => {
      state.stripe_instance = await loadStripe(token)
      state.stripe_card = state.stripe_instance.elements().create('card')
      state.stripe_card.mount('#modal-main-card')
      state.stripe_card.addEventListener('change', event => {
        if (event.error) {
          state.error = true
          state.errorMess = event.error.message
          return
        }
        state.error = false
        state.errorMess = ''
      })
    })()
  }, [])
  event.useSubscription(val => {
    if (val == 'getToken') {
      if (!stripe_instance) return
      stripe_instance.createToken(stripe_card).then(function (result) {
        if (result.error) {
          Message.warn(result.error.message)
        } else {
          if (!result.token) {
            Message.warn('Error. Please try again.')
          } else {
            form.setFieldValue('stripe_token', result.token)
            onSuccess()
          }
        }
      })
      return
    }
    state.success = true
  })
  return (
    <>
      {!success ? (
        <Form.Item label="Credit Card:" name="stripe_token">
          <div className={styles.modalMainCard} id="modal-main-card" />
        </Form.Item>
      ) : (
        <div className={styles.billingSuccess}>
          <Success />
          <p>Payment successful</p>
          <Button
            type="primary"
            shape="round"
            size="large"
            className={styles.modalMainFooterSave}
            onClick={() => {
              location.href = location.origin
            }}
          >
            Confirm
          </Button>
        </div>
      )}
    </>
  )
}

export const Buy = ({ info }) => {
  return (
    <>
      <Form.Item
        label="Credits:"
        name="credits"
        rules={[{ required: true, message: 'Cannot be empty!' }]}
      >
        <Input />
      </Form.Item>
      <div className={styles.modalMainTotal}>
        Total: <span>{info?.subsription?.total_due}</span>
      </div>
    </>
  )
}

export const Profile = () => {
  return (
    <>
      <Form.Item
        label="Password:"
        name="passwd"
        rules={[
          { required: true, message: 'Cannot be empty!' },
          {
            min: 8,
            message: 'The password must be at least 8 characters!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password:"
        name="passwd2"
        rules={[
          { required: true, message: 'Cannot be empty!' },
          {
            min: 8,
            message: 'The password must be at least 8 characters!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  )
}

export const Sad = () => {
  const state = useReactive({})
  const { other = false } = state

  return (
    <div className={styles.modalMainSad}>
      <div className={styles.modalMainDesc}>
        Before you cancel, please let us know the reason you are leaving. Every
        bit of feedback helps!
      </div>
      <Form.Item name="reason" initialValue="Technical issues">
        <Radio.Group
          onChange={e => {
            state.other = e.target.value == 'Other'
          }}
        >
          <Space direction="vertical">
            <Radio value="Technical issues">Technical issues</Radio>
            <Radio value="Too expensive">Too expensive</Radio>
            <Radio value="Switching to another product">
              Switching to another product
            </Radio>
            <Radio value="Shutting down the company">
              Shutting down the company
            </Radio>
            <Radio value="Not sure how to use the data & tools">
              Not sure how to use the data & tools
            </Radio>
            <Radio value="Missing features I need">
              Missing features I need
            </Radio>
            <Radio value="Other">Other (please explain below)</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {other && (
        <Form.Item name="other">
          <TextArea
            rows={4}
            placeholder="Anything you want to share? (Optional)"
          />
        </Form.Item>
      )}
    </div>
  )
}

export const Shopify = ({ state }) => {
  useEffect(() => {
    state.disabled = true
    state.text = 'Connect'
  }, [])

  const handleChange = e => {
    state.disabled = !e.target.value
  }

  return (
    <div className={styles.shopify}>
      <Form.Item
        label={
          <div className={styles.shopifyLable}>
            <ShopifyLogo />
            <span>Shopify website</span>
          </div>
        }
        name="website"
      >
        <Input
          placeholder="ShopifyId"
          suffix="shopify.com"
          onChange={handleChange}
        />
      </Form.Item>
    </div>
  )
}
