import React from 'react'
import { Button, Switch } from 'antd'
import { useSelector } from 'react-redux'
import * as api from '@/service'
import Lamp from 'ico/start/lamp.svg'
import Meta from 'ico/meta.svg'
import NextFinsh from 'ico/start/next-finsh.svg'
import NoAllow from 'ico/start/no-allow.svg'
import cls from 'classnames'
import CreationModal from '@/components/v3-create'
import styles from './index.module.scss'

export default function Item({
  index,
  type = 'synced',
  onChange = () => {},
  onOk = () => {},
  ...v
}) {
  const { currency_symbol } = useSelector(state => state.user.current)
  const [checked, setCheck] = React.useState(v.performance)
  const [addAudience, setAddAudience] = React.useState(false)

  // const handleSwitch = async e => {
  //   setCheck(e)
  //   await api.request({
  //     url: api.campaignAction,
  //     params: {
  //       id: v.id,
  //       action: e ? 'on' : 'off',
  //     },
  //   })
  // }

  return (
    <div className={styles.item}>
      <div className={cls(styles.theme, styles.cloum)}>
        <div className="ellipsis">
          {type == 'launch' && <span>#{index + 1}</span>}
          {v.name}
        </div>
      </div>
      <div className={cls(styles.type, styles.cloum)}>
        {v.is_imported ? (
          <p className={cls(styles.themeTag, styles.synced)}>
            Synced
            <Meta />
          </p>
        ) : (
          <p className={styles.themeTag}>
            {v.campaign_type}
            <Lamp className="fill-color" />
          </p>
        )}
      </div>
      <div className={cls(styles.text, styles.cloum)}>
        {type == 'synced' ? (
          <div className={styles.desc}>
            This campaign is performing relatively
            <span
              className={cls(v.performance ? styles.average : styles.below)}
            >
              {v.performance ? ' above average' : ' below average'}
            </span>
            , We suggest you {v.performance ? 'import ' : 'pause '}
            this campaign
          </div>
        ) : (
          <div className={styles.desc}>{v.description}</div>
        )}
        {/* {type == 'launch' && (
          <div className={styles.average}>above average</div>
        )} */}
      </div>
      <div className={cls(styles.right, styles.cloum)}>
        {type == 'synced' && (
          <>
            {v.performance == -1 ? (
              <span className={styles.noAllow}>
                Not allow
                <NoAllow />
              </span>
            ) : (
              <div className={cls(styles.box, checked && styles.checked)}>
                <span>{checked ? 'Ready' : 'Pause'}</span>
                <Switch
                  checked={checked}
                  onChange={e => {
                    if (!v.performance) return
                    onChange({ item: v, status: e })
                    setCheck(e)
                  }}
                />
              </div>
            )}
          </>
        )}
        {type == 'ideas' && (
          <div className={styles.operate} onClick={() => setAddAudience(true)}>
            <span>Draft</span>
            <NextFinsh />
          </div>
        )}
        {type == 'launch' && (
          <div className={styles.starting}>
            <div className={styles.budget}>
              {currency_symbol}
              {v.budget}
            </div>
            {/* <div className={cls(styles.box, checked && styles.checked)}>
              <span>{checked ? 'Ready' : 'Pause'}</span>
              <Switch checked={checked} onChange={handleSwitch} />
            </div> */}
          </div>
        )}
      </div>
      <CreationModal
        mode="create"
        platform={v.platform || 'meta'}
        status="draft"
        open={addAudience}
        cid={v.id}
        imported={v.is_imported}
        onCancel={() => setAddAudience(false)}
        onOk={() => {
          setAddAudience(false)
          onOk?.()
        }}
      />
    </div>
  )
}
