/* eslint-disable */
//'//rap2api.taobao.org/app/mock/293126/'
const { REACT_APP_DASH_URL} = process.env
export const MOCKURL = ''
  // location.host.indexOf('localhost') >= 0
  //   ? 'http://localhost:9292/v1/dash/'
  //   : location.host.indexOf('dev') >= 0
  //   ? 'https://market.zealery.com/v1/dash/'
  //   : 'https://rest.cactivate.com/v1/dashboard/'

//path中有/dashboard/ 会自动加上dashboardType请求字段
//path有empty的均为空数据接口
const isDebug = location.search.indexOf('debug') != -1

// General
export const doLoginUrl = MOCKURL + 'user' // 登录
export const doBusiness = MOCKURL + 'business' // 获取business
export const userInfo = MOCKURL + 'accounts' // 获取用户信息

// Dashbaord
export const cartList = MOCKURL + 'dashboard/actions' // 获取cartlist列表
export const overView = MOCKURL + (isDebug ? 'empty/dashboard/overview' : 'dashboard/overview') // 获取overview详情
export const perforMance =
  MOCKURL + (isDebug ? 'empty/dashboard/performance' : 'dashboard/performance') // 获取performance
export const audienceData =
  MOCKURL + (isDebug ? 'empty/dashboard/audience' : 'dashboard/audience') // 获取audience
export const audienceDays =
  MOCKURL +
  (isDebug ? 'empty/dashboard/audience/days' : 'dashboard/audience/days') // 获取audience/days每天的值
export const ad = MOCKURL + (isDebug ? 'empty/dashboard/ad' : 'dashboard/ad') // 获取ad数据
export const dashboardData = MOCKURL + (isDebug ? 'empty/dashboard/sync' : 'dashboard/sync')

// Campaigns
export const campaignTab =
  MOCKURL + (isDebug ? 'empty/campaigns/tab' : 'campaigns/tab') // campaigns的tab num
export const campaignList =
  MOCKURL + (isDebug ? 'empty/campaigns/list' : 'campaigns/list') // campaigns的 list 列表
export const campaignsById = MOCKURL + 'campaigns/info' // 单个campaigns
export const campaignsAds = MOCKURL + 'campaigns/ads' // campaigns的ads
export const campaignsUpdateBudget = MOCKURL + 'campaigns/update_budget' // campaigns的更新budget
export const campaignsUpdateLock = MOCKURL + 'campaigns/update_lock' // campaigns的更新look
export const campaignsUpdateStatus = MOCKURL + 'campaigns/update_status' // campaigns的更新status

// Strategy
export const strategyInfo =
  MOCKURL + (isDebug ? 'empty/strategy/info' : 'strategy/info') //strategy数据
export const strategyApprove = MOCKURL + 'strategy/approve'

// Media Library
export const mediaInfo = MOCKURL + (isDebug ? 'empty/media/info' : 'media/info') //media数据
export const uploadMedia =  MOCKURL + 'asset/upload/prepare'
export const uploadSubmit =  MOCKURL + 'asset/upload/submit'
export const uploadInfo =  MOCKURL + 'create/upload_info'

// Audience Library
export const audienceInfo =
  MOCKURL + (isDebug ? 'empty/audience/info' : 'audience/info') //audience数据
export const audienceDelete =
  MOCKURL + (isDebug ? 'empty/audience/delete' : 'audience/delete') //delete audience
export const targeting = MOCKURL + 'targeting/list'
export const persona = MOCKURL + 'targeting-persona/list'
export const updateAction = MOCKURL + 'targeting/update_action'
export const deletePersona = MOCKURL + 'targeting_persona/delete'

// Import
export const campaigns = MOCKURL + 'import/campaigns' //import的campaigns数据

// Create
export const properties = MOCKURL + 'create/properties' //properties下拉列表数据

// Profile
export const accountPrice = MOCKURL + 'profile/info' // 会员等级对应的价格
export const accountMemberList = MOCKURL + 'profile/member' // 会员列表
export const accountList = MOCKURL + 'profile/fetch' //用户数据
export const accountListAdd = MOCKURL + 'profile/accounts' // 保存用户列表
export const accountUpdate = MOCKURL + 'profile/confirm' //更新用户信息
export const creditAdd = MOCKURL + 'credit/add' // 增加银行卡
export const creditRemove = MOCKURL + 'credit/remove' // 删除银行卡
export const creditEdit = MOCKURL + 'credit/edit' // 编辑银行卡
export const doAccountConfirmUrl = MOCKURL + 'profile/confirm' // Save所有用户的数据
export const profilePages = MOCKURL + 'profile/pages'
export const profileEvent = MOCKURL + 'profile/event'
export const profileIns = MOCKURL + 'profile/instagram'
export const profilePixel = MOCKURL + 'profile/pixel'
export const profileAccount = MOCKURL + 'profile/account_save'
export const billingProducts = MOCKURL + 'profile/plan/list'
export const billingProductUpdate = MOCKURL + 'profile/plan/save'


//board
export const boardLink = MOCKURL + 'board/link' 
export const boardSignup = MOCKURL + 'board/signup'
export const boardInfo = MOCKURL + 'board/info'
export const boardBudget = MOCKURL + 'board/budget'

//Project
export const projectCampaigns = MOCKURL + 'project/campaigns'
export const projectDetail = MOCKURL + 'project/detail'
export const projectName = MOCKURL + 'project/name'
export const projectSubmit = MOCKURL + 'ads/create'
export const projectSave = MOCKURL + 'create/save'
export const projectLoad = MOCKURL + 'create/load'
export const projectEdit = MOCKURL + 'ads/edit'
export const projectOptimal = MOCKURL + 'project/optimal'
export const userSettings = MOCKURL + 'profile/fetch'
export const userSettingsUpdate = MOCKURL + 'profile/billing/save'
export const userCancel = MOCKURL + 'business/cancel'
export const userConnectPlatform = MOCKURL + 'platform/connect'
export const userBusinessEdit = MOCKURL + 'business/business_edit'
export const businessSwitch = MOCKURL + 'business/switch'
export const businessNew = MOCKURL + 'business/new'
// export const businessStatus= MOCKURL + 'business/status'
export const businessBudget= MOCKURL + 'business/budget'

//new onboard
export const facebookInfo = MOCKURL + 'onboard/facebook_info'
export const onboardMetrics = MOCKURL + 'onboard/metrics'
export const onboardSubmit = MOCKURL + 'onboard/submit'
export const onboardChecklistValidate = MOCKURL + 'onboard/checklist_validate'
export const onboardDashboardOverview = MOCKURL + 'onboard/dashboard_overview'
export const onboardAds = MOCKURL + 'onboard/ads'
export const onboardConnectFacebook = MOCKURL + 'onboard/connect_facebook'
// export const onboardSave = MOCKURL + 'onboard/save'
export const onboardAdsList = MOCKURL + 'onboard/ads_list'
export const onboardProgress = MOCKURL + 'onboard/progress'
export const onboardBudget = MOCKURL + 'onboard/budget'
export const onboardStepLoad = MOCKURL + 'onboard/step_load'
export const onboardStepSave = MOCKURL + 'onboard/step_save'
export const onboardGoal = MOCKURL + 'onboard/goal'

//v3 onboard
export const userFetch = MOCKURL + 'onboard/user_fetch'
export const userSave = MOCKURL + 'onboard/user_save'
export const fbConnect = MOCKURL + '3/platform/connect'
export const platformFetch = MOCKURL + 'onboard/platform_fetch'
export const platformLoad = MOCKURL + 'platform/load'
export const platformVerify = MOCKURL + 'platform/verify'
export const platformSave = MOCKURL + '3/platform/save'
export const platformInfo = MOCKURL + '3/platform/info'
export const platformEventSave = MOCKURL + '3/platform/event_save'
export const goalSuggest = MOCKURL + '3/goal/suggest'
export const goalConfirm= MOCKURL + '3/goal/confirm'
export const campaignSynced= MOCKURL + '3/campaign/synced'
export const generatePlan= MOCKURL + '3/generate_plan/confirm'
export const campaignSubmit= MOCKURL + '3/campaign/synced_submit'
export const campaignLists= MOCKURL + 'campaign/list'
export const goalInfo= MOCKURL + '3/goal/info'
export const campaignAction= MOCKURL + '3/campaign/action'
export const launch= MOCKURL + '3/launch'
export const businessStatus= MOCKURL + '3/business/status'


export const onboardComplete = MOCKURL + 'onboard/complete'
export const started = MOCKURL + 'started/fetch'
export const startedSave = MOCKURL + '3/started/save'
export const startedOn= MOCKURL + 'business/status'

export const onboardStep = MOCKURL + '3/onboard/step'
export const onboardSave = MOCKURL + '3/onboard/save'
export const onboardGet = MOCKURL + '3/onboard/get'


//new ads
export const adsList = MOCKURL + 'ads/list'
export const adsSearch = MOCKURL + 'ads/search'
export const adsAction = MOCKURL + 'ads/action'
export const updateBudget = MOCKURL + 'update/budget'
export const importSubmit = MOCKURL + 'import/ads_submit'
export const adsStatus = MOCKURL + 'ads/status'
export const poolSort = MOCKURL + 'pool/sort'
export const nextUpdateTime = MOCKURL + 'ads_pool/next_update_time'
export const adsPools = MOCKURL + 'ads/list'
export const poolLimits = MOCKURL + 'pool/limits'
export const adsDelete = MOCKURL + 'ads/delete'
export const adsPreview = MOCKURL + 'create/preview'
export const adcardAll = MOCKURL + 'adcard/all'
export const adcardDetail = MOCKURL + 'adcard/detail'
export const adcardTopCreative = MOCKURL + 'adcard/top_creative'
export const adcardTestingMetric = MOCKURL + 'adcard/testing_metric'
export const adcardOtherCount = MOCKURL + 'adcard/other_count'
export const adcardOtherList = MOCKURL + 'adcard/other_list'
export const adcardDelete = MOCKURL + 'adcard/delete'
export const adcardReplaceList = MOCKURL + 'adcard/replace_list'
export const adcardReplace = MOCKURL + 'adcard/replace'
export const adcardMetrics = MOCKURL + 'adcard/metrics'
export const adcardIdea = MOCKURL + 'adcard/idea'
export const adcardOrder= MOCKURL + 'adcard/order'
export const adcardRestore= MOCKURL + 'adcard/restore'

//notification
export const notification = MOCKURL + 'notification'
export const notificationRead = MOCKURL + 'notification/read'

//ping
export const ping = MOCKURL + 'ping'

//agree
export const agree = MOCKURL + 'user/turn_on'
export const pause = MOCKURL + 'user/turn_off'
export const cancel = MOCKURL + 'cancel'


