import { MetaCreation, GoogleCreation } from '@/components/v3-create/core'
import { useQuery } from '@/hooks'
import Message from '@/utils/message'

export default function Create() {
  const { id, platform = 'meta' } = useQuery()
  const Creation = platform === 'google' ? GoogleCreation : MetaCreation

  return (
    <Creation
      modal={false}
      cid={id}
      onOk={() => Message.success('Saved succesfully!')}
    />
  )
}
