/* eslint-disable  */
import React from 'react'
import { Select, Form, Row } from 'antd'
import PropTypes from 'prop-types'
import './index.scss'
import cls from 'classnames'

const { Option } = Select

const MSelect = props => {
  const {
    options = [],
    errorName = '',
    defaultValue,
    required=true,
    popupClassName='',
    labelInValue=false,
    placeholder,
    onChange = () => {},
    name,
    label = '',
    hasFeedback= false,
    disabled = false,
    rules = true,
    validateStatus='',
    customMessage = '',
  } = props

  const Select_ = () => (
      <Select
        defaultActiveFirstOption={false}
        popupClassName={cls(popupClassName, "m-select_down")}
        placeholder={placeholder}
        value={defaultValue}
        disabled={disabled}
        labelInValue={labelInValue}
        onChange={(e,name) => onChange(e,name)}
        dropdownStyle={{ zIndex: 1052 }}
        bordered={false}
        optionLabelProp="label"
      >
        {options.map((v,i) => (
          <Option disabled={v?.num < 0 || v.disabled} key={v.key ?? i} value={v.value ?? v.id} label={v.label}>
            {v.label} {v?.past_days && (<span><em>{v.num}</em> in past {v.past_days} days</span>)}
          </Option>
        ))}
      </Select>
  )
  
  let message = `Please input ${errorName || name}!`

  if (customMessage !== '') {
    message = customMessage
  }

  return (
    <Form.Item
      label={label}
      hasFeedback = {hasFeedback}
      validateStatus={validateStatus}
      name={name}
      required={required}
      initialValue={defaultValue}
      rules={[{ required: rules, message: message}]}
      className='m-select'
    >
      {Select_()}
    </Form.Item>
  )
}

export default MSelect
