export function parseQuery(query = location.search.slice(1)) {
  const obj = {}
  query.split('&').forEach(seg => {
    const [key, val] = seg.split('=')
    val === void 0 || (obj[key] = val)
  })
  return obj
}

export function download(url, name) {
  const a = document.createElement('a')
  a.href = url
  a.download = name || url.match(/([^/]*)$/)[1]
  a.target = '_blank'
  document.body.append(a)
  a.click()
  a.remove()
}

export function getDeepValue(obj, path) {
  const keys = path.split(/\[|\]|\./).filter(Boolean)
  let result = obj
  while ((path = keys.shift() || '')) {
    result = result[path]
    if (!result) return
  }
  return result
}

export const numberFormat = val =>
  String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
