import * as api from '@/service/target'

export const UPDATE_TARGETS = 'UPDATE_TARGETS'
export const ADD_TARGET = 'ADD_TARGET'
export const MODIFY_TARGET = 'MODIFY_TARGET'
export const DELETE_TARGET = 'DELETE_TARGET'
export const ADD_PERSONA = 'ADD_PERSONA'
export const MODIFY_PERSONA = 'MODIFY_PERSONA'
export const DELETE_PERSONA = 'DELETE_PERSONA'

export function updateTargets(data) {
  if (data) return { type: UPDATE_TARGETS, data }
  return async dispath => {
    const { data } = await api.fetchTargetList()
    dispath({ type: UPDATE_TARGETS, data })
    return data
  }
}

export function createTarget(data) {
  return async dispatch => {
    const { id } = (await api.createTarget(data)).data
    await dispatch(updateTargets())
    return id
  }
}

export function renameTarget(id, name) {
  return async dispatch => {
    await api.renameTarget(id, name)
    dispatch(modifyTarget({ id, name }))
  }
}

export function updateTargetStatus(id, in_use) {
  return async dispath => {
    await api.updateTargetStatus(id, in_use ? 'on' : 'off')
    dispath(modifyTarget({ id, in_use }))
  }
}

export function modifyTarget(data) {
  return { type: MODIFY_TARGET, data }
}

export function deleteTarget(targeting_id) {
  return async dispatch => {
    await api.deleteTarget(targeting_id)
    dispatch({ type: DELETE_TARGET, data: { targeting_id } })
  }
}

export function submitPersona(data) {
  return async dispatch => {
    const { persona_id } = (await api.submitPersonal(data)).data
    data.id = persona_id
    data.persona_id
      ? dispatch({ type: MODIFY_PERSONA, data })
      : dispatch({ type: ADD_PERSONA, data })
  }
}

export function deletePersona(targeting_id, persona_id) {
  return async dispatch => {
    await api.deletePersona(persona_id)
    dispatch({ type: DELETE_PERSONA, data: { targeting_id, persona_id } })
  }
}
