import React from 'react'
import cat from '@/assets/cats/error.gif'
import s from './index.module.scss'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <div className={s.error}>
          <img className={s.errorImg} src={cat} />
          <p className={s.errorMsg}>Oops! Something went wrong.</p>
        </div>
      )
    }

    return this.props.children
  }
}
