/*
 * @Description:
 * @Author: Mark
 * @Date: 2021-09-02 19:39:00
 * @LastEditors: Mark
 * @LastEditTime: 2021-11-04 19:43:19
 */
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import userReudcer from './reducers/user.reducer'
import targetsReudcer, { useActiveTargets } from './reducers/targets.reducer'

const allReducers = combineReducers({
  user: userReudcer,
  target: targetsReudcer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunkMiddleware))
)

export default store
export { useActiveTargets }
