import _axios, { request } from './request'
import * as path from './url'

/**
 * Get the onboard user. This is the user who has been onboarded on the frontend.
 * This user will be the one who will be used for the rest of the flow.
 */
export const getOnboardUser = async () => {
  const { data } = await _axios({
    url: path.userFetch,
  })
  return data
}

/**
 * user if the user does not exist
 */
export const setOnboardUser = data => {
  return _axios({
    url: path.userSave,
    data,
    method: 'post',
  })
}

// This function fetches the list of available platforms that can be onboarded.
// The list is fetched from the API endpoint /platforms.
// The function is called by the AddPlatform component.
export const FbConnect = data => {
  return _axios({
    url: path.fbConnect,
    data,
    method: 'post',
  })
}

// Fetches platforms from the API
// Returns a list of platforms
export const fetchPlatformsData = async () => {
  const { data } = await _axios({
    url: path.platformFetch,
  })
  return data
}

// Fetches the list of available platforms
export const fetchPlatformsLoad = async params => {
  const { data } = await _axios({
    url: path.platformLoad,
    params,
  })
  return data
}

export function fetchPlatformInfo() {
  return request({ url: '3/platform/info' }).then(res => {
    if (res.data.google) {
      res.data.google = Object.fromEntries(
        Object.entries(res.data.google).map(([key, val]) => [
          key.replace(/^google_/, ''),
          val,
        ])
      )
    }
    return res
  })
}

// Verifies the platform
export const fetchPlatformVerify = params => {
  return _axios({
    url: path.platformVerify,
    params,
  })
}

// Saves the platform
export const fetchPlatformSave = async data => {
  return _axios({
    url: path.platformSave,
    method: 'post',
    data,
  })
}

// Fetches the onboard complete status
export const fetchOnboardComplete = async () => {
  return _axios({
    url: path.onboardComplete,
  })
}

// This function fetches the started flag from the backend.
export const fetchStarted = async () => {
  const { data } = await _axios({
    url: path.started,
  })
  return data
}

// This function is used to save the data for the started task
// It receives the task data as a parameter
// It returns the data saved
export const fetchStartedSave = async data => {
  return _axios({
    url: path.startedSave,
    method: 'post',
    data,
    loadingCat: true,
  })
}

// This function is used to fetch the start date of a particular
// campaign. The function takes in a campaign id as a parameter
// and then returns the start date of the campaign.
export const fetchStartedOn = params => {
  return _axios({
    url: path.startedOn,
    params,
  })
}

// This function is used to fetch the start date of a particular
// campaign. The function takes in a campaign id as a parameter
// and then returns the start date of the campaign.
export const fetchUsersettingsUpdate = data => {
  return _axios({
    url: path.userSettingsUpdate,
    method: 'post',
    data,
  })
}

export const fetchOnboardGoal = params => {
  return _axios({
    url: path.onboardGoal,
    params,
  })
}
