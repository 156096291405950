import * as _ from '@/utils'

import Profile from '@/pages/new-profile'
import ResetPassword from '@/pages/login/reset-password'
import Connecting from '@/pages/connecting'
import Campaigns from '@/pages/v4-campaigns'
import Chat from '@/pages/chat'
import Create from '@/pages/create'
import Order from '@/pages/order'

export const routes = [
  {
    routeName: 'Campaigns',
    key: 'campaigns',
    path: '/campaigns',
    component: <Campaigns />,
    cache: true,
  },
  {
    routeName: 'Copilot (Beta)',
    key: 'chat',
    path: '/chat',
    component: <Chat />,
    cache: true,
  },
  {
    routeName: 'Settings',
    key: 'setting',
    path: '/profile',
    component: <Profile />,
    cache: true,
  },
]

export const singleRoute = []

export const allSingleRoute = [
  {
    routeName: 'ConnectingGoogle',
    key: 'connectingGoogle',
    path: '/connecting-google',
    component: <Connecting />,
  },
  {
    routeName: 'ConnectingTiktok',
    key: 'connectingTiktok',
    path: '/connecting-tiktok',
    component: <Connecting />,
  },
  {
    routeName: 'connectingShopify',
    key: 'connectingShopify',
    path: '/connecting-shopify',
    component: <Connecting />,
  },
  {
    routeName: 'ResetPassword',
    key: 'reset-password',
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    routeName: 'Create',
    key: 'create',
    path: '/create',
    component: Create,
  },
  {
    routeName: 'Order',
    key: 'order',
    path: '/order',
    component: Order,
  },
]
