const icons = {
  Edit: require('ico/chat/edit.svg').default,
  Manager: require('ico/chat/manager.svg').default,
  Creation: require('ico/chat/creation.svg').default,
  Activity: require('ico/chat/activity.svg').default,
  Week: require('ico/chat/week.svg').default,
  Read: require('ico/chat/read.svg').default,
  Add: require('ico/chat/add.svg').default,
  Idea: require('ico/chat/idea.svg').default,
  Chat: require('ico/menu/chat.svg').default,
  Lamp: require('ico/chat/lamp.svg').default,
  Submit: require('ico/chat/submit.svg').default,
  Robot: require('ico/chat/robot.svg').default,
  User: require('ico/chat/user.svg').default,
  Uncheck: require('ico/chat/uncheck.svg').default,
  Checked: require('ico/chat/checked.svg').default,
  Meta: require('ico/meta.svg').default,
  Google: require('ico/chat/google.svg').default,
  Search: require('ico/chat/search.svg').default,
  Success: require('ico/success.svg').default,
  Error: require('ico/error.svg').default,
  Done: require('ico/chat/done.svg').default,
  New: require('ico/chat/new.svg').default,
  RobotOnboard: require('ico/chat/robot-onboard.svg').default,
  CreateIcon1: require('ico/chat/create-icon-1.svg').default,
  CreateIcon2: require('ico/chat/create-icon-2.svg').default,
  CreateIcon3: require('ico/chat/create-icon-3.svg').default,
  Pic: require('ico/chat/pic.svg').default,
  Voice: require('ico/chat/voice.svg').default,
  RobotLogo: require('ico/chat/robot-logo.svg').default,
  Archive: require('ico/chat/archive.svg').default,
  Chatloading: require('@/assets/cats/chat-loading-v2.gif').default,
  Chatloading2: require('@/assets/cats/chat-loading2.gif').default,
  Tip: require('ico/chat/tip.svg').default,
  Clean: require('ico/chat/clean.svg').default,
  Delete: require('ico/chat/delete.svg').default,
  Email: require('ico/chat/email.svg').default,
  Cut: require('ico/chat/cut.svg').default,
  ChatCampaign: require('ico/chat/chat-campaign.svg').default,
  NewIdeaBlue: require('ico/chat/new-idea-blue.svg').default,
  RobotEmpty: require('ico/chat/robot-empty.svg').default,
  NewIdeaGreen: require('ico/chat/new-idea-green.svg').default,
  CreativeVoice: require('ico/chat/creative-voice.svg').default,
  AutoPilot: require('ico/chat/auto-pilot.svg').default,
  Copilot: require('ico/chat/copilot.svg').default,
  DayIcon: require('ico/chat/day-icon.svg').default,
  NewIdea: require('ico/chat/new-idea.svg').default,
  NewGoogle: require('ico/chat/new-google.svg').default,
  CampaignIcon1: require('ico/chat/campaign-icon-1.svg').default,
  CampaignIcon2: require('ico/chat/campaign-icon-2.svg').default,
  CampaignIcon3: require('ico/chat/campaign-icon-3.svg').default,
  CampaignIcon4: require('ico/chat/campaign-icon-4.svg').default,
  CampaignIcon5: require('ico/chat/campaign-icon-5.svg').default,
  CampaignIcon6: require('ico/chat/campaign-icon-6.svg').default,
  CampaignIcon7: require('ico/chat/campaign-icon-7.svg').default,
  ideaIcon: require('ico/chat/idea-icon.svg').default,
  variantIcon: require('ico/chat/variant-icon.svg').default,

  //v3
  Time: require('ico/chat/time.svg').default,
  Arrow: require('ico/chat/arrow.svg').default,
  Icon1: require('ico/chat/icon-1.svg').default,
  Up: require('ico/chat/up.svg').default,
  Down: require('ico/chat/down.svg').default,
  Purchases: require('ico/chat/purchases.svg').default,
  Cpr: require('ico/chat/cpr.svg').default,
  Spend: require('ico/chat/spend.svg').default,
  Unchanged: require('ico/chat/unchanged.svg').default,
  Update: require('ico/chat/update.svg').default,
  Error: require('ico/chat/error.svg').default,
  Subtract: require('ico/chat/subtract.svg').default,
  Remove: require('ico/chat/remove.svg').default,
  Pause: require('ico/chat/pause.svg').default,
  Launch: require('ico/chat/launch.svg').default,
  Revive: require('ico/chat/revive.svg').default,
  Variant: require('ico/chat/variant.svg').default,
  Subtract2: require('ico/chat/subtract2.svg').default,
  CampaignIcon8: require('ico/chat/campaign-icon-8.svg').default,
  Lock: require('ico/chat/lock.svg').default,
  AddGroup: require('ico/chat/add-group.svg').default,
  NewGoogle2: require('ico/chat/new-google-2.svg').default,
  NewMeta2: require('ico/chat/new-meta-2.svg').default,

  Stars: require('ico/chat/stars.svg').default,
  Plus: require('ico/chat/plus.svg').default,
  Trashcan: require('ico/chat/trashcan.svg').default,
  Setting: require('ico/chat/setting.svg').default,

  Icon2: require('ico/chat/icon-2.svg').default,
}

export default icons
