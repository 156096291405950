import { useRef } from 'react'
import { Slider } from 'antd'
import cls from 'classnames'
import s from './index.module.scss'
import TAd from 'ico/create/ad.svg'
import TWebsite from 'ico/create/website.svg'
import TCart from 'ico/create/cart.svg'
import TBuy from 'ico/create/buy.svg'

export const CampaignType = ({ disabled, value, onChange }) => {
  const types = [
    { value: 'standard', label: 'Standard' },
    { value: 'retargeting', label: 'Retargeting' },
    { value: 'limited_time', label: 'Limited time' },
  ]

  return (
    <div className={cls(s.radioButtons, disabled && s.disabled)}>
      <div
        className={s.inner}
        data-active={types.findIndex(v => v.value === value)}
      >
        {types.map(v => (
          <div
            key={v.value}
            className={cls(s.button, value === v.value && s.active)}
            onClick={() => value !== v.value && !disabled && onChange(v.value)}
          >
            {v.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export const RadioButtonGroup = ({ options, disabled, value, onChange }) => {
  return (
    <div className={cls(s.radioButtonGroup, disabled && s.disabled)}>
      {options.map(v => (
        <div
          key={v.value}
          className={cls(s.button, value === v.value && s.active)}
          onClick={() => value !== v.value && !disabled && onChange(v.value)}
        >
          {v.label}
        </div>
      ))}
    </div>
  )
}

export const GenderSlider = ({ className, value = 0, onChange }) => {
  return (
    <div className={cls(s.genderSlider, className)}>
      <span className={s.label}>Women</span>
      <span className={s.value} style={{ color: '#FF9090' }}>
        {value}%
      </span>
      <Slider tooltip={{ open: false }} value={value} onChange={onChange} />
      <span className={s.value} style={{ color: '#84B3FF' }}>
        {100 - value}%
      </span>
      <span className={s.label}>Men</span>
    </div>
  )
}

export const TargetType = ({ value, onChange }) => {
  const list = useRef([
    {
      name: 'Engaged with ad',
      value: 'engagement',
      icon: <TAd />,
    },
    {
      name: 'Website visitors ',
      value: 'website_view',
      icon: <TWebsite />,
    },
    {
      name: 'Add to Carts',
      value: 'abandoned_carts',
      icon: <TCart />,
    },
    {
      name: 'Previous buyers',
      value: 'previous_buyers',
      icon: <TBuy />,
    },
  ]).current

  return (
    <ul className={s.targetTypeList}>
      {list.map((item, i) => (
        <li
          key={i}
          className={cls(s.targetType, { [s.active]: item.value === value })}
          onClick={() => onChange(item.value)}
        >
          {item.icon}
          {item.name}
        </li>
      ))}
    </ul>
  )
}
