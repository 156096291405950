import { useState, useEffect } from 'react'
import { Tooltip, Spin, Button, Modal, Image } from 'antd'
import { useRequest, useBoolean } from 'ahooks'
import { getImgInfo, IMG_MAX_SIZE } from '@/utils/uploader'
import { verifyAsset } from '@/service/assets'
import produce from 'immer'
import { IMGS_AVAILABLE_FOR_GOOGLE } from '@/utils/constant'
import { MediaPicker, matchResolution } from '@/pages/v3-media-library'

import cls from 'classnames'
import s from './index.module.scss'
import Plus from 'ico/v3-media/plus.svg'
// import Image from 'ico/v3-create/image.svg'
import Video from 'ico/v3-create/video.svg'
import Play from 'ico/v3-create/play.svg'
import Download from 'ico/v3-create/download.svg'

const cache = new Map()
const MAX = 10

const match = (key, value) =>
  IMGS_AVAILABLE_FOR_GOOGLE.find(item => item[key] === value)

const downloadAsset = url => {
  const link = document.createElement('a')
  link.href = url
  link.download = url.match(/[^/]+/)[0]
  link.click()
}

export async function getMediaInfo(url) {
  if (!cache.get(url)) {
    const info = await getImgInfo(url)
    info.type = match('aspect_ratio', info.ratio)?.type || 'other'
    cache.set(url, info)
  }
  return cache.get(url)
}

export default function Creatives({
  platform,
  accept,
  value = [],
  onChange,
  suggestion,
}) {
  const [open, { toggle }] = useBoolean()

  const onOk = async medias => {
    onChange(value.concat(medias).slice(0, MAX))
  }

  const onVerify = (id, errno) => {
    onChange(
      produce(value, val => {
        const item = val.find(v => v.id === id)
        if (errno !== 1) {
          item.verified = true
          item.errno = errno
        } else if (!item.errno) {
          item.errno = errno
        }
      })
    )
  }

  const handleDelete = e => {
    const { index } = e.target.dataset
    if (!index) return
    const val = [...value]
    val.splice(index, 1)
    onChange(val)
  }

  return (
    <>
      <MediaPicker
        platform={platform}
        accept={accept}
        defaultSelected={value}
        multiple
        onOk={onOk}
      >
        {({ trigger }) => (
          <div className={s.wrap}>
            {/* <Button className={s.previewBtn}>Preview</Button> */}
            <div className={s.assets} onClick={handleDelete}>
              {value.map((item, index) => (
                <Asset
                  key={item.url}
                  accept={accept}
                  data={item}
                  index={index}
                  onVerify={onVerify}
                />
              ))}
              {value.length < MAX && (
                <span className={s.plus} onClick={trigger}>
                  <Plus />
                </span>
              )}
            </div>
            {!!suggestion && (
              <div className={s.suggestions}>
                <div className={s.title}>
                  Asset suggestions: {suggestion.title}
                  {/* {1 ? <Image /> : <Video />} */}
                </div>
                <div className={cls(s.desc, 'tiny-scrollbar')}>
                  {suggestion.description}
                </div>
                <div className={s.cover}>
                  <img src={suggestion.image_url} />
                  {false && <Play />}
                </div>
                <Button type="primary" onClick={toggle}>
                  Sample
                </Button>
              </div>
            )}
          </div>
        )}
      </MediaPicker>

      {!!suggestion && (
        <Modal
          className="custom-modal"
          open={open}
          centered
          closable={false}
          footer={null}
          onCancel={toggle}
        >
          <div className={s.sample}>
            {/* <Button
              className={s.download}
              onClick={() => downloadAsset(suggestion.image_url)}
            >
              <Download className="fill-color-strict" />
              Download
            </Button> */}
            <div className={s.title}>Asset suggestions: {suggestion.title}</div>
            <div className={s.desc}>{suggestion.description}</div>
            <img className={s.img} src={suggestion.image_url} />
            {/* <div className={s.btns}>
            <Button onClick={toggle}>Cancel</Button>
            <Button type="primary" onClick={toggle}>
              Confirm
            </Button>
          </div> */}
          </div>
        </Modal>
      )}
    </>
  )
}

const Asset = ({ accept, data, index, onVerify }) => {
  const ratioError = media => {
    return !!accept && !accept.some(item => item.type === media.type)
  }
  const resolutionError = media => {
    const width = media.resolution?.split('*')[0]
    return (
      !!accept && width < matchResolution(media.resolution)?.min_resolution?.[0]
    )
  }
  const sizeError = media => {
    return media.size > IMG_MAX_SIZE
  }

  const getErrorMsg = media =>
    ratioError(media)
      ? 'The aspect ratio is not supported'
      : resolutionError(media)
      ? `The resolution of ${
          media.type
        } images must not be less than ${matchResolution(
          media.resolution
        ).min_resolution.join('x')}`
      : sizeError(media)
      ? 'Image too large'
      : ''

  const { data: errMsg, loading } = useRequest(
    async () => {
      const msg = getErrorMsg(data)
      if (msg) {
        onVerify(data.id, 1)
        return msg
      }
      if (data.verified)
        return data.errno === 2
          ? 'Verify failed'
          : (data.errno === 1 && onVerify(data.id, 0), '')
      return verifyAsset({
        platform: accept ? 'google' : 'meta',
        asset_ids: [data.id],
      })
        .then(({ data: { ok_uuids } }) => {
          if (!ok_uuids[0]) return Promise.reject()
          onVerify(data.id, 0)
        })
        .catch(() => {
          onVerify(data.id, 2)
          return 'Verify failed'
        })
    },
    { refreshDeps: [accept] }
  )

  return (
    <Tooltip open={errMsg ? undefined : false} title={errMsg} color="#ff6161">
      <div
        className={cls(s.asset, {
          [s.error]: errMsg,
          [s.disabled]: loading,
        })}
        data-index={index}
      >
        <Image src={data.url} />
        <Spin spinning={loading} />
      </div>
    </Tooltip>
  )
}
