import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useLocation, useHistory } from 'react-router-dom'
import './App.scss'
import * as api from '@/service'
import RouterView from '@/router/router-view'
import { allSingleRoute } from '@/router/routes'
import { updateUser, modifyUser, updateGroups } from '@/store/actions'
import { useLottie } from 'lottie-react'
import loadingAnimation from './assets/json/loading.json'
import _isEmpty from 'lodash/isEmpty'
import * as _ from '@/utils'
import { CContext } from '@/context/index'
import cls from 'classnames'
import { AliveScope } from 'react-activation'
import { useRequest, useUpdateEffect, useReactive } from 'ahooks'
import { warning } from './utils/cat'
import Stripe from './components/stripe'
import Header from './components/header'
import { publish } from '@/subscribe'

const { Fbevent } = _
const { REACT_APP_ENV } = process.env

window.fbAsyncInit = function () {
  Fbevent.init()
}

function App() {
  const { View } = useLottie({
    animationData: loadingAnimation,
    loop: true,
  })
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const state = useReactive({ Businessloop: 0 })

  if (process.env.NODE_ENV === 'development') {
    window.his = history
  }

  const isAllSingleRoute = allSingleRoute.some(
    v => v.path === pathname.replace(/\/$/, '')
  )

  const {
    runAsync: fetchBusiness,
    data: businessData,
    cancel: cancelFetchBusiness,
  } = useRequest(
    async () => {
      let { data } = await api.fetchBusiness()
      data = { ...data, update_time: new Date().toUTCString() }

      if (data.is_started && !state.ready && !isAllSingleRoute) {
        await dispatch(updateGroups(_.getGroupId() || data.default_group_id))
      }

      return data
    },
    {
      manual: true,
      pollingInterval: 120 * 1000,
      pollingWhenHidden: false,
    }
  )

  const { data: userData, run } = useRequest(
    async () => {
      const { data } = await api.doLogin()
      if (data.is_maintenance_mode)
        return warning(
          'we‘re upgrading our dashboard, please come back in a few hours.'
        )
      // Start intercom
      window.Intercom('boot', {
        app_id: 'bsnt0tw3',
        email: data.email,
        user_id: data.id,
        name: data.name,
        created_at: Math.floor(new Date().getTime() / 1000.0),
        user_hash: data.intercom_hash,
      })
      // Call google tag manager
      ;(window.dataLayer || (window.dataLayer = [])).push({
        event: 'userData',
        login_status: 'logged in',
        userId: String(data.id),
      })
      _.setBusinessId(data.default_business_id, false)

      fetchBusiness().then(({ default_group_id }) => {
        _.setGroupId(default_group_id, false)
      })
      return data
    },
    {
      manual: true,
    }
  )

  // useTaskStart(cancelFetchBusiness)
  // useTaskFinish(() => {
  //   fetchBusiness()
  //   dispatch(updateGroups())
  // })

  useEffect(() => {
    if (!_.getToken()) {
      state.ready = true
      history.replace('/chat')
    } else {
      run()
    }
  }, [])

  useUpdateEffect(() => {
    if (!businessData) return
    dispatch(modifyUser({ ...businessData, ...userData }))
    if (businessData.campaigns_fetching && state.ready) {
      publish({ type: 'campaigns_fetching' })
    }
    if (businessData.recommendations_fetching && state.ready) {
      publish({ type: 'recommendations_fetching' })
    }
    if (!businessData.is_started) {
      cancelFetchBusiness()
      history.replace('/chat')
    }
    state.ready = true

    // if (businessData.is_pending && businessData.pending_id) {
    //   pollTask({ task_id: businessData.pending_id })
    //   return
    // }

    if (businessData.status == 'ERROR' && businessData.error_code) {
      if (
        [
          'PAYMENT_MISSING',
          'PAYMENT_REQUIRED',
          'FACEBOOK_DISABLED',
          'ACCOUNT_DISABLED',
          'REACH_SPEND_LIMIT',
        ].includes(businessData.error_code)
      ) {
        return warning(businessData.error_message)
      } else if (
        ['NO_CARD', 'FAILED_CHARGE'].includes(businessData.error_code)
      ) {
        history.replace(`/profile?error_code=FAILED_CHARGE`)
      } else if (['INVALID_TOKEN'].includes(businessData.error_code)) {
        history.replace(`/profile?error_code=INVALID_TOKEN`)
      } else if (businessData.error_code === 'LAUNCHING_ERROR') {
        // state.showErrorUI = true
      }
      return
    }
  }, [businessData, userData])

  const ctxValue = useMemo(
    () => ({
      ev: _.Event,
      fetchBusiness,
    }),
    [fetchBusiness]
  )

  return state.ready ? (
    <CContext.Provider value={ctxValue}>
      <div className="app">
        {!isAllSingleRoute && <Header />}
        <div className="app-main">
          <AliveScope>
            <RouterView />
          </AliveScope>
        </div>
      </div>

      <Stripe />
    </CContext.Provider>
  ) : (
    <div className="page-loading">{View}</div>
  )
}

export default App
