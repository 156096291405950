import { Spin } from 'antd'
import { useRequest } from 'ahooks'
import cls from 'classnames'
import Empty from '@/components/empty'
import Item from '@/pages/v3-start/plan-item/index.js'
import * as api from '@/service/campaign'
import s from '../index.module.scss'

export default ({ ideas, onOk, children }) => {
  const { loading, data } = useRequest(
    async () => ideas || (await api.fetchCampaignList()).data?.items
  )

  return (
    <Spin spinning={loading}>
      <div className={cls(s.modal)}>
        {children}
        <section className={s.listHeader}>
          <header>
            <span>Theme</span>
            <span>Type</span>
            <span>Description</span>
            <span>Operate</span>
          </header>
        </section>
        <section className={s.listWarp}>
          {!loading && !data?.length && <Empty className={s.empty} />}
          {data
            ?.filter(f => f.status == 'idea')
            .map((v, k) => (
              <Item type="ideas" {...v} key={v.id} onOk={onOk} />
            ))}
        </section>
      </div>
    </Spin>
  )
}
