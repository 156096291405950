import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLockFn, useReactive, useRequest, useFocusWithin } from 'ahooks'
import cls from 'classnames'
import QueueAnim from 'rc-queue-anim'
import { CContext as C } from '@/context'
import { Input, Button, Select, Spin } from 'antd'
import Icons from '../../icons'
import s from '../../index.module.scss'
import styles from './index.module.scss'
import * as api from '@/service'
import { capitalize } from 'lodash'
import { modifyUser, updateGroups } from '@/store/actions'
import { stages } from '@/utils/constant'

const { TextArea } = Input

export default ({ children }) => {
  const dispatch = useDispatch()
  const { fetchBusiness } = React.useContext(C)
  const bm = React.useRef(null)
  const state = useReactive({})

  useRequest(async () => {
    state.loading = true
    const { data } = await api.request({
      url: 'group/init/fetch?step=strategy',
    })
    state.loading = false
    if (data) state.data = data
    handleScroll()
  })

  /* const handleTest = async () => {
    await api.request({
      url: 'campaign/list',
    })
  }

  useEffect(() => {
    handleTest()
  }, []) */

  const handleSave = async (id, v) => {
    state.loading = true
    state.finish = false
    state.data = state.data.slice(0, state.data.findIndex(v => v.id == id) + 1)
    handleScroll(0)
    const { data } = await api.request({
      url: 'group/init/save',
      method: 'get',
      params: {
        data: JSON.stringify({
          step: 'strategy',
          [id]: v,
        }),
      },
    })
    state.loading = false
    if (data.result) {
      if (data.next_action) {
        state.data.push(...data.next_action)
        handleScroll()
      } else {
        state.finish = true
      }
    }
  }

  const handleComplete = useLockFn(async () => {
    state.submitLoading = true
    const { data } = await api
      .request({
        url: 'group/init/complete?step=strategy',
      })
      .finally(() => (state.submitLoading = false))
    if (data?.result) {
      // await fetchBusiness()
      dispatch(modifyUser({ is_started_group: true }))
      dispatch(updateGroups())
    }
  })

  const getDom = v => {
    switch (v.type) {
      case 'text':
        return <Mtext value={v.value} />
      case 'input':
        return <Minput {...v} handleSave={handleSave} />
      case 'textarea':
        return <Minput {...v} handleSave={handleSave} _type="1" />
      case 'sync_campaign':
        return <Mcampaign2 {...v} handleSave={handleSave} />
      // case 'sync_campaign':
      //   return <Mcampaign {...v} handleSave={handleSave} />
      case 'select':
        return <Mselect {...v} handleSave={handleSave} />
      case 'select-multiple':
        return <MselectMultiple {...v} handleSave={handleSave} />
      case 'platform':
        return <MPlatform {...v} handleSave={handleSave} />
    }
  }

  const handleScroll = async (delay = 600) => {
    await new Promise(r => setTimeout(r, delay))
    bm.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  return (
    <div className={styles.assistant} ref={bm}>
      {state.data?.length &&
        state.data.map((v, i) => (
          <QueueAnim delay={300}>
            <div key={v.value + i}>
              {getDom({ ...v, index: i, len: state.data.length })}
            </div>
          </QueueAnim>
        ))}

      {state.loading && (
        <div className={cls(s.robot)}>
          {/* <Icons.Robot /> */}
          <div className={cls(s.sendLoading, s.txt, s.center)}>{children}</div>
        </div>
      )}

      {state.finish && (
        <Button
          type="primary"
          loading={!!state.submitLoading}
          className={styles.complete}
          onClick={handleComplete}
        >
          Complete Setup
        </Button>
      )}
    </div>
  )
}

const Mtext = ({ value }) => {
  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}

      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />
        {value}
      </div>
    </div>
  )
}

const MselectMultiple = ({
  value,
  id,
  handleSave,
  options,
  index,
  len,
  title,
}) => {
  const [list, setList] = React.useState(options ?? [])
  const [all, setAll] = React.useState(value)
  const [show, setShow] = React.useState(index + 1 == len)

  const handleChange = e => {
    setList(options.filter(v => v.label.includes(e.target.value)))
  }

  const handleSubmit = useLockFn(async () => {
    await handleSave(id, all).finally(setShow(false))
  })

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center, styles.campaigns)}>
        <Icons.Icon2 className={s.icon2} />

        <div className={styles.title}>
          {title}({options?.length})
          {title.toLowerCase().includes('meta') ? (
            <Icons.Meta />
          ) : (
            <Icons.Google />
          )}
        </div>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <Input bordered={false} onChange={handleChange} />
            <Icons.Search />
          </div>
          <div
            className={styles.searchCheck}
            onClick={() => {
              setAll(all.length != list.length ? list.map(v => v.value) : [])
              setShow(true)
            }}
          >
            <span>Select All</span>
            {all.length && all.length == list.length ? (
              <Icons.Checked />
            ) : (
              <Icons.Uncheck />
            )}
          </div>
        </div>
        <div className={styles.list}>
          <ul>
            {list.map((v, i) => (
              <li
                key={v.value}
                onClick={() => {
                  setAll(
                    all.includes(v.value)
                      ? all.filter(j => j != v.value)
                      : [...all, v.value]
                  )
                  setShow(true)
                }}
              >
                <div className={styles.type}>
                  <div className={styles.name}>{v.label}</div>
                </div>
                {all.includes(v.value) ? (
                  <Icons.Checked className={styles.icon} />
                ) : (
                  <Icons.Uncheck className={styles.icon} />
                )}
              </li>
            ))}
          </ul>
        </div>
        {show && (
          <Button type="primary" disabled={!all.length} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </div>
    </div>
  )
}

const Mcampaign = ({ value, id, title, handleSave, index, len }) => {
  const [list, setList] = React.useState(value ?? [])
  const [all, setAll] = React.useState(false)
  const [show, setShow] = React.useState(index == len - 1)

  const handleChange = e => {
    setList(
      value.filter(v =>
        v.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    )
  }

  const handleSubmit = useLockFn(async () => {
    const selected = list.filter(v => v.selected).map(v => v.id)
    await handleSave(id, selected).finally(() => setShow(false))
  })

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center, styles.campaigns)}>
        <Icons.Icon2 className={s.icon2} />
        <div className={styles.header}>
          <span>
            {title}({list?.length})
          </span>
          <div className={styles.sizes}>
            <span>
              Learning from <b>10 of 100</b> campaigns
            </span>
            <span>
              Unassigned <em>90</em>
            </span>
          </div>
        </div>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <Input
              bordered={false}
              onChange={handleChange}
              placeholder="Search"
            />
            <Icons.Search />
          </div>
          <div
            className={styles.searchCheck}
            onClick={() => {
              setList(list.map(v => ({ ...v, selected: !all })))
              setAll(!all)
            }}
          >
            <span>Select All</span>
            {all ? <Icons.Checked /> : <Icons.Uncheck />}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.listHeader}>
            <span>Campaign Name</span>
            <span>Objective</span>
            <span>Assigned</span>
          </div>
          <ul>
            {list.map((v, i) => (
              <li
                key={v.id}
                onClick={() => {
                  setShow(true)
                  const newList = [...list]
                  newList[i].selected = !newList[i].selected
                  setList(newList)
                }}
              >
                <div className={styles.type}>
                  {v.platform == 'meta' ? <Icons.Meta /> : <Icons.Google />}
                  <div className={styles.name}>{v.name}</div>
                  <span
                    className={cls(styles.status, {
                      [styles.active]: v.performance,
                    })}
                  >
                    {v.performance ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <div className={styles.objective}>{v?.objective}</div>
                <div className={styles.assigned}>
                  {v.selected ? (
                    <Icons.Checked className={styles.icon} />
                  ) : (
                    <Icons.Uncheck className={styles.icon} />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {show && (
          <Button
            type="primary"
            // disabled={list.every(v => !v.selected)}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  )
}

const Mcampaign2 = ({ value, id, title, handleSave, index, len }) => {
  const [list, setList] = React.useState(value ?? [])
  // const [all, setAll] = React.useState(false)
  const [show, setShow] = React.useState(index == len - 1)
  const all = list.every(item => item.selected)

  const disableSubmit = list.some(item => item.selected && !item.stage)

  const handleChange = e => {
    setList(
      value.filter(v =>
        v.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    )
  }

  const handleSubmit = useLockFn(async () => {
    const selected = list
      .filter(v => v.selected)
      .map(v => ({ id: v.id, stage: v.stage }))
    await handleSave(id, selected).finally(() => setShow(false))
  })

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center, styles.campaigns)}>
        <Icons.Icon2 className={s.icon2} />

        <div className={styles.header}>
          <span>{title}</span>
          {/* <div className={styles.sizes}>
            <span>
              Learning from <b>10 of 100</b> campaigns
            </span>
            <span>
              Unassigned <em>90</em>
            </span>
          </div> */}
        </div>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <Input
              bordered={false}
              onChange={handleChange}
              placeholder="Search"
            />
            <Icons.Search />
          </div>
          <div
            className={styles.searchCheck}
            onClick={() => {
              setList(list.map(v => ({ ...v, selected: !all })))
            }}
          >
            <span>Select All</span>
            {all ? <Icons.Checked /> : <Icons.Uncheck />}
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.listHeader}>
            <span>Campaign Name</span>
            <span>Objective</span>
            <span>Funnel Stage</span>
            <span>Assigned</span>
          </div>
          <ul>
            {list.map((v, i) => (
              <li
                key={v.id}
                onClick={() => {
                  setShow(true)
                  const newList = [...list]
                  newList[i].selected = !newList[i].selected
                  setList(newList)
                }}
              >
                <div className={styles.type}>
                  {v.platform == 'meta' ? <Icons.Meta /> : <Icons.Google />}
                  <div className={cls(styles.name, 'ellipsis')}>{v.name}</div>
                  <span
                    className={cls(styles.status, {
                      [styles.active]: v.performance,
                    })}
                  >
                    {v.performance ? 'Active' : 'Inactive'}
                  </span>
                </div>
                <div className={styles.objective}>{v.objective}</div>
                <div className={styles.stage}>
                  <Select
                    onClick={e => e.stopPropagation()}
                    value={v.stage}
                    onChange={stage => {
                      const newList = [...list]
                      newList[i].stage = stage
                      setList(newList)
                      setShow(true)
                    }}
                    popupClassName={cls(styles.selectDown, styles.stageDown)}
                    options={stages}
                  />
                </div>
                <div className={styles.assigned}>
                  {v.selected ? (
                    <Icons.Checked className={styles.icon} />
                  ) : (
                    <Icons.Uncheck className={styles.icon} />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {show && (
          <Button
            type="primary"
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  )
}

const Mselect = ({ value, id, options, title, handleSave }) => {
  const [val, setVaL] = React.useState(value)
  const [show, setShow] = React.useState(false)

  const handleSubmit = useLockFn(async () => {
    await handleSave(id, val)
    setShow(false)
  })

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />
        <div className={styles.select}>
          <div className={styles.title}>{title}</div>
          <Select
            defaultValue={value}
            onChange={e => {
              setVaL(e)
              setShow(true)
            }}
            popupClassName={styles.selectDown}
            options={options}
          />
        </div>
        {show && (
          <Button
            type="primary"
            disabled={!val}
            className={styles.selectBtn}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  )
}

const MPlatform = ({ value, id, options, title, handleSave }) => {
  const [val, setVal] = React.useState(value)
  const [show, setShow] = React.useState(false)

  const handleChange = v => {
    const data = new Set(val)
    data.has(v) ? data.delete(v) : data.add(v)
    setVal([...data])
    setShow(true)
  }

  const handleEnter = async () => {
    await handleSave(id, val).finally(() => setShow(false))
  }

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />
        <div>{title}</div>
        <div className={styles.platform}>
          {options.includes('meta') && (
            <div
              className={cls(styles.box, {
                [styles.active]: val.includes('meta'),
              })}
              onClick={() => handleChange('meta')}
            >
              <Icons.Meta />
              <div className={styles.name}>Meta</div>
            </div>
          )}
          {options.includes('google') && (
            <div
              className={cls(styles.box, {
                [styles.active]: val.includes('google'),
              })}
              onClick={() => handleChange('google')}
            >
              <Icons.Google />
              <div className={styles.name}>Google</div>
            </div>
          )}
        </div>
        {show && (
          <Button
            type="primary"
            disabled={!val.length}
            className={styles.selectBtn}
            onClick={handleEnter}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  )
}

const Minput = ({
  value,
  placeholder,
  title,
  id,
  handleSave,
  _type = 0,
  index,
  len,
}) => {
  const [isEdit, setIsEdit] = React.useState(index + 1 == len)
  const [val, setVal] = React.useState()
  const ref = React.useRef(null)
  useFocusWithin(ref, {
    onBlur: () => {
      setIsEdit(false)
    },
  })

  React.useEffect(() => {
    setVal(value)
  }, [value])

  const handleEnter = async () => {
    await handleSave(id, val)
    setIsEdit(false)
  }

  return (
    <div className={s.robot} ref={ref}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />
        <div>{title}</div>
        <div className={styles.input} onClick={() => setIsEdit(true)}>
          {isEdit ? (
            _type ? (
              <TextArea
                rows={5}
                placeholder={placeholder}
                onChange={e => setVal(e.target.value)}
                onPressEnter={handleEnter}
                bordered={false}
                autoFocus
                value={val}
              />
            ) : (
              <Input
                placeholder={placeholder}
                value={val}
                bordered={false}
                autoFocus
                onChange={e => setVal(e.target.value)}
                onPressEnter={handleEnter}
              />
            )
          ) : (
            <div className={cls(styles.text, _type && styles.textarea)}>
              {val}
            </div>
          )}
          <Icons.Edit />
        </div>
        {isEdit && (
          <Button
            type="primary"
            disabled={!val}
            onClick={handleEnter}
            className={styles.selectBtn}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  )
}
