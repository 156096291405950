// 基础配置config
import defaultImg from '@/assets/images/default.png'

export const mockImg = defaultImg

export const CALL_ACTION_LIST = [
  {
    value: 'LEARN_MORE',
    label: 'LEARN MORE',
  },
  {
    value: 'SIGN_UP',
    label: 'SIGN UP',
  },
  {
    value: 'SUBSCRIBE',
    label: 'SUBSCRIBE',
  },
  {
    value: 'SHOP_NOW',
    label: 'SHOP NOW',
  },
  {
    value: 'GET_OFFER',
    label: 'GET OFFER',
  },
  {
    value: 'ORDER_NOW',
    label: 'ORDER NOW',
  },
  {
    value: 'CONTACT_US',
    label: 'CONTACT US',
  },
]

export const genderList = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 'Men',
    value: 1,
  },
  {
    label: 'Women',
    value: 2,
  },
]

export const IMGS_AVAILABLE_FOR_GOOGLE = [
  { type: 'market', aspect_ratio: 1.91, min_resolution: [600, 314] },
  { type: 'square', aspect_ratio: 1, min_resolution: [300, 300] },
  { type: 'portrait', aspect_ratio: 0.8, min_resolution: [480, 600] },
  { type: 'landscape', aspect_ratio: 4, min_resolution: [512, 128] },
]

export const stages = [
  { value: 'brand_awareness', label: 'Awareness' },
  { value: 'prospective', label: 'Prospective' },
  { value: 'retargeting', label: 'Retargeting' },
  { value: 'limited_time', label: 'Limited Time' },
]
