import React, { useEffect, useMemo } from 'react'
import {
  useReactive,
  useLockFn,
  useRequest,
  useFocusWithin,
  useUpdateEffect,
} from 'ahooks'
import { Form, Input, Button, Select, Spin } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { modifyUser, updateGroups } from '@/store/actions'

import { CContext as C } from '@/context'
import cls from 'classnames'
import Icons from '../../icons'
import * as _ from '@/utils'
import s from '../../index.module.scss'
import * as api from '@/service'
import styles from './index.module.scss'

const urlReg =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/
const { Message, setToken, getToken, Fbevent } = _
let startTime = 0

export default () => {
  const { fetchBusiness } = React.useContext(C)
  const bm = React.useRef(null)
  const dispatch = useDispatch()
  const { is_started, email } = useSelector(state => state.user.current)
  const state = useReactive({ step: 1 })
  const {
    step,
    isMeta = false,
    isGoogle = false,
    showCode = true,
    isLaunch = false,
  } = state
  const [form] = Form.useForm()

  const handleRegister = async params => {
    params =
      params.action == 'valid_vertify_code'
        ? { ...params, email: form.getFieldValue('email') }
        : params

    const { data } = await api.request({
      url: 'onboard/fetch',
      method: 'post',
      data: params,
    })
    if (!data?.result) {
      return Message.warn(data?.message ?? 'Submit failed')
    }
    if (data.token) {
      setToken(data.token)
      _.setBusinessId(data.business_id)
      if (data.is_started) return location.replace('/')
      // dispatch(modifyUser({ token: data?.token }))
      await new Promise(r => setTimeout(r))
      await fetchBusiness()
      state.step = 3
    } else {
      state.step = 2
    }
  }

  useUpdateEffect(() => void handleScroll(), [step])

  useEffect(() => {
    if (!is_started && getToken()) {
      state.step = 3
      state.showCode = false
      form.setFieldsValue({ email })
    }
  }, [is_started])

  const handleScroll = async () => {
    await new Promise(r => setTimeout(r, 100))
    bm.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    })
  }

  const handleComplete = async () => {
    state.submitLoading = true
    const { data } = await api
      .request({
        url: 'onboard/save',
        method: 'post',
        data: {
          action: 'finish',
        },
      })
      .finally(() => (state.submitLoading = false))
    if (data?.result) {
      location.replace('/')
    }
  }
  return (
    <C.Provider value={{ state, form }}>
      <div className={styles.onboard} ref={bm}>
        <Mtext />
        <Form form={form}>
          {step > 0 && (
            <Minput
              title="What’s your email?"
              step={1}
              placeholder="Enter your email"
              cb={async v => {
                await handleRegister({
                  action: 'get_vertify_code',
                  email: v,
                })
              }}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid email!',
                },
              ]}
            />
          )}
          {step > 1 && showCode && (
            <Minput
              title="Enter your verification code"
              placeholder="Enter your code"
              name="code"
              step={2}
              cb={v =>
                handleRegister({
                  action: 'valid_vertify_code',
                  vertify_code: v,
                })
              }
            />
          )}
          {step > 2 && (
            <Minput
              step={3}
              title="What’s your name?"
              placeholder="Enter your name"
              name="name"
            />
          )}
          {step > 3 && (
            <Minput
              step={4}
              title="What’s your company website?"
              placeholder="Enter your website"
              name="website"
              rules={[
                {
                  type: 'url',
                  message: 'The input is not valid url!',
                },
              ]}
            />
          )}
          {step > 4 && (
            <MPlatform step={5} title="Where would you like to run ads?" />
          )}
          {step > 5 && isMeta && (
            <MSelectPlatform
              step={6}
              title="Choose your ad account for Meta"
              name="meta"
            />
          )}
          {step > 6 && isGoogle && (
            <MSelectPlatform
              step={7}
              title="Choose your ad account for Google"
              name="google"
            />
          )}

          {step > 7 && (
            <MSelect
              step={8}
              name="structure"
              title="How do you structure your budget and campaigns?"
            />
          )}

          {step > 8 && (
            <MAbout
              step={9}
              name="about"
              title="Got it! Tell me a bit more about your business"
            />
          )}
        </Form>
        {state?.loading && (
          <div className={s.robot}>
            <Icons.Robot />
            <div className={cls(s.sendLoading, s.txt)}>Syncing</div>
          </div>
        )}
        {step == 10 && (
          <Button
            type="primary"
            loading={!!state?.submitLoading}
            className={styles.complete}
            onClick={handleComplete}
          >
            Complete Onboard
          </Button>
        )}
      </div>
    </C.Provider>
  )
}

const Mtext = ({
  value = 'Hi, I’m your marketing copilot. Let’s get you set up!',
}) => {
  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />
        {value}
      </div>
    </div>
  )
}

const Minput = ({ placeholder, title, cb = null, name, rules = [], step }) => {
  const { state, form } = React.useContext(C)
  const [isEdit, setIsEdit] = React.useState(true)
  const [val, setVal] = React.useState()
  const [load, setLoad] = React.useState(false)
  const ref = React.useRef(null)

  const handleSubmit = async () => {
    const { data } = await api.request({
      url: 'onboard/save',
      method: 'post',
      data: {
        [name]: val,
      },
    })
    if (data?.result) return (state.step = step + 1)

    return Message.warn(data?.message ?? 'submit Fail')
  }

  const handleEnter = useLockFn(async () => {
    await form.validateFields([name])
    setLoad(true)
    try {
      await (cb ? cb(val) : handleSubmit())
    } finally {
      setLoad(false)
      setIsEdit(false)
    }
  })

  useFocusWithin(ref, {
    onBlur: () => {
      setIsEdit(false)
    },
  })

  const disabled = useMemo(
    () => state.step > 2 && (name == 'code' || name == 'email'),
    [state.step, name]
  )

  return (
    <div className={cls(s.robot)} ref={ref}>
      {/* <Icons.Robot /> */}
      {state.step > step && <Icons.Done className={styles.done} />}
      <div
        className={cls(s.center, s.txt, {
          [styles.txtDisabled]: disabled,
        })}
      >
        <Icons.Icon2 className={s.icon2} />
        <div>{title}</div>
        <div className={styles.input}>
          {isEdit ? (
            <Form.Item
              name={name}
              rules={[
                ...rules,
                {
                  required: true,
                  message: 'Cannot be empty',
                },
              ]}
            >
              <Input
                placeholder={placeholder}
                bordered={false}
                autoFocus
                onPressEnter={handleEnter}
                onChange={e => setVal(e.target.value)}
              />
            </Form.Item>
          ) : (
            <div className={styles.text} onClick={() => setIsEdit(true)}>
              {val}
            </div>
          )}
          <Icons.Edit onClick={() => setIsEdit(true)} />
        </div>
        {isEdit && !disabled && (
          <Button
            type="primary"
            loading={load}
            disabled={!val}
            onClick={handleEnter}
            className={styles.submitBtn}
          >
            Confirm
          </Button>
        )}
      </div>
    </div>
  )
}

const MPlatform = ({ step, title }) => {
  const { state: rootState } = React.useContext(C)
  const state = useReactive({})
  const {
    metaLoading = false,
    googleLoading = false,
    loop = false,
    metaCheck = false,
    googleCheck = false,
  } = state

  const { REACT_APP_ENV: ENV } = process.env

  const { run } = useRequest(
    async () => {
      const { data } = await api.request({
        url: '3/platform/google_check', // TODO: update url?
      })
      if (+new Date() - startTime > 3 * 60 * 1000) {
        state.googleLoading = false
        state.loop = false
        return
      }
      if (data?.result) {
        state.googleCheck = true
        state.googleLoading = false
        state.loop = false
      }
    },
    {
      pollingInterval: state.loop ? 5 * 1000 : 0,
      manual: true,
      refreshDeps: [state.loop],
    }
  )

  const handleConnect = async platform => {
    const isGoogle = platform == 'google'
    state[isGoogle ? 'googleLoading' : 'metaLoading'] = true
    if (ENV == 'dev') {
      const { data } = await api.request({
        method: 'post',
        url: 'platform/connect ',
        data: { platform },
      })

      if (!data.result) {
        Message.warn(data.message)
      } else {
        state[isGoogle ? 'googleCheck' : 'metaCheck'] = true
      }
      state[isGoogle ? 'googleLoading' : 'metaLoading'] = false
      return
    }
    try {
      if (platform == 'meta') {
        FB ?? Fbevent.init()
        Fbevent.do_facebook(facebook_obj => {
          state.metaCheck = true
        })
        state.metaLoading = false
        return
      }
      window.open(
        'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email%20https%3A//www.googleapis.com/auth/userinfo.profile%20https%3A//www.googleapis.com/auth/adwords&include_granted_scopes=true&response_type=code&access_type=offline&state=state_parameter_passthrough_value&redirect_uri=https%3A//user.cactivate.com/google.html&client_id=795965339466-9udikc1r23mrkjne0dqj089ddel2rb3b.apps.googleusercontent.com&prompt=consent',
        '_blank'
      )
      startTime = +new Date()
      state.loop = true
      run()
    } catch (e) {}
  }

  const handleUnconnect = async platform => {
    const isGoogle = platform == 'google'
    if (loop && isGoogle) {
      state.googleLoading = false
      state.loop = false
      state.googleCheck = false
      return
    }
    state[isGoogle ? 'googleLoading' : 'metaLoading'] = true

    const { data } = await api
      .request({
        url: 'platform/disconnect',
        params: {
          platform: isGoogle ? 'google' : 'meta',
        },
      })
      .finally(
        () => (state[isGoogle ? 'googleLoading' : 'metaLoading'] = false)
      )
    if (data?.result) {
      rootState.step = 5
      state[isGoogle ? 'googleCheck' : 'metaCheck'] = false
    }
  }

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      {rootState.step > step && <Icons.Done className={styles.done} />}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />
        <div>{title}</div>
        <div className={styles.platform}>
          <Spin spinning={metaLoading}>
            <div
              className={cls(styles.item, {
                [styles.active]: metaCheck,
              })}
              onClick={() =>
                !metaCheck ? handleConnect('meta') : handleUnconnect('meta')
              }
            >
              <Icons.Meta />
              <span>Connect Meta Account</span>
              {metaCheck ? <Icons.Checked /> : <Icons.Uncheck />}
            </div>
          </Spin>
          <Spin spinning={googleLoading}>
            <div
              className={cls(styles.item, {
                [styles.active]: googleCheck,
              })}
              onClick={() =>
                !googleCheck
                  ? handleConnect('google')
                  : handleUnconnect('google')
              }
            >
              <Icons.Google />
              <span>Connect Google Account</span>
              {googleCheck ? <Icons.Checked /> : <Icons.Uncheck />}
            </div>
          </Spin>

          {rootState.step == 5 && (
            <Button
              type="primary"
              disabled={!googleCheck && !metaCheck}
              onClick={() => {
                rootState.isMeta = metaCheck
                rootState.isGoogle = googleCheck
                rootState.step = metaCheck ? 6 : 7
              }}
              className={styles.submitBtn}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const MSelectPlatform = ({ step, title, name }) => {
  const { state: rootState } = React.useContext(C)
  const [val, setVal] = React.useState(null)
  const [load, setLoad] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const state = useReactive({ verify: {} })

  const { loading, data } = useRequest(async () => {
    const { data } = await api.request({
      url: 'platform/load',
      params: {
        type: 'account',
        platform: name,
      },
    })
    return data?.length ? data : []
  })

  const handleSubmit = useLockFn(async () => {
    setLoad(true)
    const { data } = await api
      .request({
        url: 'onboard/save',
        method: 'post',
        data: {
          [name]: val,
        },
      })
      .finally(() => setLoad(false))

    if (data?.result) {
      setShow(false)
      return (rootState.step =
        rootState.isGoogle && rootState.step == 6 ? 7 : 8)
    }
    Message.warn(data?.message ?? 'submit Fail')
  })

  const handlVerify = async (type, id, msg, feedback) => {
    let params = { type, id }
    const {
      data: { result, message },
    } = await api.fetchPlatformVerify(params)
    state[msg] = message
    state[feedback] = result ? 'success' : 'fail'
    state.verify[type] = result
  }

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      {rootState.step > step && <Icons.Done className={styles.done} />}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />

        <div className={styles.select}>
          <div className={styles.title}>{title}</div>
          <Spin spinning={loading}>
            <div className={styles.selectBox}>
              <Select
                labelInValue
                defaultValue={val?.value}
                onChange={e => {
                  handlVerify(
                    rootState.step == 6 ? 'account' : 'googleAccount',
                    e,
                    'accountMessage',
                    'accountFeedback'
                  )
                  setVal({
                    account_id: e.value,
                    account_name: e.label,
                  })
                  setShow(true)
                }}
                popupClassName={styles.selectDown}
                options={data}
              />

              {state.accountFeedback == 'success' && (
                <Icons.Success
                  className={cls(styles.verifyIcon, styles.success)}
                />
              )}
              {state.accountFeedback == 'fail' && (
                <Icons.Error className={cls(styles.verifyIcon, styles.fail)} />
              )}
            </div>
          </Spin>
        </div>

        {show && (
          <Button
            type="primary"
            disabled={!val || state.accountFeedback != 'success'}
            loading={load}
            className={styles.submitBtn}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        )}
      </div>
    </div>
  )
}

const MSelect = ({ step, title }) => {
  const { state: rootState } = React.useContext(C)

  const [val, setVal] = React.useState(null)
  const [load, setLoad] = React.useState(false)
  const [show, setShow] = React.useState(false)

  const handleSubmit = async () => {
    setLoad(true)
    await api
      .request({
        url: 'onboard/save',
        method: 'post',
        data: {
          multiple_group: val === 2,
        },
      })
      .finally(() => setLoad(false))
    rootState.structure = val
    rootState.step = step + 1
    setShow(false)
  }

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      {rootState.step > step && <Icons.Done className={styles.done} />}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />

        <div className={styles.select}>
          <div className={styles.title}>{title}</div>
          {/* <Spin spinning={loading}> */}
          <Select
            defaultValue={val}
            onChange={e => {
              setVal(e)
              setShow(true)
            }}
            popupClassName={styles.selectDown}
            options={[
              {
                value: 1,
                label:
                  'I have a total daily budget and I want to maximize sales/leads across the board',
              },
              {
                value: 2,
                label:
                  'I group my campaigns and budgets based on locations, product/service, landing page, goals, etc.',
              },
            ]}
          />
          {/* </Spin> */}
        </div>
        {show && (
          <Button
            type="primary"
            disabled={!val}
            loading={load}
            className={styles.submitBtn}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        )}
      </div>
    </div>
  )
}

const MAbout = ({ step, title }) => {
  const { state: rootState, form } = React.useContext(C)

  const info = Form.useWatch('groups') || []
  const [generateIdx, setGenerateIdx] = React.useState(-1)
  const [show, setShow] = React.useState(true)

  const disableGenerate = index => {
    const website = form.getFieldValue(['groups', index, 'url'])
    return !website || !urlReg.test(website)
  }
  const disableConfirm = info.some(
    (item, index) =>
      !item || !item.name || !item.description || disableGenerate(index)
  )

  const generate = async index => {
    setGenerateIdx(index)
    const url = form.getFieldValue(['groups', index, 'url'])
    const { data } = await api
      .request({ url: 'tool/analyze', params: { url } })
      .finally(() => setGenerateIdx(-1))
    form.setFieldValue(['groups', index, 'description'], data)
  }

  const { loading, run } = useRequest(
    async () => {
      await api.request({
        url: 'onboard/save',
        method: 'post',
        data: {
          groups: form.getFieldValue('groups'),
        },
      })
      rootState.step = step + 1
      setShow(false)
    },
    { manual: true }
  )

  return (
    <div className={s.robot}>
      {/* <Icons.Robot /> */}
      {rootState.step > step && <Icons.Done className={styles.done} />}
      <div className={cls(s.txt, s.center)}>
        <Icons.Icon2 className={s.icon2} />

        <div className={styles.about}>
          <div className={styles.title}>{title}</div>
          <Form.List
            name="groups"
            initialValue={rootState.structure === 1 ? [{}] : [{}, {}]}
            rules={[
              {
                validator: async (_, names) => {},
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div className={styles.line}>
                    <div className={styles.info} key={key}>
                      <div className={styles.infoLable}>
                        <em>*</em>Group{name + 1}
                      </div>
                      <div className={styles.name}>
                        <Form.Item
                          name={[name, 'name']}
                          label="name:"
                          rules={[
                            {
                              required: true,
                              message: 'Cannot be empty',
                            },
                          ]}
                        >
                          <Input placeholder="Group 1 Name" bordered={false} />
                        </Form.Item>
                      </div>
                      <div className={styles.website}>
                        <Form.Item
                          name={[name, 'url']}
                          label="Website:"
                          rules={[
                            {
                              required: true,
                              message: 'Cannot be empty',
                            },
                            {
                              pattern: urlReg,
                              message: 'URL is not valid!',
                            },
                          ]}
                        >
                          <Input
                            bordered={false}
                            placeholder="Your website address"
                          />
                        </Form.Item>
                        <Button
                          type="primary"
                          shape="round"
                          disabled={disableGenerate(index)}
                          loading={generateIdx === index}
                          onClick={() => generate(index)}
                        >
                          Generate Description
                        </Button>
                      </div>
                      <div className={styles.desc}>
                        <Form.Item
                          name={[name, 'description']}
                          label="description:"
                          rules={[
                            {
                              required: true,
                              message: 'Cannot be empty',
                            },
                          ]}
                        >
                          <Input.TextArea
                            maxLength={150}
                            showCount
                            bordered={false}
                            rows={3}
                            name="description"
                            placeholder="Generating description..."
                          />
                        </Form.Item>
                      </div>
                    </div>

                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        className={styles.remove}
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                ))}
                {rootState.structure === 2 && (
                  <Icons.AddGroup
                    className={styles.add}
                    onClick={() => {
                      add()
                    }}
                  />
                )}
              </>
            )}
          </Form.List>
        </div>

        {show && (
          <Button
            className={styles.submitBtn}
            type="primary"
            disabled={disableConfirm}
            loading={loading}
            onClick={run}
          >
            Confirm
          </Button>
        )}
      </div>
    </div>
  )
}
