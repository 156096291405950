import { cloneElement } from 'react'
import { Form } from 'antd'
import { useUpdateEffect } from 'ahooks'
import s from './index.module.scss'
// import Plus from 'ico/v3-create/plus.svg'
// import Minus from 'ico/v3-create/minus.svg'
import Close from 'ico/v3-create/close2.svg'

export default function FormList({
  name,
  label,
  min = 1,
  max = 5,
  rules,
  itemRules,
  children,
}) {
  /**
   * @param {*} add
   * @param {Element} target
   */
  const addItem = async (add, target) => {
    add()
    await Promise.resolve()
    target.querySelector('.ant-input').focus()
  }

  const uniqueRule = {
    validator(_, value) {
      value = (value || []).filter(Boolean)
      return value.length > [...new Set(value)].length
        ? Promise.reject(new Error('Cannot create duplicate'))
        : Promise.resolve()
    },
  }

  const form = Form.useFormInstance()
  const value = Form.useWatch(name)
  useUpdateEffect(() => {
    value[0] !== undefined && form.validateFields([name])
  }, [value])

  return (
    <Form.List
      name={name}
      rules={(rules || []).concat(uniqueRule)}
      initialValue={Array(min).fill(undefined)}
    >
      {(fields, { add, remove }, { errors }) => (
        <div className={s.list}>
          {fields.map((field, index) => (
            <div key={field.key} className={s.item}>
              <Form.Item
                style={{ flex: 1 }}
                label={index === 0 ? label : undefined}
                name={field.name}
                rules={itemRules}
                validateTrigger={false}
              >
                {cloneElement(children, {
                  maxLength: undefined,
                  showCount: !!children.props.maxLength && {
                    formatter: ({ count }) =>
                      `${
                        children.props.maxLength - count
                      } characters remaining`,
                  },
                  onChange(e) {
                    const { maxLength } = children.props
                    if (!maxLength) return
                    const { classList } = e.target.parentNode.parentNode
                    e.target.value.length > maxLength
                      ? classList.add('overlimit')
                      : classList.remove('overlimit')
                  },
                })}
              </Form.Item>
              <div className={s.itemAddon}>
                {fields.length > min && <Close onClick={() => remove(index)} />}
              </div>
            </div>
          ))}
          <Form.ErrorList errors={errors} />
          {fields.length < max && (
            <div className={s.btn} onClick={e => add()}>
              Add {label.replace(/(\s\(.+\))?:/, '').toLowerCase()} option
              <span className={s.plus}>+</span>
            </div>
          )}
        </div>
      )}
    </Form.List>
  )
}
