/* eslint-disable */
// 设置 rem 函数
export function setRem() {
  const doc = document
  const w = Math.max(doc.documentElement.clientWidth, window.innerWidth || 0)

  let width = w
  width = width > 1600 ? 1600 : width

  const fz = ~~((width * 100000) / 100) / 10000
  doc.getElementsByTagName('html')[0].style.fontSize = `${fz}px`

  const realfz =
    ~~(
      +window
        .getComputedStyle(doc.getElementsByTagName('html')[0])
        .fontSize.replace('px', '') * 10000
    ) / 10000
  if (fz !== realfz) {
    doc.getElementsByTagName('html')[0].style.fontSize = `${
      fz * (fz / realfz)
    }px`
  }
}

// 初始化
setRem()

// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
