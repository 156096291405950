import axios from '@/service/request'
import { fetchUploadMedia, fetchUploadSubmit } from '@/service/media'
import { uid } from 'uid'
import { useState, useMemo } from 'react'
import { useCreation, useLatest, useMemoizedFn } from 'ahooks'
import { useTrackState } from '@/hooks'
import Message from '@/utils/message'

const __DEV__ = process.env.NODE_ENV === 'development'

export const IMG_MAX_SIZE = 5 * 1024 * 1024

export default class Uploader {
  constructor(options) {
    this._options = {
      multiple: false,
      accept: '.jpg, .jpeg, .png, .gif, .mp4, .mov',
      reset: false,
      serial: true,
      reverse: false,
      ...options,
    }
    this.onChange = this._options.onChange
    this.beforeUpload = this._options.beforeUpload
    this.afterUpload = this._options.afterUpload
    this.accept = this._options.accept
    this.serial = this._options.serial
    this._files = []
    this._createInput()
  }

  _createInput() {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = () => {
      const files = Array.from(input.files)
      files.forEach(file => this._touchFile(file, 'waiting'))
      this.upload(files, false)
    }

    this.input = input
  }

  _touchFile(file, status, data) {
    let _file = this._files.find(v => v.rawFile === file)
    if (!_file && status === 'remove') return
    if (!_file) {
      _file = {
        uid: uid(),
        rawFile: file,
        name: file.name,
        type: file.type.split('/')[0],
        status,
      }
      this._options.reverse
        ? this._files.unshift(_file)
        : this._files.push(_file)
    } else {
      Object.assign(_file, { status }, data)

      if (status === 'remove') {
        this._files.splice(this._files.indexOf(_file), 1)
      }
    }
    this.onChange?.(_file, this._files)
  }

  _beforeUpload(files) {
    const newFiles = files.filter(file =>
      this.accept.split(/,\s*/).includes(file.name.match(/\.\w+$/)?.[0])
    )
    if (newFiles.length < files.length) {
      Message.warn(`Only support ${this.accept} files`)
    }
    return newFiles.length ? newFiles : false
  }

  reset() {
    this.input.value = ''
    this._files = []
  }

  remove(file) {
    this._touchFile(file, 'remove')
  }

  chooseFile(optionsOrFunc) {
    let options = optionsOrFunc
    if (typeof options === 'function') {
      options = { onChange: options }
    }
    options = { ...this._options, ...options }
    this.onChange = options.onChange
    this.beforeUpload = options.beforeUpload
    this.afterUpload = options.afterUpload
    this.input.multiple = options.multiple
    this.input.accept = this.accept = options.accept
    if (options.type === 'image') {
      this.input.accept = '.jpg, .jpeg, .png, .gif'
    } else if (options.type === 'video') {
      this.input.accept = '.mp4, .mov'
    }
    options.reset && this.reset()
    this.input.click()
  }

  async upload(files, touchWaiting = true) {
    files = Array.from(files)
    const beforeUpload = (this.beforeUpload || this._beforeUpload).bind(this)
    const result = await beforeUpload(files)
    if (result === false) {
      files.forEach(file => this.remove(file))
      return
    }
    if (Array.isArray(result)) {
      files.forEach(file => !result.includes(file) && this.remove(file))
      files = result
    }
    if (this.serial) {
      const output = []
      touchWaiting && files.forEach(file => this._touchFile(file, 'waiting'))
      const sortedFiles = this._options.reverse
        ? files.slice().reverse()
        : files
      for (const file of sortedFiles) {
        try {
          let data = await this._uploadFile(file)
          data = (await this.afterUpload?.(data)) || data
          this._touchFile(file, 'success', data)
          output.push({ status: 'fulfilled', value: data })
        } catch (err) {
          console.error(err)
          this._touchFile(file, 'error')
          output.push({ status: 'rejected', reason: err })
        }
      }
      return output
    }
    return Promise.allSettled(
      files.map(file =>
        this._uploadFile(file)
          .then(async data => {
            data = (await this.afterUpload?.(data)) || data
            this._touchFile(file, 'success', data)
            return data
          })
          .catch(err => {
            this._touchFile(file, 'error')
            return Promise.reject(err)
          })
      )
    )
  }

  async _uploadFile(file) {
    this._touchFile(file, 'uploading')
    const { urls } = (await fetchUploadMedia({ file_names: [file.name] })).data
    await axios({
      method: 'put',
      url: urls[0].url,
      data: file,
      timeout: 10 * 60 * 1000,
      headers: {
        'Content-Type': urls[0].content_type,
      },
    })
    const { data } = await fetchUploadSubmit({
      file_name: urls[0].file_name,
      uuids: urls.map(v => v.uuid),
    })
    if (!data.result) return Promise.reject('upload fail')
    const url = urls[0].url.split('?')[0]
    const uuid = urls[0].uuid
    if (urls[0].content_type.includes('video')) {
      return { url, uuid, type: 'video' }
    }
    const { width, height } = await getImgInfo(file)
    const resolution = [width, height].join('*')
    return { url, uuid, resolution }
    // await new Promise(r => setTimeout(r, 1000))
    // return {
    //   url: URL.createObjectURL(file),
    //   uuid: uid(),
    // }
  }
}

export const uploader = new Uploader({ reset: true })

export const transformUrl = url => url.replace(/\.(mp4|mov)$/i, '.jpg')

export const match = (url, type) => {
  const regExp = type === 'image' ? /\.(jpe?g|png|gif)$/i : /\.(mp4|mov)$/i
  return regExp.test(url)
}

export const useUploadList = ({ defaultFileList, ...options }) => {
  const [remoteList, setRemoteList] = useTrackState(defaultFileList)
  const [localList, setLocalList] = useState([])
  const list = useMemo(
    () => [].concat(localList, remoteList),
    [localList, remoteList]
  )
  const uploader = useCreation(
    () =>
      new Uploader({
        multiple: true,
        ...options,
        onChange(file, files) {
          setLocalList(files.slice())
          options.onChange?.(file, files)
        },
      }),
    []
  )
  const remove = useMemoizedFn(item => {
    if (remoteList.includes(item)) {
      setRemoteList(list => list.filter(v => v !== item))
    } else if (localList.includes(item)) {
      uploader.remove(item.rawFile)
    }
  })

  const resetUploader = useMemoizedFn(() => {
    uploader.reset()
    setLocalList([])
    setRemoteList([])
  })

  const latestRemoteList = useLatest(remoteList)
  const paginationSelector = (data, exclude) => {
    const list = latestRemoteList.current
    return data.reduce(
      (arr, item) => (exclude(list, item) ? arr : arr.concat(item)),
      list
    )
  }

  return { uploader, resetUploader, list, remove, paginationSelector }
}

export function getImgAspectRatio(source) {
  return new Promise((resolve, reject) => {
    if (source instanceof File) {
      source = URL.createObjectURL(source)
    }
    const img = new Image()
    img.onload = () => {
      resolve(img.width / img.height)
      URL.revokeObjectURL(source)
    }
    img.onerror = reject
    img.src = source
  })
}

export function getImgInfo(source) {
  let size, ratio
  return new Promise((resolve, reject) => {
    if (source instanceof File) {
      size = source.size
      source = URL.createObjectURL(source)
    }
    const img = new Image()
    img.onload = async () => {
      const { width, height } = img
      ratio = +(width / height).toFixed(2)
      URL.revokeObjectURL(source)
      if (!size && !__DEV__) {
        const blob = await fetch(source)
          .then(res => res.blob())
          .catch(() => ({}))
        size = blob.size
      }
      resolve({ ratio, width, height, size })
    }
    img.onerror = reject
    img.src = source
  })
}
