/*
 * @Description:
 * @Author: Mark
 * @Date: 2021-11-02 17:50:39
 * @LastEditors: Mark
 * @LastEditTime: 2021-11-03 09:44:22
 */

import _axios, { request } from './request'
import {
  doBusiness,
  businessSwitch,
  doLoginUrl,
  updateBudget,
  profileIns,
  pause,
  businessNew,
  businessStatus,
  businessBudget,
  billingProducts,
  billingProductUpdate,
} from './url'

export const fetchPause = params => {
  return _axios({
    url: pause,
    params,
  })
}

export const fetchUpdateBudget = data => {
  return _axios({
    url: updateBudget,
    method: 'post',
    data,
  })
}

export const doLogin = () => {
  return _axios({
    url: doLoginUrl,
    cancelable: true,
  })
}

export const fetchBusiness = () => {
  return _axios({
    url: doBusiness,
    cancelable: true,
  })
}

export function fetchInsInfo(params) {
  return _axios({
    url: profileIns,
    params,
  })
}

export function turnOnService() {
  return request({ url: '/user/turn_on' })
}

export function fetchUserBusinessSwitch(params) {
  return request({ url: businessSwitch, params })
}

export function fetchUserBusinessNew() {
  return _axios({ url: businessNew })
}

export function fetchBusinessStatus(params, loadingCat = true) {
  return _axios({ url: businessStatus, params, loadingCat })
}

export function fetchBusinessBudget(data, loadingCat = true) {
  return _axios({
    url: businessBudget,
    method: 'POST',
    data,
    loadingCat,
    handleError: true,
  })
}

export function setupBusiness() {
  return request({
    method: 'POST',
    url: '/business/setup',
  })
}

export const fetchBillingProducts = params => {
  return _axios({
    url: billingProducts,
    params,
  })
}

export function fetchBillingProductUpdate(data) {
  return _axios({
    url: billingProductUpdate,
    method: 'POST',
    data,
  })
}
