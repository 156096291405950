/**
 * @param {EventTarget} target
 * @param {string | string[]} type
 * @param {EventListener} listener
 * @param {AddEventListenerOptions | boolean} options
 * @returns cleanup
 */
export function on(target, type, listener, options) {
  if (!Array.isArray(type)) {
    type = [type]
  }
  const stops = type.map(tp => {
    target.addEventListener(tp, listener, options)
    return () => target.removeEventListener(tp, listener, options)
  })

  return () => stops.forEach(stop => stop())
}
