import { useRef, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import {
  Spin,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Radio,
  Select,
} from 'antd'
import {
  useRequest,
  useDebounceFn,
  useReactive,
  useUpdateEffect,
  useLatest,
} from 'ahooks'
import moment, { isMoment } from 'moment'
import { isPlainObject, isArray, isNumber } from 'lodash'
import cls from 'classnames'
import TargetSuggest from '@/components/target-suggest'
import FormList from '../form-list'
import Creatives, { getMediaInfo } from '../creatives'
import { MediaPicker } from '@/pages/v3-media-library'
import {
  CampaignType,
  RadioButtonGroup,
  GenderSlider,
  TargetType,
} from './controls'
import * as api from '@/service/campaign'
import { IMG_MAX_SIZE } from '@/utils/uploader'
import { IMGS_AVAILABLE_FOR_GOOGLE } from '@/utils/constant'
import { Modal } from '@/utils'
import capitalize from 'lodash/capitalize'

import s from './index.module.scss'
import Check from 'ico/v3-media/check.svg'
import Lamp from 'ico/v3-create/lamp.svg'
import Rocket from 'ico/v3-create/rocket.svg'
import Lock from 'ico/v3-create/lock.svg'
import Close from 'ico/v3-create/close.svg'
import Warn from 'ico/v3-create/warn.svg'
import Edit from 'ico/v3-create/edit.svg'
import Plus from 'ico/v3-media/plus.svg'
import Tag from 'ico/v3-create/tag.svg'
import Google from 'ico/dashboard/google.svg'
import assistant from '@/assets/images/assistant.png'

const noop = () => {}

let leadformPromise

const padEnd = (arr, maxLength, fillVal) => {
  while (arr.length < maxLength) {
    arr.push(fillVal)
  }
  return arr
}

const mapStatus = status => {
  const map = {
    running: 'active',
    waiting: 'queue',
    pause: 'paused',
    error: 'error',
    draft: 'draft',
  }
  return map[status] || status
}

export const GoogleCreation = ({
  source,
  status = 'draft',
  cid,
  thread,
  copy = false,
  imported,
  hasAutoSaved,
  onOk = noop,
  onCancel = noop,
  modal = true,
}) => {
  const { objective, min_google_budget, currency_symbol } = useSelector(
    state => state.user.current
  )
  const [form] = Form.useForm()
  const media = Form.useWatch(['creative', 'media'], form) || []
  const asset_suggestion = Form.useWatch('asset_suggestion', form)
  const ad_type = Form.useWatch('ad_type', form)
  const budget = Form.useWatch('budget', form)
  const today = useRef(moment()).current
  const [suggestion, setSuggestion] = useState(
    {} /* {
    suggestion_type: 'factor', // idea | factor
    suggestion_text: 'Test Age',
    suggestion_message: 'Editing an active ad might reset your learning phase.',
    suggestion_highlight: 'active ad',
    suggestion_factor: 'age', // age | gender | interest | location
  } */
  )
  const [scheduleManual, setScheduleManual] = useState(0)
  const [disableSubmit, setDisableSubmit] = useState(false)

  if (process.env.NODE_ENV === 'development') {
    window.form = form
  }

  const isDraft = ['draft', 'idea'].includes(status)

  const [spinning, setSpinning] = useState(false)
  const initialData = useRef()
  useEffect(() => {
    if (cid || thread) {
      setSpinning(true)
      api
        .fetchCampaignDetail(
          Object.assign(
            {},
            cid ? { id: cid } : thread,
            source === 'chat' && { skip: true }
          )
        )
        .then(({ data }) => {
          if (copy) delete data.id
          const { headline, long_headline, description } = data.creative
          padEnd(headline, 3, undefined)
          padEnd(long_headline, 1, undefined)
          padEnd(description, 2, undefined)
          initialData.current = data
          form.setFieldsValue(data)
          form.validateFields([['creative', 'media']])
          onValuesChange()
          data.suggestion && setSuggestion(data.suggestion)
          setScheduleManual(data.schedule?.startDate ? 1 : 0)
        })
        .finally(() => setSpinning(false))
    }
    return cancelAutoSave
  }, [])

  const needLeadform = objective === 'LEAD_GENERATION'
  const [leadformOpts, setLeadformOpts] = useState([])
  useEffect(() => {
    if (!needLeadform) return
    if (!leadformPromise) {
      leadformPromise = api.fetchPlatformGoals('google', {
        type: 'leadform',
      })
    }
    leadformPromise.then(({ data }) => setLeadformOpts(data))
  }, [needLeadform])

  const [showToast, setShowToast] = useState(false)
  const { run: autoSave, cancel: cancelAutoSave } = useDebounceFn(
    async () => {
      const values = form.getFieldsValue()
      if (!values.name || submitFlag) return
      values.description = suggestion?.suggestion_message
      const { data } = await api.saveGoogleDraft(values)
      form.setFieldValue('id', data.id)
      hasAutoSaved && (hasAutoSaved.current = true)
      setShowToast(true)
    },
    {
      wait: 3000,
    }
  )

  const [submitFlag, setSubmitFlag] = useState('')
  /**
   * @param {'submit' | 'run'} flag
   */
  const handleSubmit = async flag => {
    cancelAutoSave()
    const values = form.getFieldsValue()
    values.description = suggestion?.suggestion_message
    setSubmitFlag(flag)
    await api.submitGoogleCampaign(values).finally(() => setSubmitFlag(''))
    !modal && setDisableSubmit(true)
    onOk()
  }

  const [pass, setPass] = useState([false, false, false, false])
  const hasError = nameList => {
    return form.getFieldsError(nameList).some(item => item.errors.length)
  }
  const isEmpty = value => [null, undefined, ''].includes(value)
  const hasEmpty = (values, areEmpty) => {
    return Object.entries(values).some(([key, value]) => {
      if (areEmpty?.[key]) return areEmpty[key](value)
      if (isMoment(value)) return false
      if (isPlainObject(value)) return hasEmpty(value, areEmpty)
      if (isArray(value)) return !value.length || hasEmpty(value)
      return isEmpty(value)
    })
  }
  const isPass = (nameList, areEmpty) =>
    !hasError(nameList) && !hasEmpty(form.getFieldsValue(nameList), areEmpty)
  const onValuesChange = async value => {
    await new Promise(r => setTimeout(r))
    const isPmax = form.getFieldValue('ad_type') === 'pmax'
    const p1 = isPass(
      [
        'name',
        'link',
        needLeadform && 'leadform_id',
        ...(isPmax ? ['logo', 'brand_name'] : []),
      ].filter(Boolean)
    )
    const p2 = isPass(
      [ad_type === 'search' && 'keywords', 'location'].filter(Boolean),
      {
        // interest: value => !value?.length,
        location: value => !value?.length,
      }
    )
    const p3 = latest.current.mediaValidation.pass
    const p4 = isPass(
      [
        // ['creative', 'media'],
        ['creative', 'headline'],
        ['creative', 'description'],
        ...(isPmax
          ? [
              ['creative', 'long_headline'],
              // ['creative', 'youtube'],
            ]
          : []),
      ],
      {
        youtube: value =>
          (value.length > 1 && value.some(v => !v)) ||
          value.some(
            v => v && !v.startsWith('https://www.youtube.com/watch?v=')
          ),
      }
    )
    setPass([p1, p2, p3, p4])
    isDraft && value && autoSave()
  }

  const medias = Form.useWatch(['creative', 'media'], form)
  const [mediaValidation, accept] = useMediaValidation({
    adType: ad_type,
    medias,
  })
  const latest = useLatest({ mediaValidation })

  const is = factor => suggestion.suggestion_factor === factor

  const disableDate = name => {
    const value = initialData.current?.schedule[name]
    return !isDraft && value && moment().isAfter(value, 'd')
  }

  const _onCancel = async () => {
    if (submitFlag === 'submit') {
      await Modal.confirm({
        type: 'delete',
        title: 'Are you sure to leave?',
        subtitle: 'Your changes may not have been saved',
      })
    }
    onCancel()
  }

  return (
    <Spin spinning={spinning} size="large">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <div className={cls(s.wrap, 'nice-scrollbar', imported && s.imported)}>
          <CSSTransition
            in={showToast}
            classNames={{
              enter: 'animate__animated',
              enterActive: 'animate__fadeInDown',
              exit: 'animate__animated',
              exitActive: 'animate__fadeOut',
            }}
            timeout={500}
            mountOnEnter
            unmountOnExit
            onEntered={() => setTimeout(() => setShowToast(false), 2000)}
          >
            <div className={s.toastWrap}>
              <div className={s.toast}>
                <Check />
                Autosaved draft
              </div>
            </div>
          </CSSTransition>
          <div className={s.header}>
            <span className={s.platform}>
              <Google />
            </span>
            <div className={cls(s.status, s[mapStatus(status)])}>
              <Tag className="fill-color-strict" />
              <span>{capitalize(mapStatus(status))}</span>
            </div>
          </div>
          <div className={cls(s.body)}>
            <div className={s.content}>
              <div className={s.main}>
                <div className={s.left}>
                  <div className={s.block}>
                    <div className={s.source}>
                      <Check className={cls(s.pass, !pass[0] && s.unready)} />
                      Campaign Setup:
                    </div>
                    <Form.Item noStyle name="id">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      label="Campaign Name:"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Name is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Type:"
                      name="ad_type"
                      initialValue="search"
                    >
                      <RadioButtonGroup
                        options={[
                          { label: 'Search', value: 'search' },
                          { label: 'Performance Max', value: 'pmax' },
                        ]}
                        disabled={!isDraft}
                        onChange={async value => {
                          await new Promise(r => setTimeout(r))
                          const fields = [
                            ['creative', 'media'],
                            ['creative', 'headline'],
                            ['creative', 'description'],
                          ]
                          if (value === 'pmax') {
                            const { long_headline, youtube } =
                              form.getFieldValue('creative')
                            fields.push(
                              ...long_headline.map((_, i) => [
                                'creative',
                                'long_headline',
                                i,
                              ])
                            )
                            fields.push(
                              ...youtube.map((_, i) => [
                                'creative',
                                'youtube',
                                i,
                              ])
                            )
                          }
                          form.validateFields(fields)
                        }}
                      />
                    </Form.Item>
                    {needLeadform && (
                      <Form.Item
                        label="Lead Form:"
                        name="leadform_id"
                        rules={[
                          { required: true, message: 'Lead form is required' },
                        ]}
                      >
                        <Select
                          popupClassName="custom-select-dropdown"
                          dropdownStyle={{ zIndex: 1052 }}
                          options={leadformOpts}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      label="Link:"
                      name="link"
                      rules={[
                        {
                          required: true,
                          type: 'url',
                          message: 'Link is invalid',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    {ad_type === 'pmax' && (
                      <>
                        <Form.Item
                          label="Logo:"
                          name="logo"
                          // rules={[{ required: true, message: 'Logo is required' }]}
                        >
                          <LogoUpload />
                        </Form.Item>
                        <Form.Item
                          label="Brand Name:"
                          name="brand_name"
                          rules={[
                            {
                              required: true,
                              message: 'Brand Name is required',
                            },
                          ]}
                        >
                          <Input maxLength={25} />
                        </Form.Item>
                      </>
                    )}
                    {/* <Form.Item label="Schedule:">
                      <RadioButtonGroup
                        disabled={!isDraft}
                        options={[
                          { value: 0, label: 'Let AI Decide' },
                          { value: 1, label: 'Set Start & End Date' },
                        ]}
                        value={scheduleManual}
                        onChange={setScheduleManual}
                      />
                    </Form.Item> */}
                    <Form.Item label="Schedule:" className={s.rangePicker}>
                      <Form.Item
                        className={s.pickerWrap}
                        name={['schedule', 'startDate']}
                        initialValue={today}
                      >
                        <DatePicker
                          placeholder="Start Date"
                          disabledDate={current =>
                            current &&
                            ((form.getFieldValue(['schedule', 'endDate']) &&
                              current >
                                form.getFieldValue(['schedule', 'endDate'])) ||
                              current < today)
                          }
                          getPopupContainer={() =>
                            document.querySelector(`.${s.body}`)
                          }
                          allowClear={false}
                          disabled={disableDate('startDate')}
                        />
                      </Form.Item>
                      <Form.Item
                        className={s.pickerWrap}
                        name={['schedule', 'endDate']}
                      >
                        <DatePicker
                          placeholder="End Date"
                          disabledDate={current =>
                            current &&
                            (current <
                              form.getFieldValue(['schedule', 'startDate']) ||
                              current < today)
                          }
                          getPopupContainer={() =>
                            document.querySelector(`.${s.body}`)
                          }
                          disabled={disableDate('endDate')}
                        />
                      </Form.Item>
                    </Form.Item>
                  </div>

                  <div className={s.block}>
                    <Check className={cls(s.pass, !pass[1] && s.unready)} />
                    <Form.Item
                      className={s.inline}
                      label="Audience:"
                      // name="audience"
                      // rules={[
                      //   { required: true, message: 'Audience is required' },
                      // ]}
                    >
                      {suggestion.suggestion_type === 'factor' && !is('text') && (
                        <div className={s.factor}>
                          <Rocket />
                          {suggestion.suggestion_text}
                        </div>
                      )}
                    </Form.Item>
                    {ad_type === 'search' && (
                      <Form.Item
                        className={is('keywords') && s.highlight}
                        label={
                          <span>
                            Keywords:
                            <em>Separate by comma</em>
                          </span>
                        }
                        name="keywords"
                        rules={[
                          {
                            required: true,
                            message: 'Keywords is required',
                          },
                        ]}
                      >
                        <KeywordsInput />
                      </Form.Item>
                    )}
                    <Form.Item
                      className={is('location') && s.highlight}
                      label="Location:"
                      name="location"
                      rules={[
                        { required: true, message: 'Location is required' },
                      ]}
                    >
                      <TargetSuggest type="location" from="google" />
                    </Form.Item>
                  </div>
                </div>
                <div className={s.right}>
                  {ad_type === 'pmax' && (
                    <div className={s.block}>
                      <Check className={cls(s.pass, !pass[2] && s.unready)} />
                      <Form.Item noStyle name="asset_suggestion" />
                      <Form.Item
                        className={s.creatives}
                        label={
                          <>
                            <span>
                              Creatives: {media.length} of 10 max
                              {ad_type === 'search' ? ' (Optional)' : ''}
                            </span>
                            <div className={s.validators}>
                              {mediaValidation.required.map(item => (
                                <span
                                  key={item.type}
                                  className={cls(
                                    s.validator,
                                    item.pass && s.success
                                  )}
                                >
                                  {item.pass && (
                                    <Check className="fill-color" />
                                  )}
                                  At least 1 {item.type} image
                                </span>
                              ))}
                            </div>
                          </>
                        }
                        name={['creative', 'media']}
                        rules={[
                          {
                            validator(_, value) {
                              if (
                                form.getFieldValue('ad_type') === 'pmax' &&
                                (value || []).filter(
                                  v => v.type === 'landscape'
                                ).length > 5
                              ) {
                                return Promise.reject(
                                  new Error(
                                    'The number of landscape images cannot exceed 5'
                                  )
                                )
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                      >
                        <Creatives
                          platform="google"
                          accept={accept}
                          suggestion={asset_suggestion}
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div className={s.block}>
                    <Check className={cls(s.pass, !pass[3] && s.unready)} />
                    <Form.Item
                      className={cls(
                        'copy',
                        s.creatives,
                        is('text') && 'highlight'
                      )}
                      label={
                        <div className="flex-ac">
                          <span style={{ marginRight: 8 }}>Copy</span>
                          {suggestion.suggestion_type === 'factor' &&
                            is('text') && (
                              <div className={s.factor}>
                                <Rocket />
                                {suggestion.suggestion_text}
                              </div>
                            )}
                        </div>
                      }
                    >
                      <FormList
                        label="Headline:"
                        name={['creative', 'headline']}
                        min={3}
                        max={15}
                        itemRules={[
                          {
                            required: true,
                            message: 'Headline is required',
                          },
                        ]}
                        rules={[
                          {
                            validator(_, value) {
                              value = value || []
                              if (
                                form.getFieldValue('ad_type') !== 'pmax' ||
                                value.some(v => !v || v.length <= 15)
                              )
                                return Promise.resolve()
                              return Promise.reject(
                                new Error(
                                  'At least one headline must be less than or equal to 15 characters'
                                )
                              )
                            },
                          },
                        ]}
                      >
                        <Input maxLength={30} />
                      </FormList>
                      {ad_type === 'pmax' && (
                        <FormList
                          label="Long Headline:"
                          name={['creative', 'long_headline']}
                          max={5}
                          itemRules={[
                            {
                              required: true,
                              message: 'Long Headline is required',
                            },
                          ]}
                        >
                          <Input maxLength={90} />
                        </FormList>
                      )}
                      <FormList
                        label="Description:"
                        name={['creative', 'description']}
                        min={2}
                        max={5}
                        itemRules={[
                          {
                            required: true,
                            message: 'Description is required',
                          },
                        ]}
                        rules={[
                          {
                            validator(_, value) {
                              value = value || []
                              if (
                                form.getFieldValue('ad_type') !== 'pmax' ||
                                value.some(v => !v || v.length <= 60)
                              )
                                return Promise.resolve()
                              return Promise.reject(
                                new Error(
                                  'At least one description must be less than or equal to 60 characters'
                                )
                              )
                            },
                          },
                        ]}
                      >
                        <Input.TextArea maxLength={90} />
                      </FormList>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className={s.btnWrap}>
                {modal && (
                  <Button className={s.cancelBtn} onClick={_onCancel}>
                    Cancel
                  </Button>
                )}
                {isDraft ? (
                  <div className={cls(s.run)}>
                    <Form.Item
                      name="budget"
                      noStyle
                      initialValue={min_google_budget}
                    >
                      <InputNumber
                        controls={false}
                        addonBefore={<Lock />}
                        formatter={value => value && currency_symbol + value}
                        parser={value => value.replace(currency_symbol, '')}
                      />
                    </Form.Item>
                    <Button
                      className={s.runBtn}
                      type="primary"
                      loading={submitFlag === 'submit'}
                      disabled={
                        !(pass.every(Boolean) && budget >= min_google_budget) ||
                        disableSubmit
                      }
                      onClick={() => handleSubmit('submit')}
                    >
                      Submit
                    </Button>
                    {isNumber(budget) && budget < min_google_budget && (
                      <div className={s.runErr}>
                        <Warn />
                        <span>
                          Minimum budget is {currency_symbol}
                          {min_google_budget}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <Button
                    className={s.queneBtn}
                    type="primary"
                    loading={submitFlag === 'submit'}
                    disabled={!pass.every(Boolean) || disableSubmit}
                    onClick={() => handleSubmit('submit')}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  )
}

export const LogoUpload = ({ value, onChange }) => {
  const form = Form.useFormInstance()
  const validate = ({ ratio, width, size }) => {
    const errors = []
    if (ratio !== 1) {
      errors.push('Aspect ratio must be 1:1')
    } else if (width < 128) {
      errors.push('The resolution must not be less than 128x128')
    }
    if (size > IMG_MAX_SIZE) {
      errors.push('Logo too large')
    }
    form.setFields([{ name: 'logo', errors }])
  }
  const onOk = ([media]) => {
    onChange(media.url)
    const [width, height] = media.resolution.split('*')
    validate({
      ratio: +(width / height).toFixed(2),
      width,
    })
  }

  useEffect(() => {
    if (value) {
      getMediaInfo(value).then(validate)
    }
  }, [])

  return (
    <MediaPicker type="image" multiple={false} onOk={onOk}>
      {({ trigger }) =>
        value ? (
          <div className={cls(s.logo, 0 && s.error)} onClick={trigger}>
            <img src={value} />
            <Close
              onClick={e => {
                e.stopPropagation()
                onChange('')
                form.setFields([{ name: 'logo', errors: ['Logo is required'] }])
              }}
            />
          </div>
        ) : (
          <span className={s.plus} onClick={trigger}>
            <Plus />
          </span>
        )
      }
    </MediaPicker>
  )
}

export const KeywordsInput = ({ value = [], onChange, ...props }) => {
  const [input, setInput] = useState('')
  const handleSearch = val => {
    // if (value.length >= 10) return
    if (val.includes(',')) {
      const newVal = val.split(',').filter(v => v && !value.includes(v))
      if (newVal.length) {
        onChange(value.concat(newVal))
        setInput('')
      } else {
        setInput(val.replace(/,/g, ''))
      }
    } else {
      setInput(val)
    }
  }

  return (
    <Select
      {...props}
      open={false}
      mode="tags"
      searchValue={input}
      value={value}
      onChange={val => {
        onChange(val)
        setInput('')
      }}
      onSearch={handleSearch}
    />
  )
}

const useMediaValidation = ({ adType = 'search', medias }) => {
  const validation = useReactive({
    search: {
      support: ['square', 'market'],
      required: [{ type: 'square', pass: false }],
      pass: false,
    },
    pmax: {
      support: ['square', 'market', 'portrait', 'landscape'],
      required: [
        { type: 'square', pass: false },
        { type: 'market', pass: false },
      ],
      pass: false,
    },
  })

  const accept = useMemo(
    () =>
      validation[adType].support.map(type =>
        IMGS_AVAILABLE_FOR_GOOGLE.find(item => item.type === type)
      ),
    [adType]
  )

  useEffect(() => {
    if (!medias?.length) {
      validation.search.required = []
      validation.search.pass = true
      validation.pmax.required.forEach(item => (item.pass = false))
      validation.pmax.pass = false
      return
    }
    if (adType === 'search' && !validation.search.required.length) {
      validation.search.required = [{ type: 'square', pass: false }]
      validation.search.pass = false
    }
    const { required, support } = validation[adType]
    const pass =
      required
        .map(
          item =>
            (item.pass = medias.some(
              media => media.type === item.type && !media.errno
            ))
        )
        .every(Boolean) &&
      /* medias.every(
        media =>
          support.includes(media.type) &&
          media.width >= MIN_RESOLUTION[media.type][0] &&
          (!media.size || media.size <= IMG_MAX_SIZE)
      ) && */
      !medias.some(media => media.errno) &&
      medias.filter(media => media.type === 'landscape').length <= 5
    if (validation[adType].pass !== pass) {
      validation[adType].pass = pass
    }
  }, [medias, adType])

  return [validation[adType], accept]
}
