/* eslint-disable */
import { FbConnect } from '@/service/onboard-v3'
import Message from './message'

export default {
  init() {
    !!FB &&
      FB.init({
        appId: '195244401217113',
        cookie: true,
        xfbml: true,
        version: 'v3.2',
      })
    !!FB && FB.AppEvents.logPageView()
    FB?.Event.subscribe('auth.login', function (response) {
      console.log('login_event')
      console.log(response.status)
      console.log(response)
    })
  },
 
  do_facebook(cb) {
    FB.login(
      response => {
        if (response.authResponse) {
          FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
              FB.api(
                '/me',
                { fields: 'last_name,first_name,email,picture' },
                async function (resp) {
                  let facebook_obj = {}
                  facebook_obj.facebook_token =
                    !!response.authResponse &&
                    !!response.authResponse.accessToken
                      ? response.authResponse.accessToken
                      : ''
                  facebook_obj.facebook_id = !!resp['id'] ? resp['id'] : ''
                  let first_name = !!resp['first_name']
                    ? resp['first_name']
                    : ''
                  let last_name = !!resp['last_name'] ? resp['last_name'] : ''
                  facebook_obj.first_name = first_name
                  facebook_obj.last_name = last_name
                  facebook_obj.facebook_name = first_name + ' ' + last_name
                  facebook_obj.facebook_email = !!resp['email']
                    ? resp['email']
                    : ''
                  facebook_obj.facebook_picture =
                    !!resp['picture'] &&
                    !!resp['picture']['data'] &&
                    !!resp['picture']['data']['url']
                      ? resp['picture']['data']['url']
                      : ''
                  const { data, message } = await FbConnect(facebook_obj)
                  if (data.result || message == 'connected') {
                    cb?.(facebook_obj)
                  } else {
                    Message.warn(data.message)
                  }
                }
              )
            } else {
              Message.warn("Facebook Login didn't work. Please try again.")
            }
          })
        } else {
          Message.warn('Facebook login cancelled. Please try again!')
        }
      },
      {
        scope:
          'public_profile,email,ads_read,ads_management,pages_show_list,pages_manage_ads,pages_read_engagement,instagram_basic,leads_retrieval',
      }
    )
  }
}

