import { Modal } from 'antd'
import store from '@/store/store'
import { modifyUser } from '@/store/actions/user.actions'
import { numberFormat } from './common'

import { createSubscription } from '@/hooks'

export const warning = content => {
  return Modal.warning({
    closable: false,
    keyboard: false,
    footer: null,
    centered: true,
    icon: '',
    wrapClassName: 'app-modal',
    content,
  })
}

const { publish: taskStart, useSubscribe: useTaskStart } = createSubscription()
const { publish: taskFinish, useSubscribe: useTaskFinish } =
  createSubscription()

export { useTaskStart, useTaskFinish }

/**
 * @param {keyof typeof messages} pending_type
 * @returns {() => void}
 */
export const showLoadingCat = pending_type => {
  taskStart()
  if (!store.getState().user.current.is_pending) {
    store.dispatch(modifyUser({ is_pending: true, pending_type }))
  }

  return () => {
    taskFinish()
    if (store.getState().user.current.is_pending) {
      store.dispatch(modifyUser({ is_pending: false }))
    }
  }
}

export const currencyFormat = val => {
  const { currency_symbol } = store.getState().user.current
  return currency_symbol + numberFormat(val)
}
