/* eslint-disable */



export const industrys = [
  {
    label: 'Fashion & Apparel',
    value: 'Fashion & Apparel',
  },
  {
    label: 'Baby',
    value: 'Baby',
  },
  {
    label: 'Beauty & Cosmetics',
    value: 'Beauty & Cosmetics',
  },
  {
    label: 'Food & Beverage',
    value: 'Food & Beverage',
  },
  {
    label: 'Consumer Electronics',
    value: 'Consumer Electronics',
  },
  {
    label: 'Fitness & Outdoors',
    value: 'Fitness & Outdoors',
  },
  {
    label: 'Home Decor',
    value: 'Home Decor',
  },
  {
    label: 'Pets',
    value: 'Pets',
  },
  {
    label: 'Travel',
    value: 'Travel',
  },
  {
    label: 'Health & Wellness',
    value: 'Health & Wellness',
  },
  {
    label: 'Arts & Entertainment',
    value: 'Arts & Entertainment',
  },
  {
    label: 'Garden & Outdoor',
    value: 'Garden & Outdoor',
  },
  {
    label: 'Toys & Games',
    value: 'Toys & Games',
  },
  {
    label: 'Sports',
    value: 'Sports',
  },
  {
    label: 'Gifts & Special Events',
    value: 'Gifts & Special Events',
  },
  {
    label: 'Wedding',
    value: 'Wedding',
  }
]

