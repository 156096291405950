import { request } from './request'
import { cloneDeep } from 'lodash'

export function updateAutoApprove(status) {
  return request({
    url: '3/auto_approve',
    params: { status },
  })
}

export function updateAutoDeploy(status, budget) {
  return request({
    url: '3/auto_deploy',
    params: { status, budget },
  })
}

export function fetchGroupSettings() {
  return request({ url: 'group/config/fetch' })
}

export function saveGroupSettings(data) {
  return request({
    method: 'POST',
    url: 'group/config/save',
    data: { settings: data },
  })
}

export function fetchOrderList() {
  return request({
    url: 'order/list',
  })
}

export function fetchStageList(platform) {
  return request({
    url: 'stage/list',
    params: { platform },
  }).then(res => res.data)
}

export function fetchStageConfig(stage_id) {
  return request({
    url: 'stage/config/fetch',
    params: { stage_id },
  }).then(res => res.data)
}

export function fetchOverview(platform) {
  return request({
    url: 'recommendation/overview',
    params: { platform },
  }).then(res => res.data)
}

export function fetchRecommendation(stage_id) {
  return request({
    url: 'recommendation/fetch',
    params: { stage_id },
  }).then(res => res.data)
}

export function confirmRecommendation(recommendation_id) {
  return request({
    url: 'recommendation/confirm',
    params: { recommendation_id },
  })
}

export function dismissRecommendation(recommendation_id) {
  return request({
    url: 'recommendation/dismiss',
    params: { recommendation_id },
  })
}

export function updateStageStatus(stage_id, on) {
  return request({
    url: 'stage/status',
    params: {
      stage_id,
      status: on ? 'ON' : 'OFF',
    },
  })
}
