/* eslint-disable */

import React from 'react'
import { message } from 'antd'
import Loading from '@/assets/images/loading.png'
import Info from '@/assets/images/info.png'
import Success from '@/assets/images/success.png'
import Warn from '@/assets/images/warn.png'
import Warning from '@/assets/images/warning.png'

const MessageIconMap = {
  loading: Loading,
  info: Info,
  success: Success,
  warn: Warn,
  warning: Warning,
}

/**
 * 对应antd的success info warn warning loading
 * Message.success('Short descriptive message')
 * Message.info('Short descriptive message')
 * Message.warn('Short descriptive message')
 * Message.warning('Short descriptive message')
 * Message.loading('Short descriptive message')
 */
export default {
  callMessage(content = '', onClose = ()=>{}, type = 'info') {
    try{
      message.destroy()
      message[type]({
        content,
        className: 'antd-message__custom',
        icon: <img src={MessageIconMap[type]} alt="" />,
        onClose,
      })
    } catch{}
  },
  success(content, onClose) {
    this.callMessage(content, onClose, 'success')
  },
  info(content, onClose) {
    this.callMessage(content, onClose, 'info')
  },
  warn(content, onClose) {
    this.callMessage(content, onClose, 'warn')
  },
  warning(content, onClose) {
    this.callMessage(content, onClose, 'warning')
  },
  loading(content, onClose) {
    this.callMessage(content, onClose, 'loading')
  },
}
