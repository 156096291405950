/** postcss-pxtorem `rootValue` */
const ROOT_BASE_VALUE = 192

/**
 * Convert design size to real machine size
 * @param value design size
 */
export default function px2px(value) {
  const rootValue = parseFloat(document.documentElement.style.fontSize)
  return Math.floor((rootValue / ROOT_BASE_VALUE) * value)
}

/**
 * @param {number} value design size
 */
export function px2rem(value) {
  return value / ROOT_BASE_VALUE + 'rem'
}

/**
 * @param {number} value real machine size
 */
export function csspx2rem(value) {
  const rootValue = parseFloat(document.documentElement.style.fontSize)
  return value / rootValue + 'rem'
}
