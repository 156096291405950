import React, { useState, useEffect, useRef } from 'react'
import { Select, Spin } from 'antd'
import { useDebounceFn } from 'ahooks'
import axios from 'axios'
import { request } from '@/service/request'
import Arrow from 'ico/arrow.svg'
import './index.scss'

export const parse = value => {
  const [id, name] = value.split(' -- ')
  return { id, name }
}
export const stringify = ({ id, name }) => id + ' -- ' + name

const value2option = value => ({
  value,
  label: value.split(' -- ')[1],
})

const TargetSuggest = ({
  type,
  placeholder,
  value,
  onChange,
  from = 'meta',
  ...props
}) => {
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [fetching, setFetching] = useState(false)
  const cancel = useRef()
  const isLock = useRef(false)

  const { run: handleSearch } = useDebounceFn(
    async val => {
      cancel.current?.()
      if (data.length) setData([])
      if (!val || val.length < 3) return setFetching(false)
      setFetching(true)
      const { data: list } = await request({
        url:
          type === 'interest' ? 'search/interests' : `search/locations/${from}`,
        params: {
          // type,
          keyword: val,
          // platform: from,
        },
        cancelToken: new axios.CancelToken(c => (cancel.current = c)),
      })
      setFetching(false)

      if (list?.length) {
        setData(list)
        setOpen(true)
      }
    },
    { wait: 300 }
  )

  useEffect(() => {
    if (isLock.current) return (isLock.current = false)
    if (value?.length) {
      setData(value)
      setTimeout(() => setData([]))
    }
  }, [value])

  return (
    <div className="c-target-suggest">
      <Select
        {...props}
        options={data}
        open={open || fetching}
        fieldNames={{ label: 'name', value: 'id' }}
        mode="multiple"
        placeholder={placeholder}
        value={value?.map(v => v.id)}
        defaultActiveFirstOption={false}
        dropdownStyle={{ zIndex: 1052 }}
        popupClassName="custom-select-dropdown"
        notFoundContent={null}
        showArrow={false}
        showSearch
        filterOption={false}
        onSearch={handleSearch}
        onChange={(val, option) => {
          isLock.current = true
          onChange(option)
          setOpen(false)
          setTimeout(() => setData([]))
        }}
        onDropdownVisibleChange={setOpen}
      />
      {fetching && <Spin />}
      {/* {!fetching && <Arrow className="arrow" />} */}
    </div>
  )
}

export default TargetSuggest
