import { useState, useMemo, useRef, useEffect } from 'react'
import { Spin, Select, Modal, Button } from 'antd'
import moment from 'moment'
import { useRequest, useSetState, useReactive, useUpdateEffect } from 'ahooks'
import CreationModal from '@/components/v3-create'
import Currency from '@/components/m-currency'
import Overview from './components/Overview2'
import CampaignsTable from './components/CampaignsTable'
// import Settings from './components/Settings'
import { useTrackState } from '@/hooks'
import * as api from '@/service'
import { isEmpty, chunk, cloneDeep, merge, isEqual } from 'lodash'
import { useSubscribe, publish } from '@/subscribe'
import axios from 'axios'

import RangePicker from './components/RangePicker'
import { useSelector, useDispatch } from 'react-redux'

import cls from 'classnames'
import s from './index.module.scss'
import Google from 'ico/dashboard/google.svg'
import Meta from 'ico/meta.svg'
import spin from '@/assets/cats/chat-loading2.gif'

const MC = {
  Settings: 1,
}

const union = (list, chunk) =>
  list.map(item => {
    const idx = chunk.indexOf(item)
    return idx > -1 ? chunk[idx] : item
  })

const isEqualById = (arr1, arr2) =>
  isEqual(
    arr1.map(v => v.id),
    arr2.map(v => v.id)
  )

export default function Campaigns() {
  const { meta, google, is_started_group, started_from } = useSelector(
    state => state.user.current
  )
  const isMetaConnected = !isEmpty(meta)
  const isGoogleConnected = !isEmpty(google)
  const [dateRange, setDateRange] = useState([
    moment(started_from || moment().subtract(1, 'y')),
    moment(),
  ])
  const [spinning, setSpinning] = useState(false)
  const [filterKey, setFilterKey] = useState()
  const [platform, setPlatform] = useState(() => {
    return isGoogleConnected ? 'google' : 'meta'
  })
  const state = useReactive({
    google: null,
    meta: null,
    list: [],
  })
  const pageLoading = !state[platform] || state[platform].loading
  const rawList = useRef([])
  const overview = state[platform]?.stages?.find(v => v.type === filterKey)
  const onPlatformChange = p => {
    setPlatform(p)
    if (!state[p].stages.some(v => v.type === filterKey)) {
      setFilterKey(state[p].stages[0].type)
    }
  }
  const cancel = useRef()

  const { runAsync: fetchStages } = useRequest(
    async platform => {
      const connected =
        platform === 'meta' ? isMetaConnected : isGoogleConnected
      if (!connected) return
      if (!state[platform]) {
        state[platform] = { loading: true }
      } else {
        state[platform].loading = true
      }
      try {
        const stages = await api.fetchStageList(platform)
        !filterKey && setFilterKey(stages[0].type)
        const items = await Promise.all(
          stages.map(({ stage_id }) => api.fetchStageConfig(stage_id))
        )
        state[platform].stages = stages.map((stage, idx) => {
          const config = items[idx]
          config.event =
            platform === 'meta'
              ? config.platforms.meta.event
              : config.platforms.google.objective
          return { ...stage, ...config }
        })
      } catch {}
      state[platform].loading = false
    },
    { manual: true }
  )

  const { runAsync: fetchList } = useRequest(
    async (insights = false) => {
      setSpinning(true)
      const { data } = await api
        .fetchCampaignList()
        .finally(() => setSpinning(false))
      rawList.current = data
      if (insights || !isEqualById(data, state.list)) {
        state.list = data
        fetchInsights()
      } else {
        state.list = merge(state.list, data)
      }
      return data
    },
    { manual: true }
  )

  const { runAsync: fetchInsights } = useRequest(
    async () => {
      cancel.current?.()
      const list = cloneDeep(rawList.current)
      const groups = chunk(list, 50)
      list.forEach(v => (v.loading = true))
      state.list = list
      for (const group of groups) {
        const { data = [] } = await api
          .fetchCampaignInsights(
            group,
            dateRange,
            new axios.CancelToken(c => (cancel.current = c))
          )
          .catch(err => (axios.isCancel(err) ? Promise.reject(err) : {}))
        group.forEach((item, index) =>
          Object.assign(item, data[index], { loading: false })
        )
        state.list = union(list, group)
      }
    },
    { manual: true }
  )

  useEffect(() => {
    fetchStages('google')
    fetchStages('meta')
    fetchList(true)
  }, [])

  useUpdateEffect(() => void fetchInsights(), [dateRange])
  useSubscribe(({ type }) => {
    if (type !== 'recommendations_fetching') {
      fetchStages(platform)
      fetchList(true)
    }
  })

  const tableData = useMemo(() => {
    return state.list.filter(
      item => item.platform === platform && item.stage_value === filterKey
    )
  }, [platform, filterKey, state.list])

  const [mc, setMc] = useState()
  const [create, setCreate] = useSetState({
    platform: '',
    mode: '',
    status: '',
    cid: '',
    copy: false,
    imported: false,
  })
  const openCreate = data => setCreate({ mode: 'create', ...data })
  const closeModal = () => {
    setCreate({ mode: '', platform: '', cid: '', copy: false, imported: false })
  }

  /* if (!overview?.stages)
    return (
      <div className={s.wrap}>
        <Spin className={s.spin} spinning size="large" />
      </div>
    ) */
  return (
    <>
      {pageLoading && <img className={s.spin} src={spin} />}
      <div className={cls(s.wrap, 'nice-scrollbar')}>
        <div className={s.content}>
          <Overview data={overview} onPlatformChange={onPlatformChange} />
          <div className={s.filters}>
            {(state[platform]?.stages || []).map(stage => (
              <span
                key={stage.type}
                className={cls(s.filter, {
                  [s.active]: stage.type === filterKey,
                })}
                onClick={() => setFilterKey(stage.type)}
              >
                {stage.name}
              </span>
            ))}
          </div>
          {is_started_group && (
            <div className={s.statusBar}>
              <EventsSelect
                stage_id={overview?.stage_id}
                platform={platform}
                value={overview?.event}
              />
              <RangePicker value={dateRange} onChange={setDateRange} />
            </div>
          )}

          <CampaignsTable
            loading={spinning && !pageLoading}
            disabled={overview?.status !== 1 && false}
            data={tableData}
            refresh={fetchList}
            openCreate={openCreate}
            setSpinning={setSpinning}
          />
        </div>

        <CreationModal
          {...create}
          open={!!create.mode}
          onCancel={closeModal}
          onOk={() => {
            closeModal()
            fetchInsights()
          }}
        />
      </div>
    </>
  )
}

const EventsSelect = ({ platform, stage_id, value = [] }) => {
  const { meta, google } = useSelector(state => state.user.current)
  const disabled = platform === 'meta' ? isEmpty(meta) : isEmpty(google)
  const [val, setVal] = useTrackState(value)

  const { data = [], loading } = useRequest(
    async () => {
      if (disabled || !platform) return
      const { data } = await api.fetchPlatformGoals(platform, {
        type: 'event',
      })
      return data
    },
    { refreshDeps: [platform, disabled] }
  )

  const name = platform === 'meta' ? 'pixel_event' : 'conversion_actions'
  const { run: submit, loading: submitting } = useRequest(
    async () => {
      await api.updateStageConfig({
        stage_id,
        [name]: val,
      })
      publish({ type: 'global_fetching' })
    },
    { manual: true }
  )

  const extraProps =
    platform === 'google'
      ? {
          mode: 'multiple',
          maxTagCount: 0,
          maxTagPlaceholder: omitted =>
            `${omitted.length} Goal${omitted.length > 1 ? 's' : ''} Selected`,
        }
      : {}

  const hasChange = useMemo(() => {
    if (platform === 'meta') return val !== value
    return !isEqual([...val].sort(), [...value].sort())
  }, [val, value, platform])

  return (
    <div className={s.eventsSelect}>
      <span className={s.addonBefore}>
        {platform === 'meta' ? <Meta /> : <Google />}
      </span>
      <Spin spinning={loading}>
        <Select
          popupClassName={cls('custom-select-dropdown', s.selectDropdown)}
          disabled={disabled}
          value={val}
          onChange={setVal}
          dropdownMatchSelectWidth={false}
          {...extraProps}
        >
          {data.map(item => (
            <Select.Option key={item.value} disabled={item.disabled}>
              <span>{item.label}</span>
              {!!item.num && (
                <span style={{ marginLeft: 4, color: '#bababa' }}>
                  ({item.num} in past {item.past_days} days)
                </span>
              )}
            </Select.Option>
          ))}
        </Select>
      </Spin>
      {hasChange && (
        <div className={s.addonAfter}>
          <Button onClick={() => setVal(value)}>Cancel</Button>
          <Button
            type="primary"
            disabled={!val?.length}
            loading={submitting}
            onClick={submit}
          >
            Save Changes
          </Button>
        </div>
      )}
    </div>
  )
}
