import { useState, useRef, useEffect, useMemo } from 'react'
import {
  Table,
  Switch,
  Button,
  Spin,
  Dropdown,
  InputNumber,
  Modal,
  Tooltip,
  Skeleton,
} from 'antd'
import { useRequest, useEventListener, useBoolean } from 'ahooks'
import { useSelector } from 'react-redux'
import { arrayMoveImmutable } from 'array-move'
import Currency from '@/components/m-currency'
import Empty from '@/components/empty'
import cls from 'classnames'
import { capitalize } from 'lodash'
import * as api from '@/service/campaign'
import { confirmRecommendation } from '@/service/chat'
import AsyncTask from '@/utils/async-task'
import Message from '@/utils/message'
import { on } from '@/utils/dom'
import px2px, { px2rem } from '@/utils/px2px'
import { stages } from '@/utils/constant'
import { publish } from '@/subscribe'

import s from './index.module.scss'
// import ps from '../../index.module.scss'
import Lamp from 'ico/v3-create/lamp.svg'
import Rocket from 'ico/v3-create/rocket.svg'
import Warn from 'ico/v3-create/warn.svg'
import Variant from 'ico/v3-campaigns/variant.svg'
import Lock from 'ico/v3-campaigns/lock.svg'
import Auto from 'ico/v3-campaigns/a-auto.svg'
import Manual from 'ico/v3-campaigns/a-manual.svg'
import Copy from 'ico/v3-campaigns/copy.svg'
// import Edit from 'ico/v3-campaigns/edit.svg'
import edit from '@/assets/icons/edit.png'
// import Replace from 'ico/v3-campaigns/replace.svg'
// import Trashcan from 'ico/v3-campaigns/trashcan.svg'
// import Up from 'ico/v3-campaigns/up.svg'
// import Down from 'ico/v3-campaigns/down.svg'
import Sync from 'ico/v3-campaigns/sync.svg'
import Arrow from 'ico/v3-campaigns/arrow.svg'
import Meta from 'ico/meta.svg'
import Google from 'ico/dashboard/google.svg'
import Ellipsis from 'ico/v3-campaigns/ellipsis.svg'
import AOff from 'ico/v3-campaigns/a-off.svg'
import AOn from 'ico/v3-campaigns/a-on.svg'
import ARun from 'ico/v3-campaigns/a-run.svg'
import ArrowUp from 'ico/v3-campaigns/arrow-up.svg'
import ArrowDown from 'ico/v3-campaigns/arrow-down2.svg'
import CircleArrow from 'ico/v3-campaigns/circle-arrow.svg'
import On from 'ico/v3-campaigns/on.svg'
import Pause from 'ico/v3-campaigns/pause.svg'
import Launch from 'ico/v3-campaigns/launch.svg'
import assistant from '@/assets/images/assistant.png'
import unassign from '@/assets/images/unassign.png'

const lowerCase = str => (str || '').toLowerCase()

const MC = {
  Budget: 1,
  Buyer: 2,
}

export default function CampaignsTable({
  className,
  status,
  disabled,
  data,
  showHeader = true,
  refresh = () => {},
  openCreate = () => {},
  setSpinning = () => {},
  loading,
}) {
  const {
    currency_symbol,
    is_started_group,
    min_google_budget,
    min_meta_budget,
  } = useSelector(state => state.user.current)
  const [editId, setEditId] = useState()
  const editValue = useRef()
  const editTimer = useRef()
  const editRef = useRef()
  const [modalId, setModalId] = useState()
  const task = useRef()
  const [mc, setMC] = useState()
  const manual = true

  const columns = [
    {
      ellipsis: true,
      width: px2rem(230),
      key: 'theme',
      title: 'Campaign',
      render: (row, _, index) => (
        <>
          {row.loading && <Spin className={s.themeMove} size="small" />}
          <div className={s.theme}>
            {row.platform === 'google' ? <Google /> : <Meta />}
            <Tooltip
              title={row.name}
              placement="topLeft"
              overlayClassName={s.tooltip}
              color="#ECFBF4"
              destroyTooltipOnHide
            >
              <p
                className={cls(s.themeName, 'ellipsis-2')}
                onClick={() =>
                  !row.is_imported &&
                  openCreate({
                    cid: row.id,
                    status: row.status,
                    platform: row.platform,
                  })
                }
              >
                {row.name}
              </p>
            </Tooltip>
          </div>
          {row.is_imported ? (
            <p className={cls(s.themeTag, s.synced)}>
              <Sync />
              <span>Synced</span>
            </p>
          ) : (
            <p
              className={cls(
                s.themeTag,
                row.campaign_type === 'New Variant' && s.variant
              )}
            >
              {row.campaign_type === 'New Variant' ? <Variant /> : <Lamp />}
              <strong>{row.campaign_type}</strong>
              {!!row.campaign_type_content && (
                <>
                  <i className={s.divider} />
                  <Rocket className="fill-color-strict" />
                  <span>{row.campaign_type_content}</span>
                </>
              )}
            </p>
          )}
          <Tooltip
            title={
              row.phase === 'error'
                ? 'ERROR'
                : row.status === 'ACTIVE'
                ? 'ACTIVE'
                : 'PAUSED'
            }
            placement="top"
            overlayClassName={s.tooltip}
            overlayInnerStyle={{
              color:
                row.phase === 'error'
                  ? '#FF6161'
                  : row.status === 'ACTIVE'
                  ? '#3DD994'
                  : '#B6B6B6',
            }}
            color="#ECFBF4"
          >
            <span
              className={cls(
                s.themeStatus,
                row.phase === 'error' ? s.error : s.dot,
                row.status !== 'ACTIVE' && s.inactive
              )}
            />
          </Tooltip>
        </>
      ),
    },
    ['running', 'waiting', 'unassigned'].includes(status) || true
      ? {
          width: px2rem(status === 'unassigned' ? 100 : 130),
          key: 'budget',
          title: 'Budget',
          render: record => (
            <>
              {editId === record.id ? (
                <div className={s.budgetConfirm}>
                  <InputNumber
                    ref={editRef}
                    className={cls(s.budgetInput, manual && s.budgetManual)}
                    autoFocus
                    controls={false}
                    formatter={value => value && currency_symbol + value}
                    parser={value => value.replace(currency_symbol, '')}
                    defaultValue={record.budget}
                    onFocus={() => (editValue.current = record.budget)}
                    onBlur={() =>
                      (editTimer.current = setTimeout(() => setEditId(), 300))
                    }
                    onChange={value => (editValue.current = value)}
                    onPressEnter={() => onEditConfirm(record)}
                  />
                  <Button type="primary" onClick={() => onEditConfirm(record)}>
                    Confirm
                  </Button>
                </div>
              ) : record.budget_updatable ? (
                <BudgetDropdown
                  manual={manual}
                  record={record}
                  onMenuItemClick={onEditBudget}
                />
              ) : (
                <strong
                  className={cls(
                    s.budget,
                    record.phase === 'error' ? s.danger : s.disabled
                  )}
                >
                  {isNaN(record.budget) ? (
                    '--'
                  ) : (
                    <Currency value={record.budget} />
                  )}
                </strong>
              )}
              <Text content={record.budget_value} />
            </>
          ),
        }
      : {
          width: px2rem(130),
          key: 'last_edit',
          title: 'Last Edit',
          render: ({ last_edit, last_edit_content }) => (
            <>
              <strong>{last_edit}</strong>
              <Text content={last_edit_content} />
            </>
          ),
        },
    !['draft'].includes(status)
      ? {
          width: px2rem(100),
          key: 'spend',
          title: 'Amount Spent',
          render: ({ spend, spend_value }) => (
            <>
              <strong>
                {isNaN(spend) ? spend : <Currency value={spend} />}
              </strong>
              <Text content={spend_value} />
            </>
          ),
        }
      : {
          width: px2rem(100),
          key: 'created',
          title: 'Day Created',
          render: ({ day_created, day_created_content }) => (
            <>
              <strong>{day_created}</strong>
              <Text content={day_created_content} />
            </>
          ),
        },
    status === 'unassigned' && {
      width: px2rem(100),
      key: 'objective',
      title: 'Objective',
      render: ({ objective }) => <strong>{objective}</strong>,
    },
    status !== 'draft' && {
      width: px2rem(100),
      key: 'results',
      title: 'Results',
      render: ({ result, result_value }) => (
        <>
          <strong>{result}</strong>
          <Text content={result_value} />
        </>
      ),
    },
    status !== 'draft' && {
      width: px2rem(100),
      key: 'cpr',
      title: 'CPR',
      render: ({ cpr, cpr_value }) => (
        <>
          <strong>{isNaN(cpr) ? cpr : <Currency value={cpr} />}</strong>
          <Text content={cpr_value} />
        </>
      ),
    },
    // status !== 'unassigned' && {
    //   width: px2rem(100),
    //   key: 'stage',
    //   title: 'Stage',
    //   render: ({ stage }) => <strong>{stage}</strong>,
    // },
    status !== 'unassigned' && {
      width: px2rem(100),
      key: 'phase',
      title: 'Phase',
      render: (record, _, index) =>
        getStage(record) ? (
          <>
            <div
              className={cls(s.stage, s[record.phase])}
              style={
                record.phase_progress !== void 0
                  ? { '--progress': `${record.phase_progress * 100}%` }
                  : {}
              }
            >
              <span className="pr">{capitalize(getStage(record))}</span>
            </div>
            <Text content={record.phase_value} />
          </>
        ) : null,
    },
    status !== 'unassigned'
      ? {
          width: px2rem(110),
          key: 'action',
          title: 'Autopilot Updates',
          render: row =>
            row.error_message ? (
              <>
                <Warn className={s.warn} />
                <div className={s.alert}>
                  <p>{row.error_message}</p>
                </div>
              </>
            ) : false && editId === row.id ? (
              <div className={cls(s.budgetConfirm, s.tx)}>
                <InputNumber
                  ref={editRef}
                  className={cls(s.budgetInput, s.budgetManual)}
                  autoFocus
                  controls={false}
                  formatter={value => value && currency_symbol + value}
                  parser={value => value.replace(currency_symbol, '')}
                  onFocus={() => (editValue.current = null)}
                  onBlur={() =>
                    (editTimer.current = setTimeout(() => setEditId(), 300))
                  }
                  onChange={value => (editValue.current = value)}
                  onPressEnter={() => onEditConfirm(row)}
                />
                <Button type="primary" onClick={() => onEditConfirm(row)}>
                  Confirm
                </Button>
              </div>
            ) : is_started_group ? (
              <>
                <div className={s.action}>
                  <Dropdown
                    overlayClassName={s.actionDropdown}
                    placement="bottomCenter"
                    menu={{
                      items: getActions(row),
                      onClick: ({ key }) => onAction(key, row),
                    }}
                  >
                    <span className={s.ellipsis}>
                      <Ellipsis />
                    </span>
                  </Dropdown>
                  {row.recommendated && (
                    <div
                      className={s.assistant}
                      onClick={() => {
                        setMC(MC.Buyer)
                        setModalId(row.id)
                      }}
                    >
                      <img src={assistant} alt="assistant" />
                    </div>
                  )}
                </div>
                {row.status === 'ACTIVE' && row.reason && (
                  <Text content={row.reason} />
                )}
              </>
            ) : null,
        }
      : {
          width: px2rem(100),
          key: 'action',
          title: 'Assign',
          render: record =>
            true ? (
              <AssignDropdown
                onMenuItemClick={key => assignCampaign(record, key)}
              />
            ) : (
              <div className={s.dangerBtn}>Can’t Assign</div>
            ),
        },
  ].filter(Boolean)

  const getStage = ({ phase }) => {
    return (phase === 'error' ? 'attention!' : phase).toLowerCase()
  }

  const getActions = row => {
    const menus = [
      {
        key: 'onoff',
        label: row.status === 'ACTIVE' ? 'Turn Off' : 'Turn On',
        icon: row.status === 'ACTIVE' ? <AOff /> : <AOn />,
      },
      !row.is_imported && {
        key: 'edit',
        label: 'Edit',
        icon: <img src={edit} />,
      },
      {
        key: 'copy',
        label: 'Copy',
        icon: <Copy />,
      },
      {
        key: 'unassign',
        label: 'Unassign',
        icon: <img src={unassign} />,
      },
    ]
    return menus.filter(Boolean)
  }

  const onAction = (action, row) => {
    switch (action) {
      case 'edit':
      case 'copy':
        openCreate({
          cid: row.id,
          status: row.status,
          platform: row.platform || 'meta',
          copy: action === 'copy',
        })
        break
      // case 'delete':
      //   deleteDraft(row.id)
      //   break
      // case 'run':
      //   runNow(row.id)
      //   break
      case 'onoff':
        const needBudget = row.phase === 'losing' && false
        if (row.status === 'PAUSED' && needBudget) {
          setEditId(row.id)
        } else {
          activeOrPause(row.id, row.status === 'PAUSED' ? 'ON' : 'OFF')
        }
        break
      case 'unassign':
        unassignCampaign(row.id)
        break
    }
  }

  const onEditBudget = async ({ key, id }) => {
    if (key === 'custom') {
      setEditId(id)
    }
  }

  const onEditConfirm = row => {
    const id = editId
    const budget = editValue.current
    const minimum_budget =
      row.platform === 'meta' ? min_meta_budget : min_google_budget
    clearTimeout(editTimer.current)
    editRef.current.focus()
    if (budget < minimum_budget) {
      return Message.warn(
        `The minimum budget for ${row.platform} is ` +
          currency_symbol +
          minimum_budget
      )
    }
    updateCampaignBudget(id, budget)
    /* switch (row.status) {
      case 'ACTIVE':
        updateCampaignBudget(id, budget)
        break
      case 'PAUSED':
        activeOrPause(id, 'ON', budget)
        break
    } */
  }

  const updateCampaignBudget = (id, budget) => {
    setSpinning(true)
    api
      .updateCampaignBudget({ id, budget })
      .then(() => refresh())
      .then(() => setEditId())
      .finally(() => setSpinning(false))
  }

  const runNow = async id => {
    let budget
    if (manual || true) {
      setEditId(id)
      task.current = new AsyncTask()
      budget = await task.current.promise
    }
    setSpinning(true)
    api
      .updateCampaignStatus({ action: 'run_now', id, budget })
      .then(refresh)
      .then(() => setEditId())
      .finally(() => setSpinning(false))
  }

  const activeOrPause = async (id, status, budget) => {
    setSpinning(true)
    api
      .updateCampaignStatus({ id, status, budget })
      .then(() => refresh())
      .finally(() => {
        setSpinning(false)
        setEditId()
      })
  }

  const [deleteId, setDeleteId] = useState('')
  const deleteDraft = async id => {
    setDeleteId(id)
    await api.deleteDraft(id).finally(() => setDeleteId(''))
    refresh(true)
  }

  const reorder = async (fromIndex, toIndex) => {
    const newData = arrayMoveImmutable(data, fromIndex, toIndex)
    setSpinning(true)
    await api
      .reorderCampaign(newData.map(v => v.id))
      .catch(() => setSpinning(false))
    refresh()
  }

  const assignCampaign = async (row, stage) => {
    setSpinning(true)
    api
      .assignCampaign({ id: row.id, stage })
      .then(() => publish({ type: 'global_fetching' }))
      .finally(() => setSpinning(false))
  }

  const unassignCampaign = id => {
    setSpinning(true)
    api
      .unassignCampaign({ id })
      .then(() => publish({ type: 'global_fetching' }))
      .finally(() => setSpinning(false))
  }

  const getLocale = () => {
    if (status !== 'unassigned') {
      return {
        emptyText: (
          <Empty
            className="global-empty"
            text={
              !is_started_group
                ? 'Please finish group setup first'
                : !disabled
                ? 'Please create campaign first'
                : 'Turn back on Optimization to resume campaigns'
            }
          />
        ),
      }
    }
  }

  return (
    <>
      <Table
        className={cls(s.table, status && s[status], className)}
        rowClassName={({ error_message }) => error_message && s.oops}
        showHeader={showHeader}
        columns={columns}
        dataSource={data}
        bordered={false}
        pagination={false}
        loading={loading}
        scroll={{ y: 500 }}
        locale={getLocale()}
      />

      <Modal
        className="custom-modal"
        open={mc !== void 0}
        centered
        closable={false}
        footer={null}
        onCancel={() => setMC()}
        destroyOnClose
      >
        {mc === MC.Buyer && (
          <MediaBuyer
            campaignId={modalId}
            onCancel={() => setMC()}
            onOk={() => {
              setMC()
              publish({ type: 'global_fetching' })
            }}
          />
        )}
      </Modal>
    </>
  )
}

const BudgetDropdown = ({ manual, record, disabled, onMenuItemClick }) => {
  const { id, budget, fixed_budget } = record
  const [open, setOpen] = useState(false)
  const triggerEl = useRef()
  const timer = useRef()
  const cleanup = useRef()

  const Icon = manual ? Manual : Auto

  const close = () => {
    timer.current = setTimeout(() => setOpen(false), 200)
  }
  const cancelClose = () => clearTimeout(timer.current)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const target = document.querySelector(`.${s.dropdown}`)
        const stops = [
          on(target, 'mouseleave', close),
          on(target, 'mouseenter', cancelClose),
        ]
        cleanup.current = () => stops.forEach(stop => stop())
      })
      return () => cleanup.current?.()
    }
  }, [open])

  useEventListener('mouseleave', close, {
    target: triggerEl,
  })
  useEventListener('mouseenter', cancelClose, {
    target: triggerEl,
  })

  const getMenuItems = fixed => {
    if (manual) {
      return []
    }
    const items = []
    if (fixed) {
      items.push(
        {
          key: 'auto',
          label: (
            <div className={s.menuItem}>
              AI Auto
              <Icon className={cls(s.auto)} />
            </div>
          ),
        },
        {
          key: 'custom',
          label: (
            <div className={s.menuItem} style={{ color: '#3a574e' }}>
              Update Budget
              <Lock className={cls(s.lock)} />
            </div>
          ),
        }
      )
    } else {
      items.push({
        key: 'custom',
        label: (
          <div className={s.menuItem} style={{ color: '#B6B6B6' }}>
            Fixed
            <Lock className={cls(s.lock)} />
          </div>
        ),
      })
    }
    return items
  }

  return (
    <Dropdown
      overlayClassName={s.dropdown}
      trigger={['click']}
      open={!manual && open}
      menu={{
        items: getMenuItems(!!fixed_budget),
        onClick: ({ key }) => {
          setOpen(false)
          onMenuItemClick({ key, id })
        },
      }}
      disabled={disabled}
      onOpenChange={setOpen}
      destroyPopupOnHide
    >
      <div
        ref={triggerEl}
        className={cls(
          s.budget,
          record.fixed_budget ? s.fixed : manual ? s.manual : null,
          disabled && s.disabled
        )}
        onClick={() => manual && onMenuItemClick({ key: 'custom', id })}
      >
        <Currency className={s.amount} value={fixed_budget || budget} />
        <span className={cls(s.status)}>
          {fixed_budget ? (
            <Lock className={cls(s.lock)} />
          ) : (
            <Icon className={cls(s.auto)} />
          )}
        </span>
        {!manual && !disabled && (
          <Arrow className={cls(s.arrow, 'display-on-hover')} />
        )}
      </div>
    </Dropdown>
  )
}

const AssignDropdown = ({ onMenuItemClick }) => {
  const [open, setOpen] = useState(false)
  const triggerEl = useRef()
  const timer = useRef()
  const cleanup = useRef()

  const close = () => {
    timer.current = setTimeout(() => setOpen(false), 200)
  }
  const cancelClose = () => clearTimeout(timer.current)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const target = document.querySelector(`.${s.dropdown}`)
        const stops = [
          on(target, 'mouseleave', close),
          on(target, 'mouseenter', cancelClose),
        ]
        cleanup.current = () => stops.forEach(stop => stop())
      })
      return () => cleanup.current?.()
    }
  }, [open])

  useEventListener('mouseleave', close, {
    target: triggerEl,
  })
  useEventListener('mouseenter', cancelClose, {
    target: triggerEl,
  })

  const [menuItems] = useState(() =>
    stages.map(v => ({ key: v.value, label: v.label }))
  )

  return (
    <Dropdown
      overlayClassName={cls(s.dropdown, s.assignDropdown)}
      overlayStyle={{ zIndex: 3000 }}
      trigger={['click']}
      open={open}
      menu={{
        items: menuItems,
        onClick: ({ key }) => {
          setOpen(false)
          onMenuItemClick(key)
        },
      }}
      onOpenChange={setOpen}
      destroyPopupOnHide
    >
      <div ref={triggerEl} className={s.assign}>
        Assign
        <Arrow />
      </div>
    </Dropdown>
  )
}

const Text = ({ content, className, style }) => (
  <p className={cls(s.text, className)} style={style}>
    <Tooltip
      title={content}
      placement="top"
      overlayClassName={s.tooltip}
      destroyTooltipOnHide
      color="#ECFBF4"
    >
      <span>{content}</span>
    </Tooltip>
  </p>
)

const MediaBuyer = ({ campaignId, onCancel, onOk }) => {
  const symbol = useSelector(state => state.user.current.currency_symbol)
  const [open, { toggle }] = useBoolean(false)
  const { data, loading } = useRequest(
    async () => (await api.fetchAction(campaignId)).data[0]
  )

  const { run, loading: confirmLoading } = useRequest(
    async () => {
      await confirmRecommendation(data.recommendation_id)
      onOk()
    },
    { manual: true }
  )

  const increase = data?.new_budget >= data?.old_budget
  const config = (() => {
    if (!data) return
    const [curr, prev] = [data.new_budget, data.old_budget]
    switch (data.action) {
      case 'update':
        return {
          status: 'Scale',
          summary: `${increase ? 'Increase' : 'Decrease'} budget from ${
            symbol + prev
          } to ${symbol + curr}`,
          icon: increase ? (
            <ArrowUp className={s.sm} />
          ) : (
            <ArrowDown className={s.sm} />
          ),
          danger: !increase,
        }
      case 'delete':
        return {
          status: 'Pause',
          summary: `Pause campaign with budget ${symbol + prev}`,
          icon: <Pause />,
          danger: true,
        }
      case 'create':
        return {
          status: 'Launch',
          summary: `Launch campaign with budget ${symbol + curr}`,
          icon: <Launch />,
          danger: false,
        }
      case 'revive':
        return {
          status: 'Revive',
          summary: `Revive campaign with budget ${symbol + curr}`,
          icon: <On />,
          danger: false,
        }
    }
  })()

  return (
    <div className={cls(s.buyer, open && s.open)}>
      <Skeleton loading={loading} active>
        <div className={s.title}>Media Buyer</div>
        {data && (
          <div className={s.content}>
            <div className="flex-ac">
              <div className={s.left}>
                <div className={s.message}>
                  <span className={cls(s.status, config.danger ? s.bad : s.ok)}>
                    {config.status}
                    {config.icon}
                  </span>
                  {data.name}
                  {data.platform === 'google' ? (
                    <Google className={s.platform} />
                  ) : (
                    <Meta className={s.platform} />
                  )}
                </div>
                <div className={s.data}>
                  {config.summary}
                  <CircleArrow onClick={toggle} />
                </div>
              </div>
              <div className={s.right}>
                <Button
                  type="primary"
                  disabled={data.confirmed}
                  loading={confirmLoading}
                  onClick={run}
                >
                  Confirm
                </Button>
                {/* <Button type="text" onClick={onCancel}>
                  Skipped
                </Button> */}
              </div>
            </div>
            <div className={s.bottom}>
              <div className={s.desc}>{data.description}</div>
            </div>
          </div>
        )}
      </Skeleton>
    </div>
  )
}
