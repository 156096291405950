import { produce } from 'immer'
import isPlainObject from 'lodash/isPlainObject'
import {
  UPDATE_USER,
  MODIFY_USER,
  UPDATE_BUDGET,
  TOGGLE_IN_USE,
  TOGGLE_IN_STRIPE,
  UPDATE_STRIPE,
  UPDATE_STRIPE_ID,
  TOGGLE_OPEN_BUDGET,
  TOGGLE_OPEN_SERVICE_OFF_MODAL,
  TOGGLE_TMP_IN_USE,
  TOGGLE_CREATE_MODAL,
  UPDATE_GROUPS,
  UPDATE_GROUP_NAME,
  VISIBLE_CREATE_CHAT,
} from '../actions/user.actions'

const userInit = {
  current: {},
  shared: {
    visibleChat: true,
    campaignGroups: [],
  },
}

const userReudcer = produce((draft, action) => {
  switch (action.type) {
    case MODIFY_USER:
      Object.assign(draft.current, action.data)
      break
    case UPDATE_USER:
      draft.current = action.data
      break
    case VISIBLE_CREATE_CHAT:
      draft.shared.visibleChat = action.data
      break
    case UPDATE_BUDGET:
      draft.current.budget = action.data
      break
    case TOGGLE_IN_USE:
      draft.current.in_use = action.data
      break
    case TOGGLE_IN_STRIPE:
      draft.shared.showStripe = action.data
      break
    case UPDATE_STRIPE:
      draft.current.billing.product_list = action.data
      break
    case UPDATE_STRIPE_ID:
      draft.current.billing.product_id = action.data
      break
    case TOGGLE_OPEN_BUDGET:
      draft.shared.openBudget = action.data.open ?? !draft.shared.openBudget
      draft.shared.tmp_budget_type = action.data.tmpType
      break
    case TOGGLE_OPEN_SERVICE_OFF_MODAL:
      draft.shared.openServiceOffModal =
        action.data ?? !draft.shared.openServiceOffModal
      break
    case TOGGLE_TMP_IN_USE:
      draft.shared.tmp_in_use = action.data
      break
    case TOGGLE_CREATE_MODAL:
      const open =
        action.data === void 0 ? !draft.shared.openCreateModal : !!action.data
      const options = isPlainObject(action.data) ? action.data : undefined
      draft.shared.openCreateModal = open
      draft.shared.createModalOptions = options
      break
    case UPDATE_GROUPS:
      draft.shared.campaignGroups = action.data
      break
    case UPDATE_GROUP_NAME:
      const { id, name } = action.data
      const group = draft.shared.campaignGroups.find(g => g.id === id)
      if (group) group.name = name
      break

    default:
  }
}, userInit)

export default userReudcer
