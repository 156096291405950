import { request } from './request'
import moment, { isMoment } from 'moment'
import { cloneDeep, omit } from 'lodash'

const transformRequest = data => {
  data = cloneDeep(data)
  if (data.schedule) {
    const { startDate, endDate } = data.schedule
    data.schedule = {
      startDate: isMoment(startDate)
        ? startDate.format('YYYY-MM-DD')
        : startDate || '',
      endDate: isMoment(endDate) ? endDate.format('YYYY-MM-DD') : endDate || '',
    }
  }
  if (data.creative?.media?.length) {
    data.creative.media = data.creative.media.reduce((value, item) => {
      return item.errno ? value : value.concat(omit(item, ['errno']))
    }, [])
  }

  return data
}

const transformResponse = res => {
  const { schedule, audience, location, interest, fixed_budget } = res.data
  if (schedule) {
    const { startDate, endDate } = schedule
    schedule.startDate = startDate ? moment(startDate) : undefined
    schedule.endDate = endDate ? moment(endDate) : undefined
  }
  Array.isArray(audience) && (res.data.audience = '') // dirty data
  fixed_budget === 0 && delete res.data.fixed_budget

  return res
}

export function fetchCampaignDetail(params) {
  return request({
    url: 'campaign/fetch',
    params,
  }).then(transformResponse)
}

export function submitCampaign(data) {
  return request({
    method: 'POST',
    url: 'campaign/submit?platform=meta',
    data: transformRequest(data),
    cancelable: true,
  })
}

export function submitGoogleCampaign(data) {
  return request({
    method: 'POST',
    url: 'campaign/submit?platform=google',
    data: transformRequest(data),
    cancelable: true,
  })
}

export function saveDraft(data) {
  return request({
    method: 'POST',
    url: 'campaign/save?platform=meta',
    data: transformRequest(data),
    cancelable: true,
  })
}

export function saveGoogleDraft(data) {
  return request({
    method: 'POST',
    url: 'campaign/save?platform=google',
    data: transformRequest(data),
    cancelable: true,
  })
}

export const fetchCampaignList = params => {
  return request({
    url: 'campaign/list',
    params,
  })
}

export function fetchCampaignInsights(list, dateRange, cancelToken) {
  return request({
    method: 'post',
    url: 'campaign/insights',
    params: {
      from: dateRange?.[0].format('YYYY-MM-DD'),
      until: dateRange?.[1].format('YYYY-MM-DD'),
    },
    data: { ids: list.map(v => `${v.platform}_${v.id}`) },
    cancelToken,
  })
}

export function deleteDraft(id) {
  return request({
    url: '3/draft/delete',
    params: { id },
  })
}

export function replaceCampaign({ waiting_id, running_id }) {
  return request({
    method: 'POST',
    url: '3/campaign/replace',
    data: { waiting_id, running_id },
  })
}

export function reorderCampaign(queue) {
  return request({
    method: 'POST',
    url: '3/campaign/order',
    data: { queue },
  })
}

export function fetchCampaignGroupList() {
  return request({ url: 'group/list' })
}

export function updateCampaignGroupName(group_id, name) {
  return request({
    method: 'POST',
    url: 'group/name',
    data: {
      group_id,
      name,
    },
  })
}

export function fetchCampaignGroupDetail(params) {
  return request({
    url: 'campaign/list?flag=insight',
    params,
    cancelable: true,
  })
}

export function switchCampaingGroup(id) {
  return request({
    url: '3/campaign_group/switch',
    params: { id },
    cancelable: true,
  })
}

export function updateCampaignSetting(data) {
  return request({
    method: 'POST',
    url: '3/campaign_group/setting_update',
    data,
  })
}

export function updateGroupConfig(group_config) {
  return request({
    method: 'POST',
    url: '3/campaign_group/config',
    data: { group_config },
  })
}

export function assignCampaign(params) {
  return request({
    url: 'campaign/assign',
    params,
  })
}

export function unassignCampaign(params) {
  return request({
    url: 'campaign/unassign',
    params,
  })
}

export function updateCampaignBudget(params) {
  return request({
    url: 'campaign/budget',
    params,
  })
}

export function updateCampaignStatus(params) {
  return request({
    url: 'campaign/status',
    params,
  })
}

export function fetchPlatformGoals(platform, params) {
  return request({
    url: `platform/load?platform=${platform}`,
    params,
  })
}

export function onOffCampaign(id, action) {
  return request({
    url: 'campaign/status',
    params: { id, action },
  })
}

export function increaseBudget(budget) {
  return request({
    method: 'POST',
    url: '3/goal/confirm',
    data: { budget },
  })
}

export function canRunNow() {
  return request({ url: '3/campaign/is_run' })
}

export const fetchCampaignSearch = params => {
  return request({
    url: 'search',
    params,
    cancelable: true,
  })
}

export const fetchSyncedSubmit = data => {
  return request({
    url: '3/campaign/synced_submit',
    method: 'post',
    data,
  })
}

export const fetchSyncedList = params => {
  return request({
    url: '3/campaign/synced',
    params,
  })
}

export function fetchAction(campaign_id) {
  return request({
    url: 'recommendation/fetch',
    params: { campaign_id },
  })
}

export function deployAction(campaign_id) {
  return request({
    url: 'recommendation/confirm',
    params: { campaign_id },
  })
}

export function autoBudgetSwitch(params) {
  return request({
    url: '3/campaign/fixed_auto',
    params,
  })
}

export function manualBudgetSwitch(params) {
  return request({
    url: '3/campaign/fixed_manual',
    params,
  })
}

export function updateStageConfig(data) {
  return request({
    method: 'post',
    url: 'stage/config/save',
    data,
  })
}
