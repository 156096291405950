import { useRef, useEffect, useContext, useMemo, useState } from 'react'
import { useHistory, NavLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { Spin, Input, Tooltip, Modal } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import { useReactive, useRequest } from 'ahooks'
import {
  updateGroupName,
  updateGroups,
  createGroup,
  switchGroup,
} from '@/store/actions/user.actions'
import * as _ from '@/utils'
import { merge, chunk } from 'lodash'
import { CContext } from '@/context'
import Unassigned from '@/pages/v4-campaigns/components/Unassigned'
import useCountdown from '@/components/countdown/useCountdown'
import * as api from '@/service/campaign'
import axios from 'axios'
import { useSubscribe } from '@/subscribe'

import cls from 'classnames'
import s from './index.module.scss'
import assistant from '@/assets/images/assistant.png'
import Add from 'ico/v3-campaigns/add.svg'
import Edit from 'ico/v3-campaigns/edit2.svg'
import Folder from 'ico/folder.svg'
import Dataset from 'ico/dataset.svg'
import On from 'ico/v3-campaigns/on.svg'
import Pause from 'ico/v3-campaigns/pause.svg'
import Logo from 'ico/logo4.svg'

const MC = {
  Unassigned: 0,
}

const union = (list, chunk) =>
  list.map(item => {
    const idx = chunk.indexOf(item)
    return idx > -1 ? chunk[idx] : item
  })

export default function Header() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    current: {
      current_campaign_group_id: group_id,
      campaigns_summary,
      name,
      username,
      is_started,
      is_started_group,
      is_chat_unread,
      is_data_unread,
      token,
    },
    shared: { campaignGroups: groups },
  } = useSelector(state => state.user)
  const reactive = useReactive({
    groups: [...groups],
    createGroupLoading: false,
    switchGroupId: -1,
  })
  const groupsRef = useRef(groups)
  const [mc, setMc] = useState()

  if (groupsRef.current !== groups) {
    reactive.groups = merge([], reactive.groups, groups)
    groupsRef.current = groups
  }
  // useEffect(() => {
  //   _.getToken() && is_started && dispatch(updateGroups())
  // }, [])

  const cancel = useRef()
  const [unassigned, setUnassigned] = useState([])
  const [loading, setLoading] = useState(false)
  const { runAsync: refreshData } = useRequest(
    async () => {
      if (!is_started) return
      setLoading(true)
      const { data: list } = await api
        .fetchCampaignList({ unassigned: true })
        .finally(() => setLoading(false))
      const groups = chunk(list, 50)
      list.forEach(v => (v.loading = true))
      setUnassigned(list)
      for (const group of groups) {
        const { data = [] } = await api
          .fetchCampaignInsights(
            group,
            null,
            new axios.CancelToken(c => (cancel.current = c))
          )
          .catch(err => (axios.isCancel(err) ? Promise.reject(err) : {}))
        group.forEach((item, index) =>
          Object.assign(item, data[index], { loading: false })
        )
        setUnassigned(union(list, group))
      }
    },
    { refreshDeps: [is_started] }
  )

  useSubscribe(({ type }) => {
    if (type !== 'recommendations_fetching') refreshData()
  })

  const handleUpdateGroupName = async (group, name) => {
    if (!name) return
    if (name === group.name) {
      group.isEdit = false
      return
    }
    group.loading = true
    try {
      await dispatch(updateGroupName(group.id, name))
      group.loading = false
      group.isEdit = false
    } catch {
      group.loading = false
    }
  }

  const beforeChange = async () => {
    const hooks = window.__BEFORE_GROUP_CHANGE_HOOKS__ || []
    await Promise.all(hooks.map(hook => hook()))
  }

  const handleCreateGroup = async () => {
    if (reactive.switchGroupId !== -1) return
    await beforeChange()
    reactive.createGroupLoading = true
    await dispatch(createGroup()).finally(
      () => (reactive.createGroupLoading = false)
    )
    handleChangePage('chat')
  }

  const handleSwitchGroup = async id => {
    if (id === group_id) {
      handleChangePage()
      return
    }
    await beforeChange()
    reactive.switchGroupId = id
    await dispatch(switchGroup(id)).finally(() => {
      if (reactive.switchGroupId === id) {
        reactive.switchGroupId = -1
      }
    })
    // handleChangePage()

    // delete this after
    if (location.pathname == '/campaigns') location.href = '/campaigns'
    else location.href = '/chat'
  }

  const handleChangePage = to_path => {
    location.pathname !== '/campaigns' && history.push('/campaigns')
    if (to_path === 'chat') {
      location.pathname !== '/chat' && history.push('/chat')
    }
  }

  return (
    <>
      <div className={s.wrap}>
        <div className={s.row}>
          <Logo />
          {/* <div className={s.logo} /> */}
          <div className={s.groups}>
            {reactive.groups.map((group, idx) => (
              <div
                key={group.id}
                className={cls(s.group, {
                  [s.active]: group.id == group_id,
                  [s.loading]: group.id === reactive.switchGroupId,
                })}
                style={group.isEdit ? { flexShrink: 0 } : null}
                onClick={() => handleSwitchGroup(group.id)}
              >
                <Folder />
                {!group.isEdit ? (
                  <>
                    <Tooltip title={group.name} placement="bottom">
                      <span className="ellipsis">{group.name}</span>
                    </Tooltip>
                    {!group.is_started && (
                      <WarningOutlined
                        className={s.warn}
                        style={{ color: '#fff100' }}
                      />
                    )}

                    <Edit
                      className={cls(s.edit, 'fill-color-strict')}
                      onClick={e => {
                        e.stopPropagation()
                        group.isEdit = true
                      }}
                    />
                  </>
                ) : (
                  <Spin spinning={group.loading === true}>
                    <Input
                      defaultValue={group.name}
                      bordered={false}
                      autoFocus
                      onPressEnter={e => e.target.blur()}
                      onBlur={e => handleUpdateGroupName(group, e.target.value)}
                      onClick={e => e.stopPropagation()}
                    />
                  </Spin>
                )}
              </div>
            ))}
          </div>
          {!!is_started && (
            <div className={s.addSpin}>
              <Spin spinning={reactive.createGroupLoading} size="small">
                <Add
                  className={cls(s.add)}
                  disabled={reactive.switchGroupId !== -1}
                  onClick={handleCreateGroup}
                />
              </Spin>
            </div>
          )}
          {(token || _.getToken()) && (
            <div className={s.user} onClick={() => history.push('/profile')}>
              {/* <img src={picture} /> */}
              <div>{username}</div>
              <span>{name}</span>
            </div>
          )}
        </div>

        {!!is_started && (
          <div className={s.row}>
            <div className={s.tabs}>
              <NavLink className={s.tab} activeClassName={s.active} to="/chat">
                <img className={s.tabIcon} src={assistant} />
                <span className={s.tabName}>AI AGENTS</span>
                {is_chat_unread && <em />}
              </NavLink>
              <NavLink
                className={s.tab}
                activeClassName={s.active}
                to="/campaigns"
              >
                <Dataset className={s.tabIcon} />
                <span className={s.tabName}>DATA</span>
                {is_data_unread && <em />}
              </NavLink>
            </div>
            {is_started_group && (
              <div className={s.unassigned}>
                {unassigned.length > 0 && (
                  <div className={s.right} onClick={() => setMc(MC.Unassigned)}>
                    Unassigned
                    <span className={s.badge}>{unassigned.length}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        className="custom-modal"
        open={mc !== void 0}
        closable={false}
        footer={null}
        centered
        destroyOnClose={false}
        onCancel={() => setMc()}
      >
        {mc === MC.Unassigned && (
          <Unassigned
            data={unassigned}
            refresh={refreshData}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Modal>
    </>
  )
}

const Optimizing = () => {
  const { is_pending, is_started_group } = useSelector(
    state => state.user.current
  )
  const { data, refreshData } = useContext(CContext)
  // const [inUse, setInUse] = useTrackState(data?.overview.in_use)
  const inUse = data?.overview.in_use ?? true

  const toggleInUse = () => {
    if (is_pending) return
    const value = !inUse
    const update = () => {
      // setInUse(value)
      return api
        .updateCampaignSetting({
          action: 'in_use',
          is_on: +value,
        })
        .then(refreshData /* , () => setInUse(!value) */)
    }

    Modal.confirm({
      className: cls(s.modal2, value ? s.modal2success : s.modal2danger),
      icon: null,
      title: 'Please confirm',
      content: (
        <div className={s.modal2content}>
          <div className={s.status}>
            <div className={s.statusIcon}>{value ? <On /> : <Pause />}</div>
            <p className={s.statusText}>{value ? 'Optimizing' : 'Paused'}</p>
          </div>
          <div className={s.message}>
            {value
              ? 'This will start launching campaigns from your queue immediately'
              : 'This will pause all of your campaigns in this group immediately'}
          </div>
        </div>
      ),
      centered: true,
      okText: 'Confirm',
      onOk: update,
    })
  }

  return (
    <div
      className={cls(
        s.optimizing,
        inUse ? s.on : s.off,
        is_pending && s.disabled
      )}
      onClick={toggleInUse}
    >
      {inUse ? <On /> : <Pause />}
      {inUse ? 'Optimizing' : 'Paused'}
    </div>
  )
}
