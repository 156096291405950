/*
 * @Description:
 * @Author: Mark
 * @Date: 2021-11-03 09:24:35
 * @LastEditors: Mark
 * @LastEditTime: 2021-11-04 17:13:48
 */
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import 'antd/dist/antd.less'
import 'swiper/swiper-bundle.min.css'
import 'driver.js/dist/driver.css'
import './styles'
import store from './store/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './utils/rem'
import Modal from './utils/modal'
import Empty from './components/empty'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        const [title, subtitle] = message.split('__')
        Modal.confirm({
          type: 'delete',
          title,
          subtitle,
          onCancel: () => callback(false),
          onOk: () => callback(true),
        })
      }}
    >
      <ConfigProvider
        renderEmpty={name => <Empty className="global-empty" text="No data" />}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()
