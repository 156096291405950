import React, { createElement, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import KeepAlive from 'react-activation'
import { useSelector } from 'react-redux'
import ErrorBoundary from '@/components/error-boundary'
import { routes, allSingleRoute } from './routes'
import AnimatedSwitch from './animatedSwitch'
import { CContext as C } from '@/context'

const render = ({ location }, route) => {
  let component =
    typeof route.component === 'function'
      ? createElement(route.component)
      : route.component
  component = <ErrorBoundary>{component}</ErrorBoundary>
  return route.cache ? (
    <KeepAlive name={route.key} cacheKey={route.key}>
      {component}
    </KeepAlive>
  ) : (
    component
  )
}

export default () => {
  const data = React.useContext(C)
  const { is_started_group } = useSelector(state => state.user.current)

  return (
    <>
      {data ? (
        <AnimatedSwitch>
          {[...routes, ...allSingleRoute].map((route, i) => (
            <Route
              key={route.key + i}
              path={route.path}
              render={props => render(props, route)}
            />
          ))}

          <Redirect to={routes[is_started_group ? 0 : 1].path} />
        </AnimatedSwitch>
      ) : null}
    </>
  )
}
