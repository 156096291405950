import { useRef, useMemo } from 'react'
import { Modal } from 'antd'
import cls from 'classnames'
import ImportCampaigns from './import'
import IdeasCampaigns from './ideas'
import { MetaCreation, GoogleCreation } from './core'
import { useTrackState } from '@/hooks'

import s from './index.module.scss'
import Import from 'ico/start/import.svg'
import Ideas from 'ico/start/ideas.svg'
import Create from 'ico/start/create.svg'
import Checked from 'ico/start/checked.svg'
import Meta from 'ico/meta.svg'
import Google from 'ico/google.svg'

const noop = () => {}

export default function CreationModal({
  mode,
  source,
  open,
  onCancel,
  onOk = noop,
  copy,
  imported,
  cid,
  thread,
  status,
  ideas,
  platform: p,
}) {
  const [platform, setPlatform] = useTrackState(p)
  const [currentTab, setCurrentTab] = useTrackState(
    mode === 'create' && !p ? 'precreate' : mode
  )
  const hasAutoSaved = useRef(false)

  const tabs = useMemo(() => {
    return currentTab === 'precreate'
      ? [
          {
            icon: <Meta />,
            text: 'meta',
          },
          {
            icon: <Google />,
            text: 'google',
          },
        ]
      : [
          {
            icon: <Import />,
            text: 'import',
          },
          {
            icon: <Ideas />,
            text: 'ideas',
          },
          {
            icon: <Create />,
            text: 'create',
          },
        ]
  }, [currentTab])

  const onTabClick = tab => {
    if (currentTab === 'precreate') {
      setCurrentTab('create')
      setPlatform(tab)
    } else {
      setCurrentTab(tab === 'create' ? 'precreate' : tab)
    }
  }

  const _onOk = data => {
    onOk?.(data)
    !mode && setCurrentTab()
    hasAutoSaved.current = false
  }

  const _onCancel = () => {
    hasAutoSaved.current ? onOk?.({ status: 'draft' }) : onCancel?.()
    !mode && setCurrentTab()
    hasAutoSaved.current = false
  }

  const Creation = platform === 'google' ? GoogleCreation : MetaCreation

  return (
    <Modal
      className={cls('custom-modal', mode === 'create' && s.fullscreen)}
      open={open}
      centered
      closable={false}
      footer={null}
      destroyOnClose
      onCancel={_onCancel}
    >
      {(!currentTab || currentTab === 'precreate') && (
        <div className={s.tab}>
          {tabs.map((v, k) => (
            <div
              onClick={() => onTabClick(v.text)}
              key={k}
              className={cls(v.text == currentTab && s.active)}
            >
              {v.icon}
              <>{v.text}</>
              <Checked />
            </div>
          ))}
        </div>
      )}
      {currentTab == 'import' && (
        <ImportCampaigns onOk={_onOk}>
          <Header
            title="Import campaigns"
            desc="You may import any approved campaigns that haven't ended"
          />
        </ImportCampaigns>
      )}
      {currentTab == 'ideas' && (
        <IdeasCampaigns ideas={ideas} onOk={_onOk}>
          <Header
            title="IDEAs"
            desc="See your AI generated campaign ideas! Approve to enter them into the queue for testing"
          />
        </IdeasCampaigns>
      )}
      {currentTab == 'create' && (
        <Creation
          copy={copy}
          imported={imported}
          source={source}
          cid={cid}
          thread={thread}
          status={status}
          hasAutoSaved={hasAutoSaved}
          onOk={() => _onOk({ status: 'waiting' })}
          onCancel={_onCancel}
        />
      )}
    </Modal>
  )
}

const Header = ({ title, desc }) => {
  return (
    <>
      <div className={s.title}>{title}</div>
      <div className={s.desc}>{desc}</div>
    </>
  )
}
