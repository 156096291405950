import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Popover, Button } from 'antd'
import { useRequest } from 'ahooks'
import { isEmpty } from 'lodash'
import CurrencyInput from '@/components/currency-input'
import { updateStageConfig } from '@/service/campaign'
import { publish } from '@/subscribe'

import cls from 'classnames'
import s from './index.module.scss'
import Meta from 'ico/meta.svg'
import Google from 'ico/dashboard/google.svg'
import Up from 'ico/v3-campaigns/arrow-up.svg'
import Down from 'ico/v3-campaigns/arrow-down2.svg'
import Dots from 'ico/v3-campaigns/dots.svg'
import Check from 'ico/v3-media/check.svg'

const Platform = {
  Google: 'google',
  Meta: 'meta',
}

const CampaignStatus = {
  Good: 1,
  Testing: 2,
  Fatiguing: 3,
}

export default function Overview({ data, onPlatformChange }) {
  const { google, meta, currency_symbol } = useSelector(
    state => state.user.current
  )
  const isGoogleConnected = !isEmpty(google)
  const isMetaConnected = !isEmpty(meta)
  const [platform, _setPlatform] = useState(() => {
    return isGoogleConnected ? Platform.Google : Platform.Meta
  })
  const setPlatform = val => {
    _setPlatform(val)
    onPlatformChange(val)
  }

  const getResult = key => data?.[key]?.result || 0
  const getThreshold = key => data?.[key]?.threshold || 0

  return (
    <div className={s.wrap}>
      <p
        className={cls(s.platform, {
          [s.active]: platform === Platform.Google,
          [s.disabled]: !isGoogleConnected,
        })}
        onClick={() => isGoogleConnected && setPlatform(Platform.Google)}
      >
        <Check className={s.check} />
        <span className={s.logo}>
          <Google />
        </span>
        GOOGLE ADS
      </p>
      <p
        className={cls(s.platform, {
          [s.active]: platform === Platform.Meta,
          [s.disabled]: !isMetaConnected,
        })}
        onClick={() => isMetaConnected && setPlatform(Platform.Meta)}
      >
        <Check className={s.check} />
        <span className={s.logo}>
          <Meta />
        </span>
        META ADS
      </p>

      {/* <div className={s.items}> */}
      <div className={s.item}>
        <Popover
          overlayClassName={s.popover}
          trigger="click"
          placement="topRight"
          content={
            <Prompt
              stage_id={data?.stage_id}
              status={CampaignStatus.Good}
              name="winning_threshold"
              value={getThreshold('winning')}
            />
          }
          destroyTooltipOnHide
        >
          <Dots className={s.dot} />
        </Popover>
        <div className={s.data}>
          <strong>{getResult('winning')}</strong>
          {/* <span className={cls(s.trend, s.down)}>
              <Down />1
            </span> */}
        </div>
        <div className={s.title}>Winners</div>
      </div>
      <div className={s.item}>
        <Popover
          overlayClassName={s.popover}
          trigger="click"
          placement="topRight"
          content={
            <Prompt
              stage_id={data?.stage_id}
              status={CampaignStatus.Testing}
              name="testing_threshold"
              value={getThreshold('testing')}
            />
          }
          destroyTooltipOnHide
        >
          <Dots className={s.dot} />
        </Popover>
        <div className={s.data}>
          <strong>{getResult('testing')}</strong>
          {/* <span className={cls(s.trend, s.up)}>
              <Up />1
            </span> */}
        </div>
        <div className={s.title}>Testing</div>
      </div>
      <div className={s.item}>
        <div className={s.data}>
          <strong>{getResult('losing')}</strong>
          {/* <span className={cls(s.trend, s.down)}>
              <Down />1
            </span> */}
        </div>
        <div className={s.title}>Losing</div>
      </div>
      <div className={s.item}>
        <Popover
          overlayClassName={s.popover}
          trigger="click"
          placement="topRight"
          content={
            <Prompt
              stage_id={data?.stage_id}
              status={CampaignStatus.Fatiguing}
              name="fatigue_threshold"
              value={getThreshold('fatigue')}
            />
          }
          destroyTooltipOnHide
        >
          <Dots className={s.dot} />
        </Popover>
        <div className={s.data}>
          <strong>{getResult('fatigue')}</strong>
          {/* <span className={cls(s.trend, s.down)}>
              <Down />1
            </span> */}
        </div>
        <div className={s.title}>Fatiguing</div>
      </div>
      {/* </div> */}
    </div>
  )
}

const Prompt = ({ stage_id, status, name, value }) => {
  const closePopover = () => document.dispatchEvent(new Event('mousedown'))
  const config = {
    [CampaignStatus.Good]: {
      title: 'Good Campaigns',
      subtitle: 'Campaign is “Good” when CPR is less than',
    },
    [CampaignStatus.Testing]: {
      title: 'Testing Campaigns',
      subtitle: 'Campaign is “Testing” when Total Amount Spent is less than',
    },
    [CampaignStatus.Fatiguing]: {
      title: 'Fatiguing Campaigns',
      subtitle: 'Campaign is “Fatiguing” when CPR is worsens days in a row',
    },
  }[status]

  const iptProps = (() => {
    const props = { defaultValue: value }
    if (status === CampaignStatus.Fatiguing) {
      props.formatter = undefined
      props.parser = undefined
      props.style = { marginLeft: 0 }
    }
    return props
  })()

  const [val, setVal] = useState(value)

  const { loading, run } = useRequest(
    async () => {
      if (val !== value) {
        await updateStageConfig({ stage_id, [name]: val })
        publish({ type: 'global_fetching' })
      }
      closePopover()
    },
    { manual: true }
  )

  return (
    <div className={s.prompt}>
      <div className={s.headline}>
        <div>
          <div className={s.title}>{config.title}</div>
          <div className={s.subtitle}>{config.subtitle}</div>
        </div>
      </div>
      <div className={s.content}>
        {[CampaignStatus.Good, CampaignStatus.Testing].includes(status) &&
          'CPR'}
        <CurrencyInput className={s.ipt} {...iptProps} onChange={setVal} />
        {status === CampaignStatus.Good && <em>(Average CPR)</em>}
        {status === CampaignStatus.Fatiguing && 'days'}
      </div>
      <div className={s.footer}>
        <Button onClick={closePopover}>Cancel</Button>
        <Button type="primary" disabled={!val} loading={loading} onClick={run}>
          Confirm
        </Button>
      </div>
    </div>
  )
}
