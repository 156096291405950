import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Select, Switch, Modal } from 'antd'
import cls from 'classnames'
import { DailyBudget } from '../../../v4-campaigns/components/Overview'
import Icons from '../../icons'
import { MediaPicker } from '@/pages/v3-media-library'
import VoicePicker from '../Voice'
import Strategist from '../Strategist'
import styles from './index.module.scss'
import { useBoolean, useReactive } from 'ahooks'
import * as api from '@/service'
import * as _ from '@/utils'
import { CContext } from '@/context'

const { Message } = _

export default ({ type = '' }) => {
  const { minimum_group_budget, is_pending, report_frequency } = useSelector(
    state => state.user.current
  )
  const [open, { toggle }] = useBoolean(false)
  const { data, refreshData } = useContext(CContext)
  const { is_manual = false, budget } = data?.overview.budget_optimization || {}

  const txt = React.useRef({
    strategy: {
      title: 'Account Strategist',
      desc: 'I help you analyze and strategize how to reach your goals',
    },
    campaign: {
      title: 'Creative Director',
      desc: 'I can help you create campaigns and brainstorm ideas',
    },
    manager: {
      title: 'Media Buyer',
      desc: 'I manage and optimize your campaigns 24/7',
    },
  }).current

  const toggleBudgetMode = () => {
    Modal.confirm({
      className: styles.modal,
      icon: null,
      title: `Switch to ${is_manual ? 'Autopilot' : 'Copilot'} Mode`,
      content: is_manual
        ? 'We will automatically rebalance your budget every 60 minutes for optimal performance'
        : 'You will have to launch campaigns and manage all the budgets yourself. We will still test your campaigns.',
      centered: true,
      okText: 'Confirm',
      async onOk() {
        if (is_manual && budget < minimum_group_budget) return toggle()
        await api.updateCampaignSetting({
          action: 'budget_optimization',
          is_manual: is_manual ? 0 : 1,
          user_budget: budget,
        })
        refreshData()
      },
    })
  }

  const _state = useReactive({ reportValue: '' })

  return (
    <>
      {type ? (
        <div
          className={cls(styles.empty, {
            [styles.strategy]: type == 'strategy',
          })}
        >
          {type != 'strategy' && (
            <>
              <Icons.RobotEmpty className={styles.icon} />
              <div className={styles.title}>{txt[type].title}</div>
              <div className={styles.desc}>{txt[type].desc}</div>
            </>
          )}
          {type == 'strategy' ? (
            // <div className={styles.strategy}>
            //   <span className={styles.label}>Report Frequency</span>
            //   <Select
            //     defaultValue={report_frequency}
            //     onChange={e => {
            //       _state.reportValue = e
            //       api
            //         .request({
            //           url: `3/assistant/config?action=report_frequency&frequncy=${e}`,
            //         })
            //         .then(({ data }) => {
            //           if (data?.result)
            //             return Message.success('Report frequency updated')
            //           Message.error(data?.success)
            //         })
            //     }}
            //     className={cls(_state.reportValue && styles.reportDone)}
            //     popupClassName={styles.chatDropdown}
            //     options={[
            //       // {
            //       //   value: 'every',
            //       //   label: 'Every Day',
            //       // },
            //       {
            //         value: 'weekly',
            //         label: 'Weekly',
            //       },
            //       {
            //         value: 'monthly',
            //         label: 'Monthly',
            //       },
            //     ]}
            //   />
            //   {_state.reportValue && (
            //     <Icons.ChatCampaign className={styles.chatIcon} />
            //   )}
            // </div>
            <Strategist />
          ) : type == 'campaign' ? (
            <div className={styles.creative}>
              <MediaPicker multiple onOk={() => {}}>
                {({ trigger }) => (
                  <div className={styles.creativeBox} onClick={trigger}>
                    <Icons.CreativeVoice />
                    Assets
                  </div>
                )}
              </MediaPicker>
              <VoicePicker>
                {({ state }) => (
                  <div
                    className={styles.creativeBox}
                    onClick={() => (state.open = true)}
                  >
                    <Icons.CreativeVoice />
                    Brand voice
                  </div>
                )}
              </VoicePicker>
            </div>
          ) : null}
        </div>
      ) : null}
      <Modal
        className="custom-modal"
        open={open}
        centered
        destroyOnClose
        footer={null}
        closable={false}
        onCancel={toggle}
      >
        <DailyBudget
          current={budget}
          suggested={minimum_group_budget}
          onOk={() => {
            toggle()
            refreshData()
          }}
        />
      </Modal>
    </>
  )
}
