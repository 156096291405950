import { request } from './request'

export function fetchFolderList() {
  return request({ url: '/3/folder/list' })
}

export function createFolder(name) {
  return request({
    method: 'POST',
    url: '3/folder/submit',
    data: { name },
  })
}

export function fetchAssetList(folder_id, platform) {
  return request({
    url: 'asset/list',
    params: { folder_id, platform },
  })
}

export function createAsset(data) {
  return request({
    method: 'POST',
    url: 'asset/upload/complete',
    data,
  })
}

export function verifyAsset({ platform, asset_ids }) {
  return request({
    method: 'POST',
    url: 'asset/verify',
    data: { platform, asset_ids },
  })
}

export function deleteAsset(id) {
  return request({
    url: 'asset/delete',
    params: { id },
  })
}

export function fetchFacebookAssets() {
  return request({ url: '3/asset/import' })
}

export function importAssets(ids) {
  return request({
    method: 'POST',
    url: '3/asset/import_submit',
    data: { ids },
  })
}

export function confirmAssets() {
  return request({ url: '3/asset/confirm' })
}

export function importYoutube(link) {
  return request({
    url: '3/asset/youtube',
    params: { link },
  })
}
