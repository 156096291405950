import React from 'react'
import { Modal, Button, Checkbox } from 'antd'
import { useEventEmitter, useRequest, useRafTimeout } from 'ahooks'
import { useSelector, useDispatch } from 'react-redux'
import * as api from '@/service'
import S from './index.module.scss'
import Close from 'ico/ads/close.svg'
import Subtract from 'ico/subtract.svg'
import Back from 'ico/login/back.svg'
import Logo from 'ico/logo.svg'
import line from '@/assets/images/onboard/line.png'
import success from '@/assets/images/profile/success.png'
import cls from 'classnames'
import { loadStripe } from '@stripe/stripe-js'
import * as _ from '@/utils'
import { useHistory } from 'react-router-dom'
import {
  toggleInStripe,
  updateStripe,
  updateStripeId,
} from '@/store/actions/user.actions'

const { REACT_APP_ENV: ENV } = process.env
const { Message } = _
const C = React.createContext(null)

export default React.memo(function Stripe() {
  const ev = useEventEmitter()
  const dispatch = useDispatch()
  const [step, setStep] = React.useState(-1)
  const [info, setInfo] = React.useState(null)
  const { error_code, is_started } = useSelector(state => state.user.current)
  const { showStripe } = useSelector(state => state.user.shared)
  const isInvalid = React.useMemo(
    () => error_code == 'BUSINESS_INVALID',
    [error_code]
  )

  React.useEffect(() => {
    if (!is_started) return
    if (error_code == 'BUSINESS_INVALID') setStep(0)
    if (error_code == 'PLAN_REQUIRED' || showStripe) setStep(1)
  }, [error_code, showStripe, is_started])

  ev.useSubscription(data => {
    setStep(typeof data == 'number' ? data : data.step)

    showStripe && dispatch(toggleInStripe(false))
  })
  return (
    <Modal
      open={step != -1}
      width="fit-content"
      centered
      destroyOnClose
      wrapClassName={S.wrap}
      footer={null}
      closable={false}
    >
      <C.Provider value={{ ev, isInvalid, info, setInfo, error_code }}>
        {!step && <Whoops />}
        {step == 1 && <Plan />}
        {step == 2 && <PaymentFail />}
        {step == 3 && <PaymentSuccess />}
      </C.Provider>
    </Modal>
  )
})

const Whoops = () => {
  const { ev } = React.useContext(C)
  return (
    <div className={S.whoops}>
      <h1>Whoops</h1>
      <div className={S.desc}>
        lt appears that your account is inactive. Please visit the billing page
        to continue using your account.
      </div>
      <Button
        shape="round"
        type="primary"
        onClick={() => {
          ev.emit(1)
        }}
      >
        Update billing details
      </Button>
    </div>
  )
}

const Plan = () => {
  const { ev, isInvalid, setInfo, error_code } = React.useContext(C)
  const [unit, setUnit] = React.useState('mo')
  const [list, setList] = React.useState([])
  const [currentId, setCurrentId] = React.useState(null)
  const { billing } = useSelector(state => state.user.current)
  const dispatch = useDispatch()

  const handleUpdata = React.useCallback(async v => {
    setCurrentId(v.product_id)
    const { data, message } = await api
      .fetchBillingProductUpdate({
        product_id: v.product_id,
      })
      .finally(() => setCurrentId(null))
    if (data?.result) {
      dispatch(updateStripeId(v.product_id))
      if (error_code) return ev.emit(3)
      Message.success('Subscription change is completed')
      setTimeout(() => {
        location.href = location.href
      }, 1000)
      return
    }
    if (data?.error_code == 'NO_CARD') {
      setInfo(v)
      return ev.emit({ step: 2 })
    }

    Message.warn(message)
  }, [])

  const handleClick = React.useCallback(async v => {
    handleUpdata(v)
  }, [])

  const { data } = useRequest(async () =>
    !billing?.product_list
      ? (await api.fetchBillingProducts()).data
      : { items: billing?.product_list }
  )

  React.useEffect(() => {
    if (!data?.items) return
    setList(data?.items.filter(v => v.unit == unit))

    !billing?.product_list && dispatch(updateStripe(data.items))
    billing?.product_id &&
      setUnit(data?.items.find(v => v.product_id == billing?.product_id).unit)
  }, [data])

  React.useEffect(() => {
    if (!data?.items) return
    setList(data?.items.filter(v => v.unit == unit))
  }, [unit])

  return (
    <div className={S.plan}>
      <div className={S.planTitle}>
        <div className={S.planTitleLeft}>
          <div>Choose a plan</div>
          <span>Upgrade your plan anytime for more advanced features</span>
        </div>
        <div className={S.planTitleTab}>
          <div
            className={cls(unit == 'mo' && S.active)}
            onClick={() => setUnit('mo')}
          >
            Monthly biling
          </div>
          <div
            className={cls(unit == 'yr' && S.active)}
            onClick={() => setUnit('yr')}
          >
            Annual billing <span>Save 20%</span>
          </div>
        </div>
        {error_code != 'PLAN_REQUIRED' && (
          <Close
            onClick={() => {
              isInvalid ? ev.emit(0) : ev.emit(-1)
            }}
          />
        )}
      </div>
      <div className={S.planList}>
        <ul>
          {list.map((v, i) => (
            <li
              key={v.product_id}
              className={cls(v.product_id == billing?.product_id && S.active)}
            >
              <div className={S.planListTitle}>
                <img src={v.icon} />
                {v.title}
              </div>
              <div className={S.planListPrice}>
                <span>
                  {v.currency_symbol}
                  {v.price}
                </span>
                /{v.unit}
              </div>
              <div className={S.planListSpend}>{v.ratio} of ad spend</div>
              <div className={S.planListLine}>
                <img src={line} />
              </div>
              <dl>
                <dt>What's Included</dt>
                {v.content.map((j, k) => (
                  <dd key={k}>
                    <Subtract />
                    <span>{j}</span>
                  </dd>
                ))}
              </dl>
              <Button
                disabled={currentId == v.product_id}
                shape="round"
                type="primary"
                onClick={() => handleClick(v)}
              >
                {v.action}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const PaymentFail = () => {
  const { ev, isInvalid, info, error_code } = React.useContext(C)
  const [checked, setChecked] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()

  let stripe_instance,
    stripe_card = null
  const token =
    ENV == 'dev'
      ? 'pk_test_51NNdfME6P0ymZMbfkas7iYlRWgksEX5m7sKd2DPDQLpD5okP4dX0ZMqv53DQQCNFLnq1T1JZorqdYrAWxOz9SY8A00uJGHLuDx'
      : 'pk_live_c440NmerulW2YkACJoSEEnQx'

  React.useEffect(() => {
    !(async () => {
      stripe_instance = await loadStripe(token)
      stripe_card = stripe_instance.elements().create('card')
      stripe_card.mount('.payment-element')
      stripe_card.addEventListener('change', event => {
        if (event.error) {
          Message.warn(event.error.message)
          return
        }
      })
    })()
  }, [])

  const handleSubmit = React.useCallback(
    id => {
      if (!stripe_instance) return
      stripe_instance.createToken(stripe_card).then(async result => {
        if (result.error) {
          Message.warn(result.error.message)
        } else {
          if (!result.token) {
            Message.warn('Error. Please try again.')
          } else {
            let the_token = result.token
            setLoading(true)

            const { data, message } = await api
              .fetchBillingProductUpdate({
                product_id: id,
                stripe_token: the_token,
              })
              .finally(() => setLoading(false))
            if (data?.result) {
              dispatch(updateStripeId(id))
              if (error_code) return ev.emit(3)
              Message.success('Subscription change is completed')
              setTimeout(() => {
                location.href = location.href
              }, 1000)
              return
            }

            Message.warn(message)
          }
        }
      })
    },
    [stripe_instance]
  )

  return (
    <div className={S.payment}>
      <div
        className={S.back}
        onClick={() => {
          ev.emit(1)
        }}
      >
        <Back />
        Back
      </div>
      <div className={S.paymentMain}>
        <div className={S.paymentMainLeft}>
          <div className={S.leftMain}>
            <Logo />
            <div className={S.leftSub}>Subscribe to [{info.title}]</div>
            <div className={S.leftPer}>
              <span>
                {info.currency_symbol}
                {info.price}
              </span>
              <div className={S.leftPerR}>
                Per
                <br />
                {info.unit == 'mo' ? 'month' : 'year'}
              </div>
            </div>
            <div className={S.leftSpend}>{info.ratio} of ad spend</div>
            <div className={S.leftBill}>
              Billed {info.unit == 'mo' ? 'monthly' : 'yearly'}
            </div>
          </div>
          <div className={S.leftPowered}>
            Powered by <b>stripe</b>
          </div>
        </div>
        <div className={S.paymentMainRight}>
          <div className={S.close}>
            {error_code != 'PLAN_REQUIRED' && (
              <Close onClick={() => (isInvalid ? ev.emit(0) : ev.emit(-1))} />
            )}
          </div>
          <div className={S.rightMain}>
            <div className="payment-element" />
            <div className={S.rightMainAgree}>
              <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
                I agree to Cactivate’s
                <a
                  target="_blank"
                  href="https://www.cactivate.com/terms-of-service"
                >
                  Terms of Service
                </a>
                and
                <a
                  target="_blank"
                  href="https://www.cactivate.com/privacy-policy"
                >
                  Privacy Policy
                </a>
              </Checkbox>
            </div>
            <Button
              type="primary"
              disabled={!checked}
              onClick={() => handleSubmit(info.product_id)}
              loading={loading}
            >
              Subscribe
            </Button>
            <div className={S.tips}>
              By confirming your subscription, you allow Cactivate to charge
              your card for this payment and future payments in accordance with
              our terms, you agree to our terms of service and privacy policy.
              You can always cancel your subscription
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PaymentSuccess = () => {
  const { ev, error_code } = React.useContext(C)
  const history = useHistory()

  useRafTimeout(() => {
    if (error_code == 'PLAN_REQUIRED') {
      ev.emit(-1)
      // location.href = '/start?from=start'
      return
    }
    location.href = '/'
  }, 3000)

  return (
    <div className={S.success}>
      <div className={S.successMain}>
        <img src={success} />
        <div className={S.title}>Account Active!</div>
        <div className={S.desc}>Please continue to access your dashboard.</div>
      </div>
    </div>
  )
}
