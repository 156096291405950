import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { InputNumber } from 'antd'
import { isNumber } from 'lodash'
import cls from 'classnames'
import s from './index.module.scss'

export default function CurrencyInput({
  className,
  style,
  icon,
  suffix,
  value,
  ...props
}) {
  const currency_symbol = useSelector(
    state => state.user.current.currency_symbol
  )
  const el = useRef()

  useEffect(() => {
    const style = getComputedStyle(el.current.querySelector('input'))
    const { paddingLeft, paddingRight, fontSize, fontWeight } = style
    if (!icon) return
    const shadow = el.current.querySelector(`.${s.shadow}`)

    shadow.style.paddingLeft = paddingLeft
    shadow.style.paddingRight = paddingRight
    shadow.style.fontSize = fontSize
    shadow.style.fontWeight = fontWeight
  }, [])

  return (
    <div ref={el} className={cls(s.wrap, className)} style={style}>
      <InputNumber
        controls={false}
        formatter={val => val && currency_symbol + val}
        parser={val => val.replace(currency_symbol, '')}
        value={value}
        {...props}
      />
      {!!icon && (
        <div className={s.shadow}>
          <span>{isNumber(value) ? currency_symbol + value : ''}</span>
          {icon}
        </div>
      )}
      {suffix && <span className={s.suffix}>{suffix}</span>}
    </div>
  )
}
