import React from 'react'
import { useSelector } from 'react-redux'

export default ({ className, value, onClick = () => {} }) => {
  const { currency_symbol } = useSelector(state => state.user.current)
  return isNaN(value) ? (
    '--'
  ) : (
    <span className={className} onClick={onClick}>
      {currency_symbol + value}
    </span>
  )
}
