/*
 * @Description:
 * @Author: Mark
 * @Date: 2021-11-10 17:40:22
 * @LastEditors: Mark
 * @LastEditTime: 2021-11-10 17:40:22
 */
import EventEmitter from 'events'

export default new EventEmitter()
