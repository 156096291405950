import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useRequest, useCountDown, useLatest } from 'ahooks'
import { useActivate, useUnactivate } from 'react-activation'
import { fetchUpdateTime } from '@/service/ads'
import { useSelector } from 'react-redux'
import { showLoadingCat } from '@/utils/cat'
import { createSubscription } from '@/hooks'

const { useSubscribe, publish } = createSubscription()

export { useSubscribe }

const padZero = val => String(val).padStart(2, '0')
const updateInterval = 3600 // 更新间隔, 单位s

export default function useCountdown({ disabled = false, onRestart }) {
  const { is_pending, is_started_group } = useSelector(
    state => state.user.current
  )
  const isPending = useLatest(is_pending)
  const inactive = useRef(false)
  const tid = useRef()
  const hideCat = useRef()
  const enabled = is_started_group && !disabled

  const [date, setDate] = useState()
  const [leftTime, t] = useCountDown({
    targetDate: date,
    onEnd() {
      if (inactive.current || isPending.current) return
      hideCat.current = showLoadingCat('business_next')
      tid.current = setTimeout(run, 10000)
    },
  })
  const formatted = useMemo(
    () => [padZero(t.minutes), padZero(t.seconds)].join(':'),
    [t]
  )
  const formattedRef = useRef()
  if (formattedRef.current !== formatted) {
    publish((formattedRef.current = formatted))
  }

  const leftSeconds = Math.round(leftTime / 1000)
  const progress = date
    ? ((updateInterval - leftSeconds) / updateInterval) * 100
    : 0

  useActivate(() => {
    inactive.current = false
    run()
  })
  useUnactivate(() => {
    inactive.current = true
    cancel()
    clearTimeout(tid.current)
  })

  const { run, cancel } = useRequest(
    async () => {
      const { data, error } = await fetchUpdateTime()
      return error ? Promise.reject() : data
    },
    {
      manual: true,
      pollingInterval: 10000,
      pollingWhenHidden: false,
      onSuccess({ left_time, status }) {
        if (status === 'COUNTDOWN') {
          const now = new Date()
          setDate(now.setSeconds(now.getSeconds() + left_time))
          cancel()
          if (hideCat.current) {
            onRestart?.()
            hideCat.current()
            hideCat.current = null
          }
        } else if (!hideCat.current) {
          hideCat.current = showLoadingCat('business_next')
        }
      },
    }
  )

  useEffect(() => {
    enabled ? run() : cancel()
  }, [enabled])

  return {
    enabled,
    timeData: t,
    formatted,
    progress,
  }
}
