import React, { useEffect, useMemo, useState, useRef } from 'react'
import s from './index.module.scss'
import cls from 'classnames'
import { Form, Input, Radio, Button, Spin, Progress } from 'antd'
import {
  useReactive,
  useRequest,
  useUpdateLayoutEffect,
  useInterval,
} from 'ahooks'
import * as api from '@/service'
import * as _ from '@/utils'

import MSelect from '@/components/m-select/index'
import { industrys } from './config'
import { useHistory } from 'react-router-dom'
import Fb from 'ico/fb.svg'
import Success from 'ico/success.svg'
import Error from 'ico/error.svg'
import { modifyUser } from '@/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import finsh from '@/assets/images/onboard/end.gif'
import average from '@/assets/images/onboard/average.png'
import back from '@/assets/images/onboard/back.png'
import help from '@/assets/images/onboard/help.png'
import pickBy from 'lodash/pickBy'

const { Message, Fbevent, removeToken, showLoadingCat } = _
const { REACT_APP_ENV: ENV } = process.env

const C = React.createContext(null)

const OnBoard = props => {
  const { isPop } = props
  const [step, setStep] = React.useState(isPop ? 2 : 0)
  const [type, setType] = React.useState('ecommerce')
  const history = useHistory()
  const { is_onboarded } = useSelector(state => state.user.current)
  const handleOut = async () => {
    await removeToken()
    history.replace('/chat')
  }

  useEffect(() => {
    if (is_onboarded && !isPop) history.replace('/')
  }, [])

  const showBack = useMemo(() => step > 0 && !isPop && step != 4, [step, isPop])

  return (
    <C.Provider value={{ setStep, setType, type, ...props }}>
      {showBack && (
        <div
          className={s.back}
          onClick={() => {
            setStep(step - 1)
          }}
        >
          <img src={back} /> <span>Back</span>
        </div>
      )}
      {!isPop && (
        <Button type="primary" className={s.loginOut} onClick={handleOut}>
          Log Out
        </Button>
      )}
      <div className={cls(s.onboard, { [s.onboardPop]: isPop })}>
        <div className={s.onboardMain}>
          {!isPop && (
            <div className={cls({ [s.hidden]: step })}>
              <UserInfo />
            </div>
          )}
          {step == 1 && <Connected />}
          {step == 2 && <Platform />}
          {step == 3 && <LittleHelp />}
          {step == 4 && <Finsh />}
        </div>
      </div>
    </C.Provider>
  )
}

const UserInfo = () => {
  const { setStep, setType } = React.useContext(C)
  const [form] = Form.useForm()
  const [load, setLoad] = React.useState(false)

  const { loading, data } = useRequest(api.getOnboardUser)

  useUpdateLayoutEffect(() => {
    // if (data) setStep(1)
    form.setFieldsValue(data)
  }, [data])

  const hanldFinish = async values => {
    setLoad(true)
    try {
      await form.validateFields(['name', 'industry', 'website', 'type'])
      const { error } = await api.setOnboardUser(values)
      setLoad(false)
      if (!error) {
        setStep(1)
        setType(values.type)
      }
    } catch (e) {
      setLoad(false)
    }
  }

  return (
    <div className={s.userInfo}>
      <h3>Get Started</h3>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={hanldFinish}
          name="userform"
        >
          <div className={s.onboardMainItem}>
            <Form.Item
              label="Company Name"
              name="name"
              rules={[
                { required: true, message: 'Please input your business!' },
              ]}
            >
              <Input placeholder="What's your company name?" />
            </Form.Item>
            <MSelect
              label="Industry"
              name="industry"
              popupClassName="onboard-select"
              placeholder="Please select"
              options={industrys}
              onChange={v => {
                console.log('v', v)
              }}
            />
          </div>
          <div className={s.onboardMainItem}>
            <Form.Item
              label="URL"
              name="website"
              rules={[{ required: true, message: 'Please input your url!' }]}
            >
              <Input addonBefore="https://" placeholder="Must be valid" />
            </Form.Item>
            <Form.Item
              label="What type of ads do you run?"
              name="type"
              rules={[{ required: true, message: 'Please select your type!' }]}
            >
              <Radio.Group onChange={e => {}}>
                <Radio value="ecommerce">E-Commerce</Radio>
                <Radio value="lead">Lead Generation</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" loading={load}>
            Continue
          </Button>
        </Form>
      </Spin>
    </div>
  )
}

const Connected = () => {
  const _C = React.useContext(C)
  const { setStep, isPop } = _C
  const state = useReactive({})

  useRequest(async () => {
    const info = await api.fetchPlatformsData()
    if (info?.fb_id) {
      setStep(2)
    }
    return info
  })

  const { REACT_APP_ENV: ENV } = process.env

  const handlReconnect = async () => {
    state.platform = 'facebook'
    if (ENV == 'dev') {
      console.log('Onboard on Dev')
      const { data } = await api.FbConnect({})
      if (!data.result) {
        Message.warn(data.message)
      } else {
        state.fb_id = '164251791054647'
        setStep(2)
      }
      return
    }
    console.log('Onboard started')
    try {
      FB ?? Fbevent.init()
      Fbevent.do_facebook(facebook_obj => {
        state.fb_id = facebook_obj.facebook_id
        setStep(2)
      })
    } catch (e) {}
  }

  return (
    <div className={cls(s.platform, s.connected)}>
      <h3>{!isPop ? 'Get Started' : 'Default settings'}</h3>
      {!isPop && (
        <div className={s.connectedMain}>
          <div
            className={cls(s.platformName, {
              [s.active]: state?.fb_id,
            })}
            onClick={handlReconnect}
          >
            <Fb />
            <span>Facebook</span>
          </div>
          {/* {!state.fb_id && (
            <p className={s.platformError}>Need to re associate</p>
          )} */}
        </div>
      )}
    </div>
  )
}

const Platform = () => {
  const _C = React.useContext(C)
  const [form] = Form.useForm()

  const state = useReactive({ labels: {}, verify: {} })
  const account = Form.useWatch('account', form)
  const page = Form.useWatch('page', form)
  const pixel = Form.useWatch('pixel', form)
  const event = Form.useWatch('event', form)
  const lead_type = Form.useWatch('lead_type', form)
  console.log('🚀 ~ file: index.js:241 ~ Platform ~ lead_type:', lead_type)
  const { setStep, isPop } = _C
  const type = Form.useWatch('type', form) || _C.type

  useRequest(async () => {
    const info = await api.fetchPlatformsData()

    state.fb_id = info?.fb_id

    if (!isPop && info?.fb_id) {
      state.platform = 'facebook'
    }
    if (info?.fb_id) {
      form.setFieldsValue(info)
      state.labels = info
    }

    return info
  })

  React.useEffect(() => {
    if (isPop) form.setFieldsValue(_C.values)
  }, [isPop])

  React.useEffect(() => {
    if (state?.fb_id) {
      ;(async () => {
        state.accountLoading = true
        const data = await Promise.allSettled([
          api.fetchPlatformsLoad({
            type: 'account',
          }),
          api.fetchPlatformsLoad({
            type: 'page',
          }),
        ])
        state.accountLoading = false
        state.accounts = data[0].value
        state.pages = data[1].value
      })()
    }
  }, [state?.fb_id])

  useUpdateLayoutEffect(() => {
    if (state.accountFeedback && state.accountFeedback != 'success') return
    state.pixelLoading = true

    api
      .fetchPlatformsLoad({
        type: 'pixel',
        account_id: account,
      })
      .then(data => {
        state.pixels = data
      })
    state.pixelLoading = false
  }, [account])

  useUpdateLayoutEffect(() => {
    if (state.pageFeedback && state.pageFeedback != 'success') return
    state.insLoading = true

    api
      .fetchPlatformsLoad({
        type: 'instagram',
        page_id: page,
      })
      .then(data => {
        state.instagrams = data
      })
    state.insLoading = false
  }, [page])

  useUpdateLayoutEffect(() => {
    if (
      !account ||
      !page ||
      (state.accountFeedback && state.accountFeedback != 'success') ||
      (state.pageFeedback && state.pageFeedback != 'success')
    )
      return
    ;(async () => {
      state.eventLoading = true
      const rqs = [
        api.fetchPlatformsLoad({
          type: 'event',
          account_id: account,
          pixel_id: pixel,
        }),
      ]
      lead_type == 'leadform' &&
        rqs.push(
          api.fetchPlatformsLoad({
            type: 'leadform',
            account_id: account,
            page_id: page,
          })
        )
      const reps = await Promise.allSettled(rqs)
      state.eventLoading = false

      state.events = reps[0].value
      lead_type == 'leadform' && (state.leadforms = reps[1].value)
    })()
  }, [account, pixel, lead_type, page])

  const handlVerify = async (type, id, msg, feedback, key) => {
    let params = { type, id }
    params = type == 'event' ? { ...params, account_id: account } : params
    const {
      data: { result, message },
    } = await api.fetchPlatformVerify(params)
    key && (state[key] = message)
    state[msg] = message
    state[feedback] = result ? 'success' : 'fail'
    state.verify[type] = result
  }

  const isDisabled = useMemo(() => {
    return Object.values(state.verify).includes(false)
  }, [{ ...state.verify }])

  const hanldFinish = async values => {
    console.log('🚀 ~ file: index.js:363 ~ hanldFinish ~ values:', values)
    state.load = true
    const validate = [
      'account',
      'page',
      'instagram',
      'lead_type',
      lead_type == 'website' ? ('pixel', 'event') : 'leadform',
      isPop ? 'type' : '',
    ]
    await form.validateFields(validate)
    try {
      if (isPop) {
        const m = showLoadingCat('business_settings_update')
        const { message, error } = await api.fetchUsersettingsUpdate({
          rtype: 'default_settings',
          data: {
            ...pickBy(state.labels),
            ...values,
            fb_id: state.fb_id ?? '',
          },
        })
        m()
        error && Message.warn(message)
        _C.onOk()
        return
      }
      if (!state.platform) return Message.warn('Please select platform')

      const { error, message } = await api.fetchPlatformSave({
        ...pickBy(state.labels),
        ...values,
        lead_type: !lead_type ? 'website' : lead_type,
        fb_id: state.fb_id ?? '',
      })
      state.load = false
      if (error) return Message.warn(message)
      setStep(3)
    } catch (e) {
      state.load = false
    }
  }

  const handlReconnect = async () => {
    state.connected = true
    if (ENV == 'dev') {
      console.log('Onboard on Dev')
      const { data } = await api
        .FbConnect({})
        .finally(() => (state.connected = false))
      if (!data.result) {
        Message.warn(data.message)
      } else {
        form.resetFields()
        form.setFieldsValue({ lead_type })
        handleResetState()
        state.fb_id = '164251791054647'
      }
      return
    }
    try {
      FB ?? Fbevent.init()
      Fbevent.do_facebook(facebook_obj => {
        form.resetFields()
        handleResetState()
        state.fb_id = facebook_obj.facebook_id
      })
    } catch (e) {}
    state.connected = false
  }

  const handleResetState = () => {
    state.labels = {}
    state.verify = {}
    state.accountFeedback = ''
    state.pageFeedback = ''
    state.pixelFeedback = ''
    state.eventFeedback = ''
    state.leadformFeedback = ''
    state.instagramFeedback = ''
    state.accountMessage = ''
    state.pageMessage = ''
    state.pixelMessage = ''
    state.eventMessage = ''
    state.leadformMessage = ''
    state.averageVal = ''
  }

  return (
    <div className={s.platform}>
      <h3>{!isPop ? 'Get Started' : 'Default settings'}</h3>
      {!isPop && (
        <Spin spinning={!!state?.connected}>
          <div className={cls(s.platformName)} onClick={handlReconnect}>
            <Fb />
            <span>Reconnect</span>
          </div>
        </Spin>
      )}
      <Spin spinning={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={hanldFinish}
          name="platform"
          disabled={!state?.fb_id}
        >
          {isPop && (
            <div className={s.onboardMainItem}>
              <Form.Item
                label="What type of ads do you run?"
                name="type"
                rules={[
                  { required: true, message: 'Please select your type!' },
                ]}
              >
                <Radio.Group onChange={e => {}}>
                  <Radio value="ecommerce">E-Commerce</Radio>
                  <Radio value="lead">Lead Generation</Radio>
                </Radio.Group>
              </Form.Item>
              <div className={s.selectItem}>
                <Form.Item label=" " />
              </div>
            </div>
          )}
          {/* {!state.platform && <div className={s.mask} />} */}
          <div className={s.onboardMainItem}>
            <div className={s.selectItem}>
              <div className={s.selectLabel}>
                <span>*</span>Ad Account
              </div>
              <Spin spinning={!!state.accountLoading && !!state.fb_id}>
                <MSelect
                  popupClassName="onboard-select"
                  name="account"
                  disabled={!state.fb_id || isPop}
                  onChange={(v, option) => {
                    state.labels.account_name = option.label
                    handlVerify(
                      'account',
                      v,
                      'accountMessage',
                      'accountFeedback'
                    )
                  }}
                  options={state.accounts}
                  placeholder="Please select"
                />
              </Spin>
              {state.accountFeedback && (
                <p className={s[state.accountFeedback]}>
                  {state.accountFeedback == 'success' ? <Success /> : <Error />}
                  {state.accountMessage}
                </p>
              )}
            </div>
            <div className={s.selectItem}>
              <div className={s.selectLabel}>
                <span>*</span>Page
              </div>
              <Spin spinning={!!state.accountLoading && !!state.fb_id}>
                <MSelect
                  name="page"
                  disabled={!state.fb_id}
                  popupClassName="onboard-select"
                  options={state.pages}
                  onChange={(v, option) => {
                    console.log(
                      '🚀 ~ file: index.js:533 ~ Platform ~ option:',
                      option
                    )
                    state.labels.page_name = option.label
                    handlVerify('page', v, 'pageMessage', 'pageFeedback')
                  }}
                  placeholder="Please select"
                />
              </Spin>
              {state.pageFeedback && (
                <p className={s[state.pageFeedback]}>
                  {state.pageFeedback == 'success' ? <Success /> : <Error />}
                  {state.pageMessage}
                </p>
              )}
            </div>
          </div>
          <div className={s.onboardMainItem}>
            {type == 'ecommerce' ? (
              <div className={s.selectItem}>
                <div className={s.selectLabel}>
                  <span>*</span>Please select your default pixel
                </div>
                <Spin spinning={!!state.pixelLoading && !!state.fb_id}>
                  <MSelect
                    name="pixel"
                    popupClassName="onboard-select"
                    options={state.pixels}
                    disabled={!state.fb_id}
                    onChange={(v, option) => {
                      state.labels.pixel_name = option.label
                      handlVerify('pixel', v, 'pixelMessage', 'pixelFeedback')
                    }}
                    placeholder="Please select"
                  />
                </Spin>
                {state.pixelFeedback && (
                  <p className={s[state.pixelFeedback]}>
                    {state.pixelFeedback == 'success' ? <Success /> : <Error />}
                    {state.pixelMessage}
                  </p>
                )}
              </div>
            ) : (
              <Form.Item
                label="How do you capture leads?"
                name="lead_type"
                rules={[
                  { required: true, message: 'Please select your type!' },
                ]}
              >
                <Radio.Group onChange={e => {}}>
                  <Radio value="website">Website</Radio>
                  <Radio value="leadform">Lead Form</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <div className={s.selectItem}>
              <div className={s.selectLabel}>
                <span>*</span>Instagram
              </div>
              <Spin spinning={!!state.insLoading && !!state.fb_id}>
                <MSelect
                  name="instagram"
                  popupClassName="onboard-select"
                  disabled={!state?.fb_id}
                  options={state?.instagrams}
                  onChange={(v, option) => {
                    state.labels.instagram_name = option.label
                    handlVerify(
                      'instagram',
                      v,
                      'instagramMessage',
                      'instagramFeedback'
                    )
                  }}
                  placeholder="Please select"
                />
              </Spin>
              {state.instagramFeedback && (
                <p className={s[state.instagramFeedback]}>
                  {state.instagramFeedback == 'success' ? (
                    <Success />
                  ) : (
                    <Error />
                  )}
                  {state.instagramMessage}
                </p>
              )}
            </div>
          </div>
          <div className={s.onboardMainItem}>
            {type == 'ecommerce' ? (
              <div className={s.selectItem}>
                <div className={s.selectLabel}>
                  <span>*</span>What should we optimize for?
                </div>
                <Spin spinning={!!state.eventLoading && !!state.fb_id}>
                  <MSelect
                    popupClassName="onboard-select"
                    name="event"
                    options={state.events}
                    disabled={!state.fb_id}
                    onChange={(v, option) => {
                      state.labels.event_name = option.label
                      handlVerify(
                        'event',
                        v,
                        'eventMessage',
                        'eventFeedback',
                        'averageVal'
                      )
                    }}
                    placeholder="Please select"
                  />
                </Spin>
                {state.eventFeedback && (
                  <p className={s[state.eventFeedback]}>
                    {state.eventFeedback == 'success' ? <Success /> : <Error />}
                    {state.eventFeedback != 'success' && state.eventMessage}
                  </p>
                )}
              </div>
            ) : lead_type == 'website' ? (
              <div className={s.selectItem}>
                <div className={s.selectLabel}>
                  <span>*</span>Please select your default pixel
                </div>
                <Spin spinning={!!state.pixelLoading && !!state.fb_id}>
                  <MSelect
                    name="pixel"
                    popupClassName="onboard-select"
                    options={state.pixels}
                    disabled={!state?.fb_id}
                    onChange={(v, option) => {
                      state.labels.pixel_name = option.label
                      handlVerify('pixel', v, 'pixelMessage', 'pixelFeedback')
                    }}
                    placeholder="Please select"
                  />
                </Spin>
                {state.pixelFeedback && (
                  <p className={s[state.pixelFeedback]}>
                    {state.pixelFeedback == 'success' ? <Success /> : <Error />}
                    {state.pixelMessage}
                  </p>
                )}
              </div>
            ) : (
              <div className={s.selectItem}>
                <div className={s.selectLabel}>
                  <span>*</span>Please select your default lead form
                </div>
                <Spin spinning={!!state.eventLoading && !!state.fb_id}>
                  <MSelect
                    popupClassName="onboard-select"
                    name="leadform"
                    hasFeedback
                    disabled={!state?.fb_id}
                    options={state.leadforms}
                    onChange={(v, option) => {
                      state.labels.leadform_name = option.label
                      handlVerify(
                        'leadform',
                        v,
                        'leadformMessage',
                        'leadformFeedback'
                      )
                    }}
                    placeholder="Please select"
                  />
                </Spin>
                {state.leadformFeedback && (
                  <p className={s[state.leadformFeedback]}>
                    {state.leadformFeedback == 'success' ? (
                      <Success />
                    ) : (
                      <Error />
                    )}

                    {state.leadformMessage}
                  </p>
                )}
              </div>
            )}

            <Form.Item label=" ">
              {state.eventFeedback == 'success' && void 1 != event && (
                <div className={s.average}>
                  <img src={average} />
                  <div className={s.averageTxt}>
                    Your 90 days average is <span>{state.averageVal}</span>
                  </div>
                </div>
              )}
            </Form.Item>
          </div>
          {lead_type == 'website' && (
            <div className={s.onboardMainItem}>
              <div className={s.selectItem}>
                <div className={s.selectLabel}>
                  <span>*</span>What should we optimize for?
                </div>
                <Spin spinning={!!state.eventLoading && !!state.fb_id}>
                  <MSelect
                    popupClassName="onboard-select"
                    name="event"
                    disabled={!state.fb_id}
                    options={state.events}
                    onChange={(v, option) => {
                      state.labels.event_name = option.label
                      handlVerify(
                        'event',
                        v,
                        'eventMessage',
                        'eventFeedback',
                        'averageVal'
                      )
                    }}
                    placeholder="Please select"
                  />
                </Spin>
                {state.eventFeedback && (
                  <p className={s[state.eventFeedback]}>
                    {state.eventFeedback == 'success' ? <Success /> : <Error />}
                    {state.eventFeedback != 'success' && state.eventMessage}
                  </p>
                )}
              </div>
              <div className={s.selectItem}>
                <Form.Item label=" " />
              </div>
            </div>
          )}
          <Button
            type="primary"
            disabled={isDisabled}
            htmlType="submit"
            className={cls({ [s.disabled]: isDisabled })}
            loading={state.load}
          >
            Continue
          </Button>
          {isPop && (
            <Button
              type="primary"
              className={s.cancel}
              onClick={() => _C.onCancel()}
            >
              Cancel
            </Button>
          )}
        </Form>
      </Spin>
    </div>
  )
}

const LittleHelp = () => {
  const _C = React.useContext(C)
  const { setStep } = _C
  const { data } = useRequest(async () => (await api.fetchOnboardGoal()).data)

  return (
    <div className={s.help}>
      <div className={s.helpMain}>
        <span className={s.cpr}>{data?.cpr_last_year}</span>
        <span className={s.spend}>{data?.spend_last_year}</span>

        <img src={help} />
        <div className={s.helpMainTxt}>
          <div>
            Our goal: <span>{data?.improvement}</span> improvement after
            <span>{data?.conversion}</span>
            conversions
          </div>
          <div className={s.helpBtn} onClick={() => setStep(4)}>
            Continue
          </div>
        </div>
      </div>
    </div>
  )
}

const Finsh = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [percent, setPercent] = useState(0)
  const { is_onboarded } = useSelector(state => state.user.current)
  const count = React.useRef(0)

  useRequest(api.fetchOnboardComplete)

  useInterval(async () => {
    if (percent < 99) setPercent(count.current)
    const { id, is_onboarding, ...business } = (await api.fetchBusiness()).data
    if (count.current++ > 70 || !is_onboarding) {
      setPercent(100)
      setTimeout(() => history.replace('/start?from=start'), 1000)
    }
    if (!is_onboarded) {
      dispatch(modifyUser(business))
    }
  }, 2500)

  return (
    <div className={s.finsh}>
      <img src={finsh} />
      <div className={s.desc}>
        We are preparing your dashboard. This may take 2-3 minutes.
      </div>
      <Progress percent={percent} />
    </div>
  )
}
export default OnBoard
