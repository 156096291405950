import { useState, useMemo } from 'react'
import { Spin, Button, Select } from 'antd'
import { useBoolean, useDynamicList, useLockFn } from 'ahooks'
import cls from 'classnames'
import Item from '@/pages/v3-start/plan-item/index.js'
import * as api from '@/service/campaign'
import debounce from 'lodash/debounce'
import s from './index.module.scss'
import S from '../index.module.scss'
import ImportAdd from 'ico/start/import-add.svg'

export default ({ onOk, children }) => {
  const [fetching, { toggle: toggleFetch }] = useBoolean()
  const [submit, { toggle: toggleSubmit }] = useBoolean()
  const [val, setVal] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [asyncs, setAsyncs] = useState([])
  const [open, { toggle }] = useBoolean()
  const [campaigns, setCampaigns] = useState([])
  const { list, push } = useDynamicList()

  const handleSearch = async () => {
    toggleFetch(true)
    const { data } = await api
      .fetchCampaignSearch({
        type: 'campaign',
        keyword,
      })
      .finally(() => toggleFetch(false))
    !!data.length && setCampaigns(data)
    toggle(true)
  }

  const getSyncedReady = ({ item, status }) => {
    const _ = asyncs.find(v => v.id == item.id)
    if (status && !_) {
      return setAsyncs([...asyncs, { id: item.id, name: item.name }])
    }
    !status && setAsyncs(asyncs.filter(v => v.id != item.id))
  }

  const handleSubmit = useLockFn(async () => {
    toggleSubmit(true)
    const { data } = await api
      .fetchSyncedSubmit({
        ready: asyncs,
      })
      .finally(() => toggleSubmit())
    if (data?.success ?? data?.result) onOk()
  })

  const handleUnique = (v, { item }) => {
    const _ = list.find(v => v.id == item.id)
    if (!_) {
      push(item)
      setAsyncs([...asyncs, item])
    }
    setVal(item.name)
    toggle(false)
  }

  return (
    <div className={cls(s.import, S.modal)}>
      {children}

      <div className={s.label}>
        <span>*</span>Choose campaign to import
      </div>

      <div className={s.select}>
        <Select
          filterOption={false}
          value={val}
          onSearch={v => setKeyword(v)}
          popupClassName={s.selectPopup}
          // notFoundContent={fetching ? <Spin size="small" /> : null}
          placeholder="Enter a keyword to start searching..."
          showSearch
          showArrow={false}
          open={open}
          onChange={handleUnique}
          style={{
            width: '100%',
          }}
          onBlur={() => open && toggle(false)}
          onKeyDown={e => e.keyCode === 13 && handleSearch()}
          optionLabelProp="label"
        >
          {campaigns.map((v, k) => (
            <Select.Option
              key={v.id}
              item={v}
              label={v.name}
              disabled={v.performance != 1}
            >
              <div className={s.name}>
                <span>{v.name}</span>
                <span
                  className={cls({
                    [s.active]: v.status == 'active',
                    [s.pause]: v.status == 'pause',
                    [s.poor]: v.status == 'active' && !v.performance,
                  })}
                >
                  {!v.performance && v.status == 'active'
                    ? 'Poor performance'
                    : v.status}
                </span>
              </div>
              {v.status == 'active' && v.performance == 1 && (
                <div className={s.add}>
                  <span>Add to queue</span>
                  <ImportAdd />
                </div>
              )}
            </Select.Option>
          ))}
          {/**/}
        </Select>
        <Button type="primary" loading={fetching} onClick={handleSearch}>
          Search
        </Button>
      </div>

      {!!list.length && (
        <>
          <section className={S.listHeader}>
            <header>
              <span>Theme</span>
              <span>Type</span>
              <span>Performance</span>
              <span>Suggest</span>
            </header>
          </section>
          <section className={S.listWarp}>
            {list.map((v, k) => (
              <Item key={k} {...v} onChange={getSyncedReady} />
            ))}
          </section>
        </>
      )}
      <Button
        type="primary"
        shape="round"
        loading={submit}
        className={s.confirm}
        disabled={!list.length}
        onClick={handleSubmit}
      >
        confirm
      </Button>
    </div>
  )
}
