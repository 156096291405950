import React, { useCallback, useEffect, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useReactive, useRequest } from 'ahooks'
import { useSelector } from 'react-redux'
import { CContext as C } from '@/context'
import { Select, Switch, Button, Modal } from 'antd'
import cls from 'classnames'
import * as api from '@/service/chat'
import Icons from '../../icons'
import * as _ from '@/utils'
import { isEmpty, countBy } from 'lodash'
import { useSubscribe } from '@/subscribe'
import autoAnimate from '@formkit/auto-animate'

import ps from '../../index.module.scss'
import styles from './index.module.scss'
import On from 'ico/v3-campaigns/on.svg'
import Pause from 'ico/v3-campaigns/pause.svg'

const s = styles

const Platform = {
  Google: 'google',
  Meta: 'meta',
}

export default () => {
  const { currency_symbol, meta, google } = useSelector(
    state => state.user.current
  )
  const { ev } = useContext(C)
  const state = useReactive({
    google: null,
    meta: null,
  })
  const isGoogleConnected = !isEmpty(google)
  const isMetaConnected = !isEmpty(meta)
  const [platform, setPlatform] = useState(() => {
    return isGoogleConnected ? Platform.Google : Platform.Meta
  })
  const info = state[platform]
  const [container, setContainer] = useState(null)

  useEffect(() => setContainer(document.querySelector(`.${ps.chat}`)), [])

  const { loading, run } = useRequest(async () => {
    await Promise.all(
      [
        ['google', isGoogleConnected],
        ['meta', isMetaConnected],
      ].map(async ([platform, connected]) => {
        if (!connected) return
        return Promise.all([
          api.fetchOverview(platform),
          api.fetchStageList(platform),
        ]).then(async ([overview, stages]) => {
          const items = await Promise.all(
            stages.map(({ stage_id }) => api.fetchRecommendation(stage_id))
          )
          stages = stages.map((stage, idx) => ({
            ...stage,
            recommendations: items[idx],
            get usable_recommendations() {
              return this.recommendations.filter(item => !item.dismissed)
            },
          }))
          state[platform] = { overview, stages }
        })
      })
    )
  })

  useSubscribe(({ type }) => {
    if (type !== 'campaigns_fetching') run()
  })

  const countRecommendation = stage =>
    countBy(stage.recommendations, item =>
      item.dismissed ? 'dismissed' : item.confirmed ? 'confirmed' : 'pending'
    )

  const getFunc = func => {
    switch (func) {
      case 'update':
        return 'Scale'
      case 'delete':
        return 'Pause'
      case 'create':
        return 'Launch'
      case 'revive':
        return 'Revive'
    }
  }

  const getSummary = v => {
    switch (v.action) {
      case 'update':
        return v.new_budget > v.old_budget
          ? `Increase budget from ${currency_symbol}${v.old_budget} to ${currency_symbol}${v.new_budget}`
          : `Decrease budget from ${currency_symbol}${v.old_budget} to ${currency_symbol}${v.new_budget}`
      case 'delete':
        return `Pause campaign with budget ${currency_symbol}${v.old_budget}`
      case 'create':
        return `Launch campaign with budget ${currency_symbol}${v.new_budget}`
      case 'revive':
        return `Revive campaign with budget $${v.new_budget}`
    }
  }

  const switchStageStatus = (stage, checked) => {
    stage.status = +checked
    const reset = async () => (stage.status = checked ? 0 : 1)
    const update = async () => {
      stage.statusUpdating = true
      await api
        .updateStageStatus(stage.stage_id, checked)
        .catch(err => {
          reset()
          return Promise.reject(err)
        })
        .finally(() => (stage.statusUpdating = false))
    }

    if (checked) return update()
    Modal.confirm({
      className: cls(s.modal2, s.modal2danger),
      icon: null,
      title: 'Please confirm',
      content: (
        <div className={s.modal2content}>
          <div className={s.status}>
            <div className={s.statusIcon}>
              <Pause />
            </div>
            <p className={s.statusText}>Paused</p>
          </div>
          <div className={s.message}>
            This will move all of your campaigns in this stage to Unassigned
            immediately.
          </div>
        </div>
      ),
      centered: true,
      okText: 'Confirm',
      onOk: update,
      onCancel: reset,
    })
  }

  const dismissRecommendation = async item => {
    item.dismissing = true
    await api
      .dismissRecommendation(item.recommendation_id)
      .finally(() => (item.dismissing = false))
    item.dismissed = true
  }

  const confirmRecommendation = async item => {
    item.loading = true
    try {
      await api.confirmRecommendation(item.recommendation_id)
      item.confirmed = true
    } catch (e) {
      item.error = true
    }
    item.loading = false
  }

  return (
    <>
      {loading && container
        ? createPortal(
            <div className={styles.strategistLoad}>
              <div className={styles.cont}>
                <div className={styles.text}>
                  <Icons.Chat />
                  <span>Optimizing...This may take several minutes.</span>
                </div>
              </div>
            </div>,
            container
          )
        : null}
      {info ? (
        <div className={styles.strategist}>
          <div className={styles.tabs}>
            <div
              className={cls(styles.platform, {
                [styles.platformDisbaled]: !isGoogleConnected,
                [styles.active]: platform === Platform.Google,
              })}
              onClick={() => isGoogleConnected && setPlatform(Platform.Google)}
            >
              <Icons.Checked className={styles.check} />
              <Icons.NewGoogle2 className={styles.logo} />
              <b className={styles.name}>GOOGLE ADS</b>
              <div className={styles.budget}>
                <p>
                  Recommended budget:{' '}
                  {state.google ? (
                    <b>
                      {_.currencyFormat(state.google.overview.budget.suggested)}
                    </b>
                  ) : (
                    <b>--</b>
                  )}
                </p>
                <p>
                  Current budget:{' '}
                  {state.google ? (
                    <b>{_.currencyFormat(state.google.overview.budget.curr)}</b>
                  ) : (
                    <b>--</b>
                  )}
                </p>
              </div>
            </div>
            <div
              className={cls(styles.platform, {
                [styles.platformDisbaled]: !isMetaConnected,
                [styles.active]: platform === Platform.Meta,
              })}
              onClick={() => isMetaConnected && setPlatform(Platform.Meta)}
            >
              <Icons.Checked className={styles.check} />
              <Icons.NewMeta2 className={styles.logo} />
              <b className={styles.name}>META ADS</b>
              <div className={styles.budget}>
                <p>
                  Recommended budget:{' '}
                  {state.meta ? (
                    <b>
                      {_.currencyFormat(state.meta.overview.budget.suggested)}
                    </b>
                  ) : (
                    <b>--</b>
                  )}
                </p>
                <p>
                  Current budget:{' '}
                  {state.meta ? (
                    <b>{_.currencyFormat(state.meta.overview.budget.curr)}</b>
                  ) : (
                    <b>--</b>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.warp}>
            <div className={cls(styles.box, styles.pm)}>
              <div className={cls(styles.overview, styles.main)}>
                <div className={styles.overviewHeader}>
                  <span>Performance Overview</span>
                  {/* <span>As of {info?.datetime}</span> */}
                </div>
                <div className={styles.overviewData}>
                  <div className={styles.dl}>
                    <div className={styles.item}>
                      <em>
                        <Icons.Spend className={styles.spend} />
                      </em>
                      <span>Spend:</span>
                      <b>
                        {currency_symbol}
                        {info.overview.spend}
                      </b>
                      {/* <span
                        className={cls({
                          [styles.up]: info.overview.spend_trend > 0,
                          [styles.down]: info.overview.spend_trend < 0,
                          [styles.nchanged]: !info.overview.spend_trend,
                        })}
                      >
                        {info.overview.spend_trend > 0 && <Icons.Up />}
                        {info.overview.spend_trend < 0 && <Icons.Down />}
                        {!info.overview.spend_trend && <Icons.Unchanged />}
                        {!info.overview.spend_trend
                          ? 'Unchanged'
                          : `${info.overview.spend_trend}%`}
                      </span> */}
                    </div>
                    <div className={styles.item}>
                      <em>
                        <Icons.Purchases className={styles.purchases} />
                      </em>
                      <span>Purchases:</span>
                      <b>{info.overview.result}</b>
                      {/* <span
                        className={cls({
                          [styles.up]: info.overview.result_trend > 0,
                          [styles.down]: info.overview.result_trend < 0,
                          [styles.nchanged]: !info.overview.result_trend,
                        })}
                      >
                        {info.overview.result_trend > 0 && <Icons.Up />}
                        {info.overview.result_trend < 0 && <Icons.Down />}
                        {!info.overview.result_trend && <Icons.Unchanged />}
                        {!info.overview.result_trend
                          ? 'Unchanged'
                          : `${info.overview.result_trend}%`}
                      </span> */}
                    </div>
                    <div className={styles.item}>
                      <em>
                        <Icons.Cpr className={styles.cpr} />
                      </em>
                      <span>Average CPR:</span>
                      <b>
                        {currency_symbol}
                        {info.overview.cpr}
                      </b>
                      {/* <span
                        className={cls({
                          [styles.up]: info.overview.cpr_trend > 0,
                          [styles.down]: info.overview.cpr_trend < 0,
                          [styles.nchanged]: !info.overview.cpr_trend,
                        })}
                      >
                        {info.overview.cpr_trend > 0 && <Icons.Up />}
                        {info.overview.cpr_trend < 0 && <Icons.Down />}
                        {!info.overview.cpr_trend && <Icons.Unchanged />}
                        {!info.overview.cpr_trend
                          ? 'Unchanged'
                          : `${info.overview.cpr_trend}%`}
                      </span> */}
                    </div>
                  </div>
                  <div className={styles.dr}>
                    <div className={styles.status}>
                      <div>
                        <b>{info.overview.winning}</b>
                        {/* <div>
                          {info.overview.winning_trend > 0 && <Icons.Up />}
                          {info.overview.winning_trend < 0 && <Icons.Down />}
                          <span>{info.overview.winning_trend}</span>
                        </div> */}
                      </div>
                      <div>Winners</div>
                    </div>
                    <div className={styles.status}>
                      <div>
                        <b>{info.overview.testing}</b>
                        {/* <div>
                          {info.overview.testing_trend > 0 && <Icons.Up />}
                          {info.overview.testing_trend < 0 && <Icons.Down />}
                          <span>{info.overview.testing_trend}</span>
                        </div> */}
                      </div>
                      <div>Testing</div>
                    </div>
                    <div className={styles.status}>
                      <div>
                        <b>{info.overview.losing}</b>
                        {/* <div>
                          {info.overview.losing_trend > 0 && <Icons.Up />}
                          {info.overview.losing_trend < 0 && <Icons.Down />}
                          <span>
                            {info.overview.losing_trend.toString().slice(1)}
                          </span>
                        </div> */}
                      </div>
                      <div>Losing</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {info.stages.map(stage => (
              <div key={stage.stage_id} className={styles.stage}>
                <div className={cls(styles.awareness, styles.actionHeader)}>
                  <div className={styles.awarenessL}>
                    <b>{stage.name}</b>
                    <Switch
                      loading={stage.statusUpdating}
                      checked={stage.status === 1}
                      onChange={val => switchStageStatus(stage, val)}
                    />
                  </div>
                  <div className={styles.tab}>
                    <span>
                      Pending
                      <em>{countRecommendation(stage).pending || 0}</em>
                    </span>
                    <span>
                      Confirmed
                      <em>{countRecommendation(stage).confirmed || 0}</em>
                    </span>
                    <span>
                      Dismissed
                      <em>{countRecommendation(stage).dismissed || 0}</em>
                    </span>
                  </div>

                  <div className={styles.awarenessR}>
                    <div>Current budget: {_.currencyFormat(stage.budget)}</div>
                    <div>
                      Recommended budget:{' '}
                      {_.currencyFormat(stage.recommended_budget)}
                    </div>
                  </div>
                </div>

                <div className={cls(styles.ations, styles.pm, styles.nav)}>
                  <b>
                    {stage.usable_recommendations.length
                      ? `We have ${
                          stage.usable_recommendations.length
                        } new recommendation${
                          stage.usable_recommendations.length > 1 ? 's' : ''
                        } for you`
                      : 'There are no recommendations'}
                  </b>
                </div>

                {!!stage.usable_recommendations.length && (
                  <div className={cls(styles.mlist)}>
                    <ul
                      ref={el => el && autoAnimate(el)}
                      className={styles.campaignList}
                    >
                      {stage.usable_recommendations.map((v, k) => (
                        <li className={styles.li} key={v.recommendation_id}>
                          {v.action != 'delete' &&
                            !v.confirmed &&
                            (v.dismissing ? (
                              <img
                                className={styles.loader}
                                src={Icons.Chatloading2}
                              />
                            ) : (
                              <Icons.Remove
                                onClick={() => dismissRecommendation(v)}
                              />
                            ))}
                          <div className={styles.mheader}>
                            <div className={styles.name}>
                              <span
                                className={cls(
                                  getFunc(v.action) == 'Scale'
                                    ? v.new_budget > v.old_budget
                                      ? [styles.up]
                                      : [styles.down]
                                    : '',
                                  {
                                    [styles.launch]:
                                      getFunc(v.action) == 'Launch',
                                    [styles.revive]:
                                      getFunc(v.action) == 'Revive',
                                    [styles.pause]:
                                      getFunc(v.action) == 'Pause',
                                  }
                                )}
                              >
                                {getFunc(v.action)}
                                {getFunc(v.action) == 'Scale' ? (
                                  v.new_budget > v.old_budget ? (
                                    <Icons.Up />
                                  ) : (
                                    <Icons.Down />
                                  )
                                ) : null}
                                {getFunc(v.action) == 'Launch' && (
                                  <Icons.Launch />
                                )}
                                {getFunc(v.action) == 'Pause' && (
                                  <Icons.Pause />
                                )}
                                {getFunc(v.action) == 'Revive' && (
                                  <Icons.Revive />
                                )}
                              </span>
                              <span>{v.name || v.description}</span>
                              {v.platform == 'google' ? (
                                <Icons.Google className={styles.platform} />
                              ) : (
                                <Icons.Meta className={styles.platform} />
                              )}
                              {/* <b>W</b> */}
                            </div>

                            {v.error ? (
                              <div className={styles.error}>
                                <Icons.Error />
                                <span>Error</span>
                              </div>
                            ) : v.confirmed ? (
                              <Button type="primary" disabled>
                                Confirmed
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                loading={v.loading}
                                onClick={() => confirmRecommendation(v)}
                              >
                                Confirm
                              </Button>
                            )}
                          </div>
                          <div className={styles.desc}>
                            <div>
                              {getSummary(v)}
                              <Icons.Subtract
                                className={cls(
                                  styles.arrow,
                                  !v.hideDesc && styles.active
                                )}
                                onClick={() => (v.hideDesc = !v.hideDesc)}
                              />
                            </div>
                          </div>
                          {!v.hideDesc && (
                            <div className={styles.text}>
                              <div className={styles.textC}>
                                {v.description}
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <Button
                  type="primary"
                  className={styles.createBtn}
                  disabled={stage.status !== 1}
                  onClick={() =>
                    ev.emit('precreate', { stage_type: stage.type, platform })
                  }
                >
                  Create {stage.name.toLowerCase()} campaigns
                </Button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  )
}
