import _axios from './request'

import {
  facebookInfo,
  profileEvent,
  profileIns,
  onboardAds,
  onboardMetrics,
  onboardSubmit,
  onboardChecklistValidate,
  onboardDashboardOverview,
  onboardConnectFacebook,
  onboardAdsList,
  onboardProgress,
  ping,
  onboardSave,
  onboardBudget,
  onboardStepLoad,
  onboardStepSave,
} from './url'

const { REACT_APP_TIMEOUT } = process.env

/**
 * @param {*} null
 * @returns Promise
 */
export function fetchFcInfo(params) {
  return _axios({
    url: facebookInfo,
    params,
    timeout: REACT_APP_TIMEOUT,
  })
}

/**
 * @param {*} null
 * @returns Promise
 */
export function fetchEvent(params) {
  return _axios({
    url: profileEvent,
    params,
    timeout: REACT_APP_TIMEOUT,
  })
}

/**
 * @param {*} page_id
 * @returns Promise
 */
export function fetchInsInfo(params) {
  return _axios({
    url: profileIns,
    params,
    timeout: REACT_APP_TIMEOUT,
  })
}

/**
 * @param {*} account_id
 * @returns Promise
 */
export function fetchMetrics(params) {
  return _axios({
    url: onboardMetrics,
    params,
  })
}

/**
 * @param {*} account_id
 * @returns Promise
 */
export function fetchSubmit(params) {
  return _axios({
    url: onboardSubmit,
    params,
  })
}

/**
 * @param {*} account_id
 * @returns Promise
 */
export function fetchCheck(params) {
  return _axios({
    url: onboardChecklistValidate,
    params,
  })
}

/**
 *
 * @param {*} null
 * @returns Promise
 */
export function fetchOverview(params) {
  return _axios({
    url: onboardDashboardOverview,
    params,
  })
}

/**
 *ads Pagination settings
 * @param {
 * page_size: data per page
 * current_page: current page number
 * account_id
 * }
 * @returns Promise
 */
export function fetchAds(params) {
  return _axios({
    url: onboardAds,
    params,
  })
}

export function doPing(params) {
  return _axios({
    url: ping,
    params,
  })
}

export function fetchConnect(params) {
  return _axios({
    url: onboardConnectFacebook,
    params,
  })
}

export function fetchOnboardSave(data) {
  return _axios({
    url: onboardSave,
    method: 'post',
    data,
  })
}

export function fetchOnboardAdsList(params) {
  return _axios({
    url: onboardAdsList,
    params,
  })
}

export function fetchOnboardProgress(params) {
  return _axios({
    url: onboardProgress,
    params,
  })
}

export function fetchOnboardBudget(params) {
  return _axios({
    url: onboardBudget,
    params,
  })
}

export function fetchOnboardStepLoad(params) {
  return _axios({
    url: onboardStepLoad,
    cancelable: true,
    params,
  })
}

export function fetchOnboardStepSave(data) {
  return _axios({
    url: onboardStepSave,
    cancelable: true,
    method: 'post',
    data,
  })
}
