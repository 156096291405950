import { useState, useMemo, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { Button, Modal, Spin, Input } from 'antd'
import {
  useDrop,
  useDynamicList,
  useRequest,
  useUpdateEffect,
  useLatest,
  useBoolean,
} from 'ahooks'
import * as MC from './components/modals'
import { Assets } from '@/components/start/modal'
import { transformUrl, useUploadList } from '@/utils/uploader'
import * as api from '@/service/assets'
import { updateGroups } from '@/store/actions'
import { IMGS_AVAILABLE_FOR_GOOGLE } from '@/utils/constant'
import { Message } from '@/utils'

import cls from 'classnames'
import s from './index.module.scss'
import Plus from 'ico/v3-media/plus.svg'
import Folder from 'ico/v3-media/folder.svg'
import Folder2 from 'ico/v3-media/folder2.svg'
import Play from 'ico/v3-media/play.svg'
import Trashcan from 'ico/v3-media/trashcan.svg'
import Link from 'ico/v3-media/link.svg'
import Brand from 'ico/v3-media/brand.svg'
import Check from 'ico/v3-media/check.svg'
import Clock from 'ico/ads/time.svg'
import Back from 'ico/v4-onboard/back.svg'
import Youtube from 'ico/youtube.svg'
import Meta from 'ico/meta.svg'
import Google from 'ico/dashboard/google.svg'

const noop = () => {}

export const matchResolution = (
  resolution,
  accept = IMGS_AVAILABLE_FOR_GOOGLE
) => {
  if (!resolution) return false
  // resolution: width*height
  if (resolution.includes('*')) {
    const [width, height] = resolution.split('*')
    const ratio = +(width / height).toFixed(2)
    return accept.find(item => item.aspect_ratio === ratio)
  }
  // resolution: 'market' | 'square' | 'portrait' | 'landscape' | 'other'
  return accept.find(item => item.type === resolution)
}

export const isAssetAvailable = (asset, accept = IMGS_AVAILABLE_FOR_GOOGLE) => {
  const matched = matchResolution(asset.resolution, accept)
  if (!matched) return false
  return (
    !matched.min_resolution ||
    !asset.resolution.includes('*') ||
    asset.resolution.split('*')[0] >= matched.min_resolution[0]
  )
}

const selector = createSelector(
  state => state.user.current.asset_suggested,
  state => state.user.current.current_campaign_group_id,
  state => state.user.shared.campaignGroups,
  (suggestedNum, id, list) => ({
    suggestedNum,
    groupId: id,
    groupName: list.find(item => item.id === id)?.name,
  })
)

export default function MediaLibrary({
  platform,
  mode = 'normal',
  type,
  accept,
  defaultSelected = [],
  onCancel = noop,
  onOk = noop,
  effect = noop,
  okButtonLoading,
  multiple = true,
  readonly = false,
}) {
  type = platform === 'google' ? 'image' : type
  const isModal = mode === 'modal'
  const isEmbeded = mode === 'embeded'
  const dispatch = useDispatch()
  const { suggestedNum, groupId, groupName } = useSelector(selector)
  const [modalType, setModalType] = useState(isEmbeded ? 'assets' : '')
  const {
    list: selected,
    push: pushSelected,
    remove: removeSelected,
    resetList: resetSelected,
  } = useDynamicList()
  const latest = useLatest({ groupId })

  useEffect(() => {
    !isModal && !groupName && dispatch(updateGroups())
  }, [])

  const {
    data: assets = [],
    runAsync: fetchAssets,
    loading,
  } = useRequest(
    async () => {
      const { data } = await api.fetchAssetList(groupId, platform)
      resetUploader()
      return data
    },
    { refreshDeps: [groupId] }
  )

  const { uploader, resetUploader, list, remove } = useUploadList({
    type,
    defaultFileList: assets,
    reverse: true,
    async afterUpload({ url, uuid, resolution, type }) {
      const { data } = await api.createAsset({
        folder_id: latest.current.groupId,
        url,
        uuid,
        resolution,
      })
      const { result, image_url, ...rest } = data
      if (type === 'video') url = image_url
      return { url, resolution, ...rest }
    },
  })
  const usableNum = useMemo(
    () => list.filter(item => !item.status || item.status === 'success').length,
    [list]
  )

  const dropEl = useRef()
  useDrop(dropEl, {
    onFiles(files) {
      uploader.upload(files)
    },
  })

  const deleteAsset = async item => {
    if (item.id) await api.deleteAsset(item.id)
    remove(item)
    selected.includes(item) && toggleSelect(item)
  }

  const toggleSelect = item => {
    if (isReadonly(item) || disableSelect(item)) return
    const idx = selected.indexOf(item)
    if (!multiple) {
      idx === -1 && resetSelected([item])
    } else {
      idx !== -1 ? removeSelected(idx) : pushSelected(item)
    }
  }

  const isDefaultSelected = item =>
    defaultSelected.some(v => v.url === item.url)

  const isReadonly = item =>
    readonly ||
    !isModal ||
    isDefaultSelected(item) ||
    (item.status && item.status !== 'success')

  const disableSelect = item =>
    isModal &&
    (!item.status || item.status === 'success') &&
    ((type && item.type !== type) ||
      (accept && !isAssetAvailable(item, accept)))

  const { run: connectAssets, loading: connecting } = useRequest(
    async () => {
      await api.confirmAssets()
      onOk()
      // dispatch(modifyUser({ is_connect_assets: true }))
    },
    { manual: true }
  )

  const containerRef = useRef()
  useEffect(() => effect(containerRef.current), [])

  const [input, { toggle: toggleInput }] = useBoolean(false)
  const inputRef = useRef()
  const link = useRef('')
  const tid = useRef()
  const { run: importYoutube, loading: youtubeLoading } = useRequest(
    async () => {
      clearTimeout(tid.current)
      inputRef.current.focus()
      if (!link.current.startsWith('https://www.youtube.com/watch?v=')) {
        return Message.warn(
          'Link must starts width https://www.youtube.com/watch?v='
        )
      }
      await api.importYoutube(link.current)
      toggleInput()
      fetchAssets()
    },
    { manual: true }
  )

  return (
    <>
      <div ref={containerRef} className={s[mode]}>
        {isModal && (
          <div className={s.modalHeader}>
            Create Assistant
            <span>{'>'} Media Library</span>
          </div>
        )}
        <div className={cls(s.wrap)}>
          {!isModal && (
            <div className={s.left}>
              <div className={cls(s.folders, 'nice-scrollbar')}>
                <div
                  className={cls(s.folder, {
                    [s.checked]: true,
                  })}
                >
                  <Brand />
                  <span className="ellipsis">{groupName}</span>
                  <Check className={s.check} />
                </div>
              </div>
            </div>
          )}
          <div className={s.main}>
            {loading && <Spin className={s.spin} spinning size="large" />}
            {true && (
              <>
                <div className={s.header}>
                  <span className={s.title}>Media Library</span>
                  {/* {!input ? (
                    <span className={s.youtube} onClick={toggleInput}>
                      <Youtube />
                    </span>
                  ) : (
                    <div className={s.inputWrap}>
                      <Input
                        ref={inputRef}
                        autoFocus
                        onChange={e => (link.current = e.target.value)}
                        onFocus={() => (link.current = '')}
                        onBlur={() =>
                          (tid.current = setTimeout(toggleInput, 300))
                        }
                        onPressEnter={importYoutube}
                      />
                      <Button
                        type="primary"
                        loading={youtubeLoading}
                        onClick={importYoutube}
                      >
                        Confirm
                      </Button>
                    </div>
                  )} */}
                </div>
                <div ref={dropEl} className={cls(s.medias, 'nice-scrollbar')}>
                  <div className={s.media}>
                    <div
                      className={cls(s.mediaCard, s.upload)}
                      onClick={() => uploader.chooseFile()}
                    >
                      <span className={cls(s.plus, s.lg)}>
                        <Plus />
                      </span>
                    </div>
                  </div>
                  {list.map(item => (
                    <div
                      key={item.id || item.uid}
                      className={cls(s.media, {
                        [s.error]: item.status === 'error',
                        [s.disabled]: disableSelect(item),
                      })}
                      style={isReadonly(item) ? { cursor: 'default' } : null}
                      onClick={() => toggleSelect(item)}
                    >
                      <div className={s.mediaCard}>
                        {(selected.includes(item) ||
                          isDefaultSelected(item)) && (
                          <Check
                            className={cls(
                              s.check,
                              isDefaultSelected(item) && s.checkDisabled
                            )}
                          />
                        )}
                        {item.status === 'uploading' ? (
                          <Spin size="large">
                            <div className={s.inner} />
                          </Spin>
                        ) : (
                          <div className={s.inner}>
                            {!!item.url && (
                              <img
                                className={s.thumbnail}
                                src={transformUrl(item.url)}
                              />
                            )}
                            <div className={s.platforms}>
                              {item.is_meta && (
                                <span className={s.platform}>
                                  <Meta />
                                </span>
                              )}
                              {item.is_google && (
                                <span className={s.platform}>
                                  <Google />
                                </span>
                              )}
                              {item.is_youtube && (
                                <span className={s.platform}>
                                  <Youtube />
                                </span>
                              )}
                            </div>
                            {item.status === 'waiting' ? (
                              <Clock className={s.clock} />
                            ) : (
                              item.type === 'video' && (
                                <Play className={s.play} />
                              )
                            )}
                            {!!item.resolution && (
                              <span className={cls(s.resolution)}>
                                <Check className="fill-color" />
                                <span className="ellipsis">
                                  {item.resolution}
                                </span>
                              </span>
                            )}
                            {(!item.status ||
                              ['success', 'error'].includes(item.status)) && (
                              <Trashcan
                                className={s.trashcan}
                                onClick={e => {
                                  e.stopPropagation()
                                  deleteAsset(item)
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <div className={cls(s.mediaName, 'ellipsis')}>
                        {item.name}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {isEmbeded && (
              <div className={s.fixed}>
                <span className={s.back} onClick={onCancel}>
                  <Back />
                  Back
                </span>
                <div className={s.tip}>
                  {/* <img className={s.img} src={bear} /> */}
                  <span className={s.text}>
                    Total Assets: <strong>{usableNum}</strong>
                  </span>
                  <span className={s.line} />
                  <span className={s.text} style={{ color: '#3DD994' }}>
                    Suggested: <strong>{suggestedNum}</strong>
                  </span>
                </div>
                <Button
                  className={s.btn}
                  type="primary"
                  disabled={!usableNum}
                  loading={connecting}
                  onClick={connectAssets}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
        {isModal && (
          <div className={s.modalFooter}>
            {/* <Button onClick={onCancel}>Back</Button> */}
            <Button
              type="primary"
              loading={okButtonLoading}
              onClick={async () => {
                if (!selected.length) return onCancel()
                await onOk(selected)
                resetSelected([])
              }}
            >
              Confirm
            </Button>
          </div>
        )}
      </div>

      <Modal
        className="custom-modal"
        open={!!modalType}
        centered
        destroyOnClose
        footer={null}
        closable={false}
        onCancel={() => modalType === 'folder' && setModalType('')}
      >
        {modalType === 'assets' && (
          <div style={{ padding: 16 }}>
            <Assets
              onCancel={() => setModalType('')}
              onOk={assets => {
                setModalType('')
                fetchAssets()
              }}
            />
          </div>
        )}
      </Modal>
    </>
  )
}

export function MediaPicker({
  children,
  platform,
  type,
  accept,
  defaultSelected,
  multiple,
  readonly,
  onOk = () => {},
}) {
  const [open, { toggle }] = useBoolean(false)
  const [loading, setLoading] = useState(false)

  const onChooseMedia = async medias => {
    medias = medias.map(({ verified, id, url, type, resolution, ...rest }) => {
      if (platform) verified = rest[`is_${platform}`]
      if (platform === 'google') {
        type = matchResolution(resolution)?.type || 'other'
      }
      return { verified, id, url, type, resolution }
    })
    // setLoading(true)
    // medias = await Promise.all(medias).finally(() => setLoading(false))
    onOk(medias)
    toggle()
  }

  const onCancel = () => {
    toggle()
    setLoading(false)
  }

  return (
    <>
      {children({ trigger: toggle })}

      <Modal
        className="custom-modal"
        open={open}
        centered
        footer={null}
        closable={false}
        onCancel={onCancel}
      >
        <MediaLibrary
          mode="modal"
          readonly={readonly}
          platform={platform}
          type={type}
          accept={accept}
          defaultSelected={defaultSelected}
          multiple={multiple}
          onOk={onChooseMedia}
          onCancel={onCancel}
          okButtonLoading={loading}
        />
      </Modal>
    </>
  )
}
