import {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useContext,
} from 'react'
import { useSelector } from 'react-redux'
import {
  Form,
  Select,
  Switch,
  Spin,
  Button,
  InputNumber,
  Tooltip,
  Modal,
} from 'antd'
import {
  useCreation,
  useMemoizedFn,
  useRequest,
  useAsyncEffect,
  useBoolean,
} from 'ahooks'
import shallowEqual from 'react-redux/es/utils/shallowEqual'
// import { driver } from 'driver.js'
import { isEmpty, pick, isEqualWith } from 'lodash'
import { fetchPlatformGoals } from '@/service/campaign'
import * as api from '@/service/chat'
import CurrencyInput from '@/components/currency-input'
import { MediaPicker } from '@/pages/v3-media-library'
import Settings from '../Settings'
import { useSubscribe, publish } from '@/subscribe'

import cls from 'classnames'
import s from './index.module.scss'
import Google from 'ico/dashboard/google.svg'
import Meta from 'ico/meta.svg'
import Question from 'ico/chat/question.svg'
import CircleArrow from 'ico/chat/circle-arrow.svg'
import Media from 'ico/chat/media.svg'
import Lock from 'ico/v3-campaigns/lock.svg'
import Auto from 'ico/v3-campaigns/a-auto.svg'
import Check from 'ico/v3-media/check.svg'

const noop = async () => {}
const rules = [{ required: true, message: 'Cannot be empty' }]

const Groups = {
  Budget: 1,
  Assets: 2,
}

const percentage = {
  formatter: val => val && `${val * 100}%`,
  parser: val => val.replace('%', '') / 100,
}

const useEventOpts = ({ platform, disabled }) => {
  const { data = [], loading } = useRequest(async () => {
    if (disabled) return
    const { data } = await fetchPlatformGoals(platform, {
      type: 'event',
    })
    return data
  })

  return { data, loading }
}

export default forwardRef(function SideBar(props, ref) {
  const {
    google,
    meta,
    currency_symbol,
    minimum_budget,
    minimum_group_budget,
  } = useSelector(state => state.user.current)
  const isGoogleConnected = !isEmpty(google)
  const isMetaConnected = !isEmpty(meta)
  const [setupForm] = Form.useForm()
  const [budgetForm] = Form.useForm()
  const [assetsForm] = Form.useForm()
  const [open, { toggle }] = useBoolean(false)

  const { data = {}, runAsync } = useRequest(async (snapshot = true) => {
    const { data } = await api.fetchGroupSettings()
    if (snapshot) {
      setupForm.setFieldsSnapshot?.(pick(data, ['meta', 'google']))
      budgetForm.setFieldsSnapshot?.(
        pick(data, [
          'lookback',
          'aov',
          'margins',
          'conversion_rate',
          'profit_per_lead',
          'budget',
        ])
      )
    }
    return data
  })
  const isSales = data.objective === 'sales'

  useSubscribe(({ type }) => {
    if (type !== 'campaigns_fetching') {
      runAsync()
    }
  })

  return (
    <div className={cls(s.wrap)}>
      <div className={s.group}>
        <div className={s.title}>
          Set Up
          <Tooltip title="Set Up">
            <Question />
          </Tooltip>
        </div>
        <CustomizedForm form={setupForm}>
          <Form.Item label="Platforms & Objectives" style={{ marginBottom: 0 }}>
            <div className={cls(s.flexBox, s.compact)}>
              <Form.Item name={['google', 'in_use']}>
                <CheckboxButton disabled={!isGoogleConnected}>
                  <Google />
                  <span>Google</span>
                </CheckboxButton>
              </Form.Item>
              <Form.Item
                name={['google', 'conversion_actions']}
                rules={isGoogleConnected ? rules : undefined}
              >
                <EventsSelect
                  platform="google"
                  mode="multiple"
                  disabled={!isGoogleConnected}
                  maxTagCount={0}
                  maxTagPlaceholder={omitted =>
                    `${omitted.length} Goal${
                      omitted.length > 1 ? 's' : ''
                    } Selected`
                  }
                />
              </Form.Item>
            </div>
            <div className={cls(s.flexBox, s.compact)}>
              <Form.Item name={['meta', 'in_use']}>
                <CheckboxButton disabled={!isMetaConnected}>
                  <Meta />
                  <span>Facebook</span>
                </CheckboxButton>
              </Form.Item>
              <Form.Item
                name={['meta', 'pixel_event']}
                rules={isMetaConnected ? rules : undefined}
              >
                <EventsSelect platform="meta" disabled={!isMetaConnected} />
              </Form.Item>
            </div>
          </Form.Item>
        </CustomizedForm>
      </div>
      <div className={s.group}>
        <div className={s.title}>
          Goals & Budget
          <Tooltip title="Goals & Budget">
            <Question />
          </Tooltip>
        </div>
        <CustomizedForm form={budgetForm}>
          <Form.Item label="Average CPR timeframe" name="lookback">
            <Select
              popupClassName="custom-select-dropdown sm"
              options={[
                { label: 'Lifetime', value: 'lifetime' },
                { label: 'Last 7 Days', value: 'last_7d' },
                { label: 'Last 30 Days', value: 'last_30d' },
              ]}
              name={'lookback'}
              disabled
            />
          </Form.Item>
          <div className={s.flexBox}>
            <Form.Item
              label={isSales ? 'AOV' : ' Conv. rate'}
              name={isSales ? 'aov' : 'conversion_rate'}
              rules={rules}
            >
              <CurrencyInput {...(!isSales && percentage)} />
            </Form.Item>
            <Form.Item
              label={isSales ? 'Margins' : 'Profit per lead'}
              name={isSales ? 'margins' : 'profit_per_lead'}
            >
              <CurrencyInput {...(isSales && percentage)} />
            </Form.Item>
          </div>
          <div className={s.flexBox}>
            <Form.Item
              label="Rec Budget"
              name={['budget', 'suggested']}
              rules={[
                {
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(new Error(`Cannot be empty`))
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <CurrencyInput />
            </Form.Item>
            <Form.Item label="Current Budget" name={['budget', 'curr']}>
              <CurrencyInput suffix={<Lock className={s.lock} />} disabled />
            </Form.Item>
          </div>
        </CustomizedForm>
      </div>
      <div className={s.group}>
        <div className={s.title}>
          Assets
          <Tooltip title="Assets">
            <Question />
          </Tooltip>
        </div>
        <CustomizedForm form={assetsForm} disableSubmit={!data.config}>
          <Form.Item label="Assets to Use">
            <MediaPicker readonly>
              {({ trigger }) => (
                <div className={cls(s.control, s.media)} onClick={trigger}>
                  <strong>{data.assets}</strong>
                  <Media />
                </div>
              )}
            </MediaPicker>
          </Form.Item>
          <Form.Item label="Default campaign assets">
            <div className={cls(s.control, s.btn)} onClick={toggle}>
              <strong>{data.config ? 'Most Recent' : 'Not configured'}</strong>
              <CircleArrow className={s.arrow} />
            </div>
          </Form.Item>
        </CustomizedForm>
      </div>

      <Modal
        className="custom-modal"
        open={open}
        closable={false}
        footer={null}
        centered
        destroyOnClose
        onCancel={toggle}
      >
        <Settings
          google={isGoogleConnected}
          meta={isMetaConnected}
          onCancel={toggle}
          onOk={toggle}
        />
      </Modal>
    </div>
  )
})

const isEqual = (a, b) =>
  isEqualWith(a, b, (v1, v2, k) => {
    if (k === 'locations') return true
    if (Array.isArray(v1) && Array.isArray(v2)) {
      const _v1 = [...v1].sort()
      const _v2 = [...v2].sort()
      return shallowEqual(_v1, _v2)
    }
  })

const CustomizedForm = ({
  form,
  children,
  showButtons,
  disableSubmit,
  onCancel = noop,
  onSubmit = api.saveGroupSettings,
}) => {
  const snapshot = useRef({})
  const [valuesChanged, setValuesChanged] = useState(false)
  const [loading, setLoading] = useState(false)

  useCreation(() => {
    const setFieldsValue = form.setFieldsValue.bind(form)
    form.setFieldsSnapshot = values => {
      setFieldsValue(values)
      snapshot.current = values
    }
  })

  const onValuesChange = useMemoizedFn(async () => {
    await Promise.resolve()
    const values = form.getFieldsValue(true)
    setValuesChanged(!isEqual(values, snapshot.current))
  })

  const handleCancel = () => {
    form.setFieldsValue(snapshot.current)
    setValuesChanged(false)
    onCancel()
  }

  const handleSubmit = async () => {
    await form.validateFields()
    const values = form.getFieldsValue(true)
    setLoading(true)
    await onSubmit(values).finally(() => setLoading(false))
    snapshot.current = values
    setValuesChanged(false)
    publish({ type: 'global_fetching' })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onValuesChange={onValuesChange}
    >
      {children}
      {(valuesChanged || showButtons) && (
        <div className={s.btns}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            disabled={disableSubmit}
            loading={loading}
            onClick={handleSubmit}
          >
            {showButtons ? 'Confirm Settings' : 'Save Changes'}
          </Button>
        </div>
      )}
    </Form>
  )
}

const FormItem = ({ name, label, ...props }) => {
  const form = Form.useFormInstance()
  const { suggested, suggested_message } = form.getFieldValue(name[0]) || {}
  return (
    <Form.Item
      name={name}
      label={
        suggested ? (
          <div className="flex-ac">
            {label}
            <Tooltip title={suggested_message}>
              <span className={s.dot} />
            </Tooltip>
          </div>
        ) : (
          label
        )
      }
      {...props}
    />
  )
}

const CheckboxButton = ({ children, disabled, value, onChange }) => (
  <div
    className={cls(s.checkboxBtn, value && s.active, disabled && s.disabled)}
    onClick={() => !disabled && onChange(!value)}
  >
    {children}
    {value && <Check className={s.checkboxBtnCheck} />}
  </div>
)

const EventsSelect = ({ platform, disabled, value, onChange, ...props }) => {
  const inUse = Form.useWatch([platform, 'in_use'])
  const { data = [], loading } = useEventOpts({
    platform,
    disabled,
  })

  return (
    <Spin spinning={loading}>
      <Select
        popupClassName={cls('custom-select-dropdown', s.selectDropdown)}
        disabled={disabled || !inUse}
        value={value}
        onChange={onChange}
        dropdownMatchSelectWidth={false}
        {...props}
      >
        {data.map(item => (
          <Select.Option key={item.value} disabled={item.disabled}>
            <span>{item.label}</span>
            {!!item.num && (
              <span style={{ marginLeft: 4, color: '#bababa' }}>
                ({item.num} in past {item.past_days} days)
              </span>
            )}
          </Select.Option>
        ))}
      </Select>
    </Spin>
  )
}

const LockSwitch = ({ value, onChange }) => {
  const Icon = value ? Lock : Auto
  return <Icon className={s.suffixIcon} onClick={() => onChange(!value)} />
}
