import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cls from 'classnames'
import styles from './index.module.scss'
import { Table, Drawer, Modal as _Modal, Button, Spin } from 'antd'
import google from '@/assets/images/onboard/google.png'
import tiktok from '@/assets/images/onboard/tiktok.png'
import facebook from '@/assets/images/onboard/fb.png'
import shopify from '@/assets/images/profile/shopify.png'
import { EditOutlined } from '@ant-design/icons'
import { useReactive, useRequest } from 'ahooks'
import {
  request,
  userSettings,
  userConnectPlatform,
  fetchUserBusinessSwitch,
  fetchUserBusinessNew,
} from '@/service'
import * as api from '@/service'
import { toggleInStripe, visibleCreateChat } from '@/store/actions'
import phone from '@/assets/images/profile/phone.png'
import add from '@/assets/images/profile/add.png'
import * as CM from './components/modal-main'
import { useHistory } from 'react-router-dom'
import OnBoardPop from '@/pages/onboard'
import { useSelector, useDispatch } from 'react-redux'
import * as _ from '@/utils'
import Edit from 'ico/edit.svg'
import MSelect from '@/components/m-select'

const { Modal, Fbevent, Message, getToken, setToken, removeToken } = _
let startTime = 0

const { REACT_APP_ENV: ENV } = process.env

const getIcon = platform => {
  switch (platform.toLowerCase()) {
    case 'google':
      return google
    case 'shopify':
      return shopify
    case 'tiktok':
      return tiktok
    case ('facebook', 'meta'):
      return facebook
  }
}

function openCenteredWindow(url, title, w, h) {
  const screenWidth = window.screen.width
  const screenHeight = window.screen.height
  const left = screenWidth / 2 - w / 2
  const top = screenHeight / 2 - h / 2

  const newWindow = window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, 
      top=${top}, left=${left}`
  )

  if (newWindow) newWindow.focus()
  return newWindow
}

const Profile = () => {
  const [loop, setLoop] = useState(false)

  const history = useHistory()
  const $state = useReactive({})
  const dispatch = useDispatch()
  const { is_free_trial } = useSelector(state => state.user.current) //date
  const {
    openDrawer = false,
    am = -1,
    title = '',
    info = {},
    accountPlatform = '',
    channelId = '',
    accounts = [],
    accountLoading = false,
    account_id = '',
    cancel = true,
  } = $state
  const code = new URLSearchParams(history.location.search).get('error_code')

  const { loading, run } = useRequest(
    async () => {
      const { data } = await request({
        url: userSettings,
        cancelable: true,
      })
      return data
    },
    {
      manual: true,
      refreshDeps: [channelId],
      onSuccess: data => {
        data.accounts = data.accounts.map((v, k) => ({
          ...v,
          key: k,
        }))
        $state.info = data
        const id = data.channels.find(v => v.selected).id
        $state.lastChannelId = id
        $state.channelId = id
      },
    }
  )

  const { run: runGoogle } = useRequest(
    async () => {
      const { data } = await api.request({
        url: '3/platform/google_check',
      })
      if (+new Date() - startTime > 3 * 60 * 1000) {
        setLoop(false)
        return
      }
      if (data?.result) {
        setLoop(false)
      }
    },
    {
      pollingInterval: loop ? 5 * 1000 : 0,
      manual: true,
      refreshDeps: [loop],
    }
  )

  const ConnectMeta = cb => {
    if (ENV == 'dev') {
      request({
        method: 'POST',
        url: userConnectPlatform,
        data: {},
      })
      cb?.()
      run()
      return
    }
    try {
      FB ?? Fbevent.init()
      Fbevent.do_facebook(() => {
        cb?.()
        run()
      })
    } catch (e) {}
  }

  const ConnectGoogle = async record => {
    if (ENV == 'dev') {
      const { data } = await api.request({
        method: 'post',
        url: '3/platform/google_connect ',
      })
      if (!data.result) {
        return Message.warn(data.message)
      }
      run()
      return
    }
    let googleWindow = openCenteredWindow(record.google_url, '', 700, 400)
    setLoop(true)
    runGoogle()
    let googleTimer = setInterval(() => {
      if (googleWindow.closed || !loop) {
        run()
        clearInterval(googleTimer)
      }
    }, 500)
  }

  const columns = [
    {
      title: 'Platform',
      dataIndex: 'Platform',
      key: 'platform',
      render: (_, record) => {
        return (
          <div className={styles.tablePlatform}>
            <img src={getIcon(record.platform)} />
            <span>{record.platform}</span>
          </div>
        )
      },
    },
    {
      title: 'Cactivate Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <span>
            <em
              className={cls(styles.tableConnection, {
                [styles.tableConnectionFail]:
                  ['Connected', 'Coming Soon'].indexOf(record.connection) < 0,
              })}
            >
              {record.connection}
            </em>
          </span>
        )
      },
    },

    {
      title: 'Account ID',
      dataIndex: 'account',
      key: 'account',
      render: (_, record) => {
        return record.account_id ? (
          <div className={styles.tableAccount}>{record.account_id}</div>
        ) : (
          <Button
            icon={<EditOutlined />}
            type="primary"
            shape="round"
            onClick={() => {
              $state.accountPlatform = record.platform.toLowerCase()
              $state.am = 'account'
              $state.accountLoading = true
              getAccounts()
            }}
          >
            Choose Account
          </Button>
        )
      },
    },
    {
      title: 'Account Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return <div className={styles.tableAccount}>{record.name}</div>
      },
    },
    {
      title: 'connection',
      dataIndex: 'connection',
      key: 'connection',
      render: (_, record) => {
        const handleConnect = () => {
          if (record.platform.toLowerCase() == 'meta') {
            ConnectMeta(() => {
              Message.success('Connection re-established')
            })
          } else {
            ConnectGoogle(record)
          }
        }

        const handleDisconnect = async () => {
          const { data } = await api.request({
            url: 'platform/disconnect',
            params: {
              platform: record.platform,
            },
          })

          if (data?.result) {
            run()
          }
        }

        return (
          <div>
            {!record.connected ? (
              <Button type="primary" shape="round" onClick={handleConnect}>
                Connect
              </Button>
            ) : (
              <div className={styles.tableReconnect}>
                <Button type="primary" shape="round" onClick={handleConnect}>
                  Reconnect
                </Button>
                <Button type="primary" shape="round" onClick={handleDisconnect}>
                  Disconnect
                </Button>
              </div>
            )}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    run()
  }, [])

  useEffect(() => {
    if (!code) return
    $state.cancel = false
    $state.peeding = true

    if (code == 'INVALID_TOKEN') {
      setTiltle('Updated & Accounts')
      $state.am = 'fb'
    } else if (['NO_CARD', 'FAILED_CHARGE'].includes(code)) {
      $state.am = 'billing'
      setTiltle('Billing')
    }
  }, [code])

  const handleChangeBusiness = () => {
    $state.openDrawer = true
  }

  const setTiltle = title => {
    $state.title = title
  }

  const getAccounts = useCallback(async () => {
    $state.accounts = []
    const { data } = await api
      .request({
        url:
          accountPlatform == 'meta'
            ? 'platform/load'
            : 'google_platform/load?type=account',
        params: {
          type: 'account',
        },
      })
      .finally(() => ($state.accountLoading = false))

    $state.accounts = data
  }, [accounts, accountLoading, accountPlatform])

  const ModalMain = () => {
    if (am == 'onboard')
      return (
        <OnBoardPop
          isPop
          values={{
            ...info?.default_settings,
            type: info?.business_info?.type,
          }}
          onCancel={() => {
            $state.am = -1
          }}
          onOk={() => {
            location.replace('/')
          }}
        />
      )

    if (am == 'account') {
      return (
        <div className={styles.accountMain}>
          <div className={styles.accountTitle}>
            <img src={getIcon(accountPlatform)} />
            <span>{accountPlatform}</span>
          </div>
          <div className={styles.accountContent}>
            <div className={styles.label}>Ad account</div>
            <Spin spinning={accountLoading}>
              <MSelect
                required={false}
                options={accounts}
                defaultValue={account_id}
                placeholder="Please select"
                onChange={v => {
                  $state.account_id = v
                }}
              />
            </Spin>
          </div>
          <Button
            type="primary"
            onClick={async () => {
              const { data } = await api.request({
                url: '3/platform/account',
                method: 'post',
                data: {
                  platform: accountPlatform,
                  account_id: account_id,
                },
              })
              if (data?.result) {
                run()
                $state.am = -1
              }
            }}
          >
            Confirm
          </Button>
        </div>
      )
    }

    return (
      <CM.ModalMain
        type={am}
        title={title}
        initVals={info[am]}
        info={info}
        cancel={cancel}
        onClose={(e, r = false) => {
          if (r) $state.info = null
          $state.am = -1
          run()
        }}
      />
    )
  }

  return (
    <Spin spinning={loading}>
      <div className={styles.profile}>
        <div className={styles.profileBox}>
          <div className={styles.profileHeader}>
            <div className={styles.profileTitle}>
              <span>Channel & Ad accounts</span>
              <span className={styles.name}>
                {$state.info?.profile?.username}
              </span>
            </div>
            <span onClick={handleChangeBusiness}>Switch Business &gt; </span>
          </div>
          <Table
            columns={columns}
            dataSource={info?.accounts ?? []}
            pagination={false}
          />
        </div>
        <div className={styles.profileLine}>
          <div className={styles.profileFlex1}>
            <div className={cls(styles.profileBox, styles.profileBoxHg270)}>
              <div className={styles.profileHeader}>
                <div className={styles.profileTitle}>
                  <span>Subscription</span>
                  <span className={styles.date}>
                    {info?.subsription?.last_day}
                  </span>
                </div>
              </div>

              <ul>
                {info?.subsription?.allow_edit && (
                  <li>
                    <span>Subscription plan</span>
                    <span
                      className={styles.stripeEdit}
                      onClick={() => dispatch(toggleInStripe(true))}
                    >
                      {info?.subsription?.management_plan}
                      <Edit />
                    </span>
                  </li>
                )}
                {!info?.subsription?.allow_edit && (
                  <li>
                    <span>Subscription plan</span>
                    <span> {info?.subsription?.management_plan}</span>
                  </li>
                )}
                <li>
                  <span>Platform fee</span>
                  <span> {info?.subsription?.per_platform_fee}</span>
                </li>
                <li>
                  <span>Management fee</span>
                  <span> {info?.subsription?.management_fee}</span>
                </li>
              </ul>
              <div className={styles.profileBoxBm}>
                <span>Total Due ({info?.subsription?.total_due_time})</span>
                <span>{info?.subsription?.total_due}</span>
              </div>
            </div>
          </div>
          <div className={styles.profileFlex1}>
            <div className={cls(styles.profileBox, styles.profileBoxHg270)}>
              <div className={styles.profileHeader}>
                <div className={styles.profileTitle}>
                  <span>Billing</span>
                </div>
                <span
                  onClick={() => {
                    $state.am = 'billing'
                    setTiltle('Billing')
                  }}
                >
                  Change CC &gt;{' '}
                </span>
              </div>
              <ul>
                <li>
                  <span>Credit Card on file</span>
                  <span
                    className={cls(
                      styles.billOn,
                      info?.billing?.is_on == 1
                        ? styles.billSuccess
                        : styles.billFail
                    )}
                  >
                    {info?.billing?.is_on == 1 ? 'Yes' : 'No'}
                  </span>
                </li>
                <li>
                  <span>Type </span>
                  <span>{info?.billing?.type}</span>
                </li>
                <li>
                  <span>Last 4 </span>
                  <span>{info?.billing?.last4}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.profileLine}>
          <div className={styles.profileFlex1}>
            <div className={cls(styles.profileBox, styles.profileBoxHg350)}>
              <div className={styles.profileHeader}>
                <div className={styles.profileTitle}>
                  <span>Profile</span>
                </div>
                {/* <span
                  onClick={() => {
                    $state.am = 'profile'
                    setTiltle('Profile')
                  }}
                >
                  Change &gt;{' '}
                </span> */}
              </div>
              <ul>
                <li>
                  <span>Name</span>
                  <span>{info?.profile?.username}</span>
                </li>
                <li>
                  <span>Email</span>
                  <div className={styles.profileEmail}>
                    <span>
                      {info?.profile?.email}
                      {!info?.profile?.email_verified && ' '}
                    </span>
                    {/* <img src={edit} /> */}
                  </div>
                </li>
                {/* <li>
                  <span>Password</span>
                  <span>******</span>
                </li> */}
                {/* <li>
                  <span>Role</span>
                  <span>{info?.profile?.role}</span>
                </li> */}
                <li>
                  <span>Date joined</span>
                  <span>{info?.profile?.join_time}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.profileFlex1}>
            <div className={cls(styles.profileBox, styles.profileBoxHg350)}>
              <div className={styles.profileHeader}>
                <div className={styles.profileTitle}>
                  <span>Business Info</span>
                </div>
              </div>
              <ul>
                <li>
                  <span>Business name</span>
                  <span>{info?.business_info?.name}</span>
                </li>
                {/* <li>
                  <span>Industry</span>
                  <span>{info?.business_info?.industry}</span>
                </li> */}
                <li>
                  <span>Website</span>
                  <span>{info?.business_info?.website}</span>
                </li>
                {/* <li>
                  <span>Summary</span>
                  <span>{info?.business_info?.type}</span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.profileCancel}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              $state.am = 5
              setTiltle('We’re sad to see you go...')
            }}
          >
            Cancel Service
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              removeToken()
              setTimeout(() => {
                location.replace('/chat')
              }, 300)
            }}
          >
            Log Out
          </Button>
        </div>
        <Drawer
          title="Switch Business"
          placement="bottom"
          className={styles.profileBusiness}
          closable={false}
          open={openDrawer}
          onClose={() => {
            $state.openDrawer = false
          }}
        >
          <div className={styles.profileBusinessList}>
            <ul>
              {info?.channels?.map((v, k) => (
                <li
                  onClick={async () => {
                    if (v.id != channelId) {
                      /* const { data } = await fetchUserBusinessSwitch({
                        business_id: v.id,
                      })
                      if (!data?.result) {
                        Message.warn('Could not switch business.')
                        $state.openDrawer = false
                        return
                      } */
                      _.setBusinessId(v.id)
                      location.reload()
                    } else {
                      $state.openDrawer = false
                    }
                    $state.lastChannelId = channelId
                    $state.channelId = v.id
                  }}
                  className={cls({ [styles.active]: v.id == channelId })}
                  key={v.id}
                >
                  {channelId == v.id && (
                    <div className={styles.profileBusinessSuccess}>
                      {/* <img src={success} /> */}
                    </div>
                  )}
                  <img src={phone} className={styles.profileBusinessPhone} />
                  <div className={styles.profileBusinessName}>{v.name}</div>
                  <div className={styles.profileBusinessStatus}>
                    {v.connection}
                  </div>
                </li>
              ))}
            </ul>
            {!is_free_trial && (
              <div
                className={styles.profileBusinessAdd}
                onClick={() =>
                  fetchUserBusinessNew().then(({ error }) => {
                    if (!error) location.href = location.origin
                  })
                }
              >
                <div className={styles.profileBusinessAddIcon}>
                  <img src={add} />
                </div>
                <p>CREATE NEW</p>
              </div>
            )}
          </div>
        </Drawer>
        <_Modal
          open={am != -1}
          width="fit-content"
          destroyOnClose
          maskClosable={!code}
          closable={false}
          footer={null}
          wrapClassName="profileModal"
          onCancel={() => {
            $state.am = -1
          }}
          centered
          bodyStyle={{ padding: 0 }}
        >
          <ModalMain />
        </_Modal>
      </div>
    </Spin>
  )
}

export default Profile
