import React, { useState } from 'react'
import { Modal, Form, Button, Spin } from 'antd'
import {
  useRequest,
  useDynamicList,
  useUpdateEffect,
  useReactive,
  useAsyncEffect,
  useToggle,
  useLockFn,
} from 'ahooks'
import cls from 'classnames'
import * as _ from '@/utils'
import * as api from '@/service'
import Plan from '@/pages/v3-start/plan'
import { CContext as C } from '@/context'
import MSelect from '@/components/m-select/index'
import { fetchFacebookAssets, importAssets } from '@/service/assets'
import { transformUrl } from '@/utils/uploader'

import styles from './index.module.scss'
import _default from '@img/default.png'
import Close from 'ico/ads/close.svg'
import Fb from 'ico/start/fb.svg'
import Success from 'ico/success.svg'
import Error from 'ico/error.svg'
import Check from 'ico/start/check.svg'
import Play from 'ico/start/play.svg'
import Delete from 'ico/start/delete.svg'
import Checked from 'ico/start/checked.svg'

const noop = () => {}
const { Message, Fbevent } = _

export default function StartModal({ visible = false, onOk = noop }) {
  const [open, setOpen] = React.useState(visible)
  const { step, setIsShow } = React.useContext(C)

  useUpdateEffect(() => setOpen(visible), [visible])

  return (
    <Modal
      width="fit-content"
      centered
      closable={step == 5}
      destroyOnClose
      maskClosable={false}
      wrapClassName={styles.modal}
      footer={null}
      onCancel={() => setIsShow(false)}
      open={open}
    >
      {step == 3 && (
        <AccountForm
          onNext={() => {
            onOk?.()
            setIsShow(false)
          }}
          onCancel={() => {
            setIsShow(false)
          }}
        />
      )}
      {step == 5 && <Plan modal />}
    </Modal>
  )
}

export const Assets = ({ onOk, onCancel }) => {
  // const [isConnect, setIsAssets] = React.useState(false)

  return (
    <>
      {/* {!isConnect ? (
        <AccountForm type={2} onNext={() => setIsAssets(true)} />
      ) : ( */}
      <AssetsList onOk={onOk} onCancel={onCancel} />
      {/* )} */}
    </>
  )
}

const AccountForm = ({ type = 3, onNext = noop, onCancel = noop }) => {
  const [form] = Form.useForm()
  const { setStep, info, ev } = React.useContext(C)
  const facebook_id = Form.useWatch('facebook_id', form)
  const account = Form.useWatch('account_id', form)
  const page = Form.useWatch('page_id', form)
  const ins = Form.useWatch('instagram_id', form)
  const pixel = Form.useWatch('pixel_id', form)
  const lead_type = Form.useWatch('lead_type', form)
  const leadform = Form.useWatch('leadform_id', form)
  const pixel_event = Form.useWatch('pixel_event', form)
  const state = useReactive({ disabled: true, verify: {} })

  const initData = () => {
    form.setFieldsValue({
      ...state.formData,
      pixel_id: info?.pixel_id
        ? {
            value: info?.pixel_id,
            label: info?.pixel_name,
          }
        : void 0,
      pixel_event: info?.pixel_event
        ? {
            value: info?.pixel_event,
          }
        : void 0,
      leadform_id: info?.leadform_id
        ? {
            value: info?.leadform_id,
            label: info?.leadform_name,
          }
        : void 0,
    })
  }

  const connect = useLockFn(async () => {
    state.submitLoading = true
    const rqsData =
      type == 2
        ? {
            facebook_id,
            account_id: account.value,
            account_name: account.label,
            page_id: page.value,
            page_name: page.label,
            instagram_id: ins.value,
            instagram_name: ins.label,
          }
        : {
            pixel_id: pixel?.value,
            pixel_name: pixel?.label,
            pixel_event: pixel_event?.value,
            lead_type: lead_type,
            leadform_id: leadform?.value,
            leadform_name: leadform?.label,
          }

    const { data } = await api
      .request({
        method: 'post',
        url: type == 2 ? api.platformSave : api.platformEventSave,
        data: rqsData,
      })
      .finally(() => (state.submitLoading = false))
    if (data?.result) {
      type == 3 && ev.emit('onUpdateBusiness')
      return onNext()
    }
    Message.warn(data?.message ?? 'Submit Fail')
  })

  const handlVerify = async (type, { value }, msg, feedback) => {
    let params = { type, id: value }
    params =
      type == 'event' ? { ...params, account_id: account?.value } : params
    const {
      data: { result, message },
    } = await api.fetchPlatformVerify(params)
    state[msg] = message
    state[feedback] = result ? 'success' : 'fail'
    state.verify[type] = result
  }

  useAsyncEffect(async () => {
    if (!facebook_id) return
    state.accountLoading = true
    const data = await Promise.allSettled([
      api.fetchPlatformsLoad({
        type: 'account',
      }),
      api.fetchPlatformsLoad({
        type: 'page',
      }),
    ])
    state.accountLoading = false
    state.accounts = data[0].value
    state.pages = data[1].value
    initData()
  }, [facebook_id])

  useAsyncEffect(async () => {
    if (!page) return
    state.insLoading = true
    const data = await api
      .fetchPlatformsLoad({
        type: 'instagram',
        page_id: page?.value ?? page,
      })
      .finally(() => (state.insLoading = false))
    state.instagrams = data
    // state.disabled =
    //   !facebook_id || Object.values(form.getFieldsValue()).some(v => !v)
  }, [page])

  useAsyncEffect(async () => {
    if (type != 3 || !info) return
    state.formData = info
    form.setFieldValue('facebook_id', info?.facebook_id)
    state.disabledAcid = !!info?.account_id
  }, [info])

  useAsyncEffect(async () => {
    if (!account) return
    state.pixelLoading = true
    const data = await api
      .fetchPlatformsLoad({
        type: 'pixel',
        account_id: account?.value ?? account,
      })
      .finally(() => (state.pixelLoading = false))
    state.pixels = data
  }, [account])

  useAsyncEffect(async () => {
    if (!account || !page) return
    state.eventLoading = true
    const rqs = [
      api.fetchPlatformsLoad({
        type: 'event',
        account_id: account?.value ?? account,
        pixel_id: pixel?.value ?? pixel,
      }),
    ]
    lead_type == 'leadform' &&
      rqs.push(
        api.fetchPlatformsLoad({
          type: 'leadform',
          account_id: account?.value ?? account,
          page_id: page?.value ?? page,
        })
      )
    const reps = await Promise.allSettled(rqs)
    state.eventLoading = false

    state.events = reps[0].value
    lead_type == 'leadform' && (state.leadforms = reps[1].value)
  }, [account, pixel, lead_type, page])

  return (
    <div className={styles.accounts}>
      <div className={styles.title}>Connect Assets</div>
      <div className={styles.desc}>
        Choose the ad account you would like us to use.
      </div>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(v, allv) => {
          console.log('🚀 ~ file: index.js:248 ~ AccountForm ~ allv:', allv)
          state.disabled =
            !facebook_id ||
            Object.values(allv).some(v => !v) ||
            Object.values(state.verify).includes(false)
        }}
      >
        <div className={styles.actions}>
          <Form.Item name="facebook_id">
            <ConnectBtn />
          </Form.Item>
          {type == 3 && info?.objective != 'CONVERSIONS' && (
            <Form.Item name="lead_type" initialValue="website">
              <TypeBtn />
            </Form.Item>
          )}
        </div>
        <div className={styles.item}>
          <Spin spinning={!!state.accountLoading && facebook_id}>
            <MSelect
              label="Ad account"
              name="account_id"
              disabled={!facebook_id || state.disabledAcid}
              labelInValue
              required={false}
              options={state.accounts}
              popupClassName="onboard-select"
              placeholder="Please select"
              onChange={v => {
                handlVerify('account', v, 'accountMessage', 'accountFeedback')
              }}
            />
          </Spin>
          {state.accountFeedback && (
            <p className={styles[state.accountFeedback]}>
              {state.accountFeedback == 'success' ? <Success /> : <Error />}
              {state.accountMessage}
            </p>
          )}
        </div>
        <div className={styles.item}>
          <Spin spinning={!!state.accountLoading && facebook_id}>
            <MSelect
              label="Page"
              name="page_id"
              disabled={!facebook_id || state.disabledAcid}
              labelInValue
              required={false}
              popupClassName="onboard-select"
              placeholder="Please select"
              options={state.pages}
              onChange={v => {
                handlVerify('page', v, 'pageMessage', 'pageFeedback')
              }}
            />
          </Spin>
          {state.pageFeedback && (
            <p className={styles[state.pageFeedback]}>
              {state.pageFeedback == 'success' ? <Success /> : <Error />}
              {state.pageMessage}
            </p>
          )}
        </div>
        <div className={styles.item}>
          <Spin spinning={!!state.insLoading && facebook_id}>
            <MSelect
              label="Instagram"
              name="instagram_id"
              required={false}
              disabled={!facebook_id || state.disabledAcid}
              labelInValue
              popupClassName="onboard-select"
              placeholder="Please select"
              options={state?.instagrams}
              onChange={v => {
                handlVerify(
                  'instagram',
                  v,
                  'instagramMessage',
                  'instagramFeedback'
                )
              }}
            />
          </Spin>
          {state.instagramFeedback && (
            <p className={styles[state.instagramFeedback]}>
              {state.instagramFeedback == 'success' ? <Success /> : <Error />}
              {state.instagramMessage}
            </p>
          )}
        </div>
        {type == 3 && (
          <>
            {info?.objective == 'CONVERSIONS' || lead_type == 'website' ? (
              <>
                <div className={styles.item}>
                  <Spin spinning={!!state.pixelLoading && facebook_id}>
                    <MSelect
                      label="Pixel"
                      disabled={!facebook_id}
                      name="pixel_id"
                      labelInValue
                      required={false}
                      // popupClassName="onboard-select"
                      placeholder="Please select"
                      options={state.pixels}
                      onChange={v => {
                        handlVerify('pixel', v, 'pixelMessage', 'pixelFeedback')
                      }}
                    />
                  </Spin>
                  {state.pixelFeedback && (
                    <p className={styles[state.pixelFeedback]}>
                      {state.pixelFeedback == 'success' ? (
                        <Success />
                      ) : (
                        <Error />
                      )}
                      {state.pixelMessage}
                    </p>
                  )}
                </div>
                <div className={styles.item}>
                  <Spin spinning={!!state.eventLoading && facebook_id}>
                    <MSelect
                      label="What should we optimize for?"
                      name="pixel_event"
                      required={false}
                      labelInValue
                      disabled={!facebook_id}
                      // popupClassName="onboard-select"
                      placeholder="Please select"
                      options={state.events}
                      onChange={v => {
                        handlVerify('event', v, 'eventMessage', 'eventFeedback')
                      }}
                    />
                  </Spin>
                  {state.eventFeedback && (
                    <p className={styles[state.eventFeedback]}>
                      {state.eventFeedback == 'success' ? (
                        <Success />
                      ) : (
                        <Error />
                      )}
                      {state.eventFeedback != 'success' && state.eventMessage}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div className={styles.item}>
                <Spin spinning={!!state.eventLoading && facebook_id}>
                  <MSelect
                    label="Lead Form"
                    name="leadform_id"
                    required={false}
                    labelInValue
                    disabled={!facebook_id}
                    // popupClassName="onboard-select"
                    placeholder="Please select"
                    options={state.leadforms}
                    onChange={v => {
                      handlVerify(
                        'leadform',
                        v,
                        'leadformMessage',
                        'leadformFeedback'
                      )
                    }}
                  />
                </Spin>
                {state.leadformFeedback && (
                  <p className={styles[state.leadformFeedback]}>
                    {state.leadformFeedback == 'success' ? (
                      <Success />
                    ) : (
                      <Error />
                    )}

                    {state.leadformMessage}
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </Form>
      <div className={styles.footer}>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            if (type == 2) {
              return setStep(1)
            }
            onCancel?.()
          }}
        >
          Back
        </Button>
        <Button
          type="primary"
          shape="round"
          loading={state.submitLoading}
          disabled={state?.disabled}
          onClick={connect}
        >
          Connect
        </Button>
      </div>
    </div>
  )
}

const ConnectBtn = ({ value, onChange }) => {
  const [check, setCheck] = useState(false)
  const { REACT_APP_ENV: ENV } = process.env

  const handleCheck = async () => {
    if (check) return
    if (ENV == 'dev') {
      const { data } = await api.FbConnect({})
      if (!data.result) {
        Message.warn(data.message)
      } else {
        onChange('164251791054647')
        setCheck(true)
      }
      return
    }
    try {
      FB ?? Fbevent.init()
      Fbevent.do_facebook(facebook_obj => {
        onChange(facebook_obj.facebook_id)
        setCheck(true)
      })
    } catch (e) {}
  }

  useUpdateEffect(() => {
    setCheck(!!value)
  }, [value])

  return (
    <div className={styles.connect} onClick={handleCheck}>
      <div className={styles.connectBtn}>
        {check ? <Checked /> : <Check />}
        <Fb />
        <span>Facebook</span>
      </div>
      <div className={styles.desc}>Connect Your Facebook Account</div>
    </div>
  )
}

const TypeBtn = ({ onChange, value }) => {
  const [state, { set }] = useToggle('website', 'leadform')

  const handleCheck = type => {
    set(type)
    onChange(type)
  }

  useUpdateEffect(() => {
    handleCheck(value)
  }, [value])

  return (
    <div className={styles.types}>
      <span
        className={cls({
          [styles.active]: state == 'website',
        })}
        onClick={() => handleCheck('website')}
      >
        Website
      </span>
      <span
        className={cls({
          [styles.active]: state == 'leadform',
        })}
        onClick={() => handleCheck('leadform')}
      >
        Lead Form
      </span>
    </div>
  )
}

const AssetsList = ({ onOk, onCancel }) => {
  const { data = [], loading } = useRequest(async () => {
    const { data } = await fetchFacebookAssets()
    resetList(data)
    return data
  })
  const { list, resetList, push, remove } = useDynamicList()
  const [syncLoading, setSyncLoading] = useState(false)

  const syncAssets = useLockFn(async () => {
    setSyncLoading(true)
    await importAssets(list.map(item => item.id)).finally(() =>
      setSyncLoading(false)
    )
    onOk(list)
  })

  const toggleSelected = item => {
    const idx = list.indexOf(item)
    idx === -1 ? push(item) : remove(idx)
  }

  return (
    <div className={styles.assets}>
      <div className={styles.title}>
        We found these from your website and active ads
      </div>
      <div className={styles.desc}>
        The more you give us, the better our suggestion quality will be.{' '}
      </div>
      <Spin spinning={loading}>
        <div className={styles.warp}>
          <ul>
            {data.map(item => (
              <li key={item.id} onClick={() => toggleSelected(item)}>
                {list.includes(item) && <Checked className={styles.check} />}
                <div className={styles.pic}>
                  <img src={transformUrl(item.url)} />
                  {/* <Delete /> */}
                </div>
                <div className={styles.name}>{item.name}</div>
                {item.type === 'video' && <Play className={styles.play} />}
              </li>
            ))}
          </ul>
        </div>
      </Spin>
      <div className={styles.btn}>
        <Button
          type="primary"
          shape="round"
          disabled={!list.length}
          loading={syncLoading}
          onClick={syncAssets}
        >
          Sync <b>{list.length}</b> assets
        </Button>
        <span className={styles.skip} onClick={onCancel}>
          Skip
        </span>
      </div>
    </div>
  )
}
