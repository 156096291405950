import React from 'react'
import { Modal } from 'antd'
import Success from '@/assets/images/success.png'
import Warn from '@/assets/images/warn.png'
import Warning from '@/assets/images/warning.png'

const { confirm } = Modal
const dialogIconMap = {
  delete: Warning,
  warn: Warn,
  success: Success,
}

/**
 * Modal.confirm({
      title: 'Are you sure?',
      subtitle: 'Deleted it',
      type: 'success',
    })

    type: delete | warn | success
 */
export default {
  confirm({ title, subtitle, type, onOk, onCancel }) {
    return new Promise((resolve, reject) => {
      confirm({
        centered: true,
        icon: <img src={dialogIconMap[type]} alt="" />,
        content: (
          <div>
            <div className="confirm-dailog__title">{title}</div>
            <div className="confirm-dailog__subtitle">{subtitle}</div>
          </div>
        ),
        okText: 'Yes',
        onOk() {
          onOk ? onOk() : resolve()
        },
        onCancel() {
          onCancel ? onCancel() : reject()
        },
        className: `confirm-dailog__custom confirm-dailog__${type}`,
      })
    })
  },
}
