import { Modal, Spin } from 'antd'
import Currency from '@/components/m-currency'
import CampaignsTable from '../CampaignsTable'
import s from './index.module.scss'

export default function Unassigned({
  data = [],
  refresh,
  loading,
  setLoading,
}) {
  /* const budget = data.reduce(
    (sum, item) =>
      sum +
      ((item.lifetime_budget || item.status != 'running' ? 0 : item.budget) ||
        0),
    0
  ) */
  return (
    <Spin spinning={loading}>
      <div className={s.wrap}>
        <div className={s.headline}>
          <div className={s.title}>Choose Funnel Stage to Assign</div>
          <div className={s.unassigned}>
            <span>Unassigned</span>
            <span className={s.badge}>{data.length}</span>
          </div>
          {/* <div className={s.budget}>
            <span>Active Daily Budget</span>
            <i />
            <Currency className={s.currency} value={budget} />
          </div> */}
        </div>
        <CampaignsTable
          status="unassigned"
          data={data}
          refresh={refresh}
          setSpinning={setLoading}
        />
      </div>
    </Spin>
  )
}
