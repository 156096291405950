import { useState, useRef, useMemo } from 'react'
import moment from 'moment'
import { DatePicker, Button } from 'antd'
import { useEventListener } from 'ahooks'
import { useSelector } from 'react-redux'
import { useTrackState } from '@/hooks'
import Calendar from 'ico/v3-campaigns/calendar.svg'
import s from './index.module.scss'
import cls from 'classnames'

/**
 * @param {import('moment').Moment} value
 */
const format = value => value.format('MMM DD, YYYY')

export default function RangePicker({ value, onChange }) {
  const start = useSelector(state =>
    moment(state.user.current.started_from || moment().subtract(1, 'y'))
  )
  const [ranges] = useState(() => {
    const lastDays = days => [
      moment().subtract(days, 'd'),
      moment().subtract(1, 'd'),
    ]
    return {
      Maximum: [start, moment()],
      'Last 90 Days': lastDays(90),
      'Last 30 Days': lastDays(30),
      'Last 7 Days': lastDays(7),
      Today: [moment(), moment()],
    }
  })
  const [range, setRange] = useTrackState(value)
  const [open, setOpen] = useState(false)
  const lockOpen = useRef(false)

  const pickerRef = useRef()
  const elRef = useRef()
  useEventListener(
    'click',
    e => {
      if (!e.target.classList.contains('ant-tag')) return
      const li = e.target.parentNode
      const idx = Array.from(li.parentNode.children).indexOf(li)
      if (idx === activePresetIdx) e.stopPropagation()
    },
    { target: elRef, capture: true }
  )
  const activePresetIdx = useMemo(() => {
    if (!range.every(Boolean)) return -1
    return Object.values(ranges).findIndex(
      ([start, end]) => start.isSame(range[0], 'd') && end.isSame(range[1], 'd')
    )
  }, [ranges, range])
  const currentPresetIdx = useMemo(() => {
    return Object.values(ranges).findIndex(
      ([start, end]) => start.isSame(value[0], 'd') && end.isSame(value[1], 'd')
    )
  }, [ranges, value])

  const onCancel = () => {
    lockOpen.current = false
    setOpen(false)
    setRange(value)
    setTimeout(() => pickerRef.current?.blur(), 300)
  }

  const onConfirm = () => {
    lockOpen.current = false
    setOpen(false)
    onChange(range)
    setTimeout(() => pickerRef.current?.blur(), 300)
  }

  return (
    <div className={s.picker}>
      {/* {currentPresetIdx !== -1 && (
        <span className={s.label}>
          {Object.keys(ranges)[currentPresetIdx]}:
        </span>
      )} */}
      <Calendar className={s.calendar} />
      <div className="pr">
        <div className={s.value}>
          <span>{format(value[0])}</span>
          <strong>-</strong>
          <span>{format(value[1])}</span>
        </div>
        <DatePicker.RangePicker
          dropdownClassName={s.dropdown}
          style={{ position: 'relative' }}
          ref={pickerRef}
          open={open}
          ranges={ranges}
          value={range}
          bordered={false}
          format={format}
          suffixIcon={null}
          separator={null}
          allowClear={false}
          disabledDate={current => current && current > moment().endOf('d')}
          panelRender={node => (
            <div className={s.panel}>
              <div
                ref={elRef}
                className={s.body}
                data-active-preset={activePresetIdx + 1}
              >
                {node}
              </div>
              <div className={s.footer}>
                <div className={s.date}>
                  <span className={cls({ [s.empty]: !range[0] })}>
                    {range[0] ? format(range[0]) : 'Start date'}
                  </span>
                  -
                  <span className={cls({ [s.empty]: !range[1] })}>
                    {range[1] ? format(range[1]) : 'End date'}
                  </span>
                </div>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  type="primary"
                  disabled={!range[0] || !range[1]}
                  onClick={onConfirm}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
          onChange={range => {
            lockOpen.current = true
          }}
          onCalendarChange={setRange}
          onOpenChange={open => {
            if (lockOpen.current) {
              lockOpen.current = false
              return
            }
            setOpen(open)
            !open && setRange(value)
          }}
        />
      </div>
    </div>
  )
}
