import { Precreate } from '../chat/components'
import { useQuery } from '@/hooks'
import cls from 'classnames'
import s from './index.module.scss'

export default function Order() {
  const { stage, platform } = useQuery()

  return (
    <div className={cls(s.wrap, 'nice-scrollbar')}>
      <Precreate isPage platform={platform} stage={stage} />
    </div>
  )
}
