import { useEffect } from 'react'
import { Form, Input, Select, Button, Spin } from 'antd'
import { useRequest } from 'ahooks'
import { RadioButtonGroup } from '@/components/v3-create/core/controls'
import TargetSuggest from '@/components/target-suggest'
import { fetchPlatformsLoad } from '@/service/onboard-v3'
import { fetchGroupSettings, saveGroupSettings } from '@/service/chat'
import { MediaPicker } from '@/pages/v3-media-library'
import { IMG_MAX_SIZE } from '@/utils/uploader'
import { getMediaInfo } from '@/components/v3-create/creatives'
import { publish } from '@/subscribe'

import cls from 'classnames'
import s from './index.module.scss'
import Google from 'ico/dashboard/google.svg'
import Meta from 'ico/meta.svg'
import Close from 'ico/v3-create/close.svg'
import Plus from 'ico/v3-media/plus.svg'

let pagePromise, pixelPromise

const usePlatformLoad = (form, enabled) => {
  const objective = Form.useWatch(['meta', 'objective'], form)
  const lead_type = Form.useWatch(['meta', 'lead_type'], form)
  const page_id = Form.useWatch(['meta', 'page_id'], form)
  const pixel_id = Form.useWatch(['meta', 'pixel_id'], form)
  const isWebsite =
    objective === 'OUTCOME_SALES' ||
    (objective === 'OUTCOME_LEADS' && lead_type === 'website')
  const isLeadform = objective === 'OUTCOME_LEADS' && lead_type === 'leadform'

  const setInitialVal = field => data => {
    if (!form.getFieldValue(['meta', field]) && data?.length) {
      form.setFieldValue(['meta', field], data[0].value)
    }
    return data
  }

  const { data: pageOpts, loading: pageLoading } = useRequest(() => {
    if (!enabled) return
    if (!pagePromise) {
      pagePromise = fetchPlatformsLoad({
        type: 'page',
      })
    }
    return pagePromise.then(setInitialVal('page_id'))
  })
  const { data: instagramOpts, loading: instagramLoading } = useRequest(
    async () =>
      page_id && enabled
        ? fetchPlatformsLoad({ type: 'instagram', page_id }).then(
            setInitialVal('instagram_id')
          )
        : instagramOpts,
    { refreshDeps: [page_id], cacheKey: 'load_instagram' }
  )
  const { data: pixelOpts, loading: pixelLoading } = useRequest(
    async () => {
      if (!isWebsite || !enabled) return pixelOpts
      if (!pixelPromise) {
        pixelPromise = fetchPlatformsLoad({
          type: 'pixel',
        })
      }
      return pixelPromise.then(setInitialVal('pixel_id'))
    },
    { refreshDeps: [objective, lead_type] }
  )
  /* const { data: eventOpts, loading: eventLoading } = useRequest(
    async () =>
      isWebsite && pixel_id
        ? fetchPlatformsLoad({ type: 'event', pixel_id }).then(
            setInitialVal('pixel_event')
          )
        : eventOpts,
    { refreshDeps: [objective, pixel_id, lead_type], cacheKey: 'load_event' }
  ) */
  const { data: leadformOpts, loading: leadformLoading } = useRequest(
    async () => {
      return isLeadform && page_id && enabled
        ? fetchPlatformsLoad({ type: 'leadform', page_id }).then(
            setInitialVal('leadform_id')
          )
        : leadformOpts
    },
    { refreshDeps: [page_id, lead_type], cacheKey: 'load_leadform' }
  )

  return {
    objective,
    lead_type,
    isWebsite,
    isLeadform,
    pageOpts,
    pageLoading,
    instagramOpts,
    instagramLoading,
    pixelOpts,
    pixelLoading,
    // eventOpts,
    // eventLoading,
    leadformOpts,
    leadformLoading,
  }
}

export default function Settings({ google, meta, onCancel, onOk }) {
  const [form] = Form.useForm()
  const {
    objective,
    lead_type,
    isWebsite,
    isLeadform,
    pageOpts,
    pageLoading,
    instagramOpts,
    instagramLoading,
    pixelOpts,
    pixelLoading,
    leadformOpts,
    leadformLoading,
  } = usePlatformLoad(form, meta)

  const { loading } = useRequest(async () => {
    const { data } = await fetchGroupSettings()
    form.setFieldsValue(data)
  })

  const { loading: submitting, runAsync: submit } = useRequest(
    async () => {
      const values = await form.validateFields()
      const err = form.getFieldError(['google', 'logo'])
      if (err.length) return
      await saveGroupSettings(values)
      onOk()
      publish({ type: 'global_fetching' })
    },
    { manual: true }
  )

  return (
    <Spin spinning={loading}>
      <div className={s.wrap}>
        <div className={s.title}>Default campaign settings</div>
        <div className={cls(s.main, 'nice-scrollbar')}>
          <Form
            className={s.form}
            form={form}
            layout="vertical"
            requiredMark={false}
          >
            <div className={cls(s.block, { [s.disabled]: !meta })}>
              <div className={s.platform}>
                <Meta />
              </div>
              <Form.Item
                label=" "
                name={['meta', 'objective']}
                initialValue="sales"
              >
                <RadioButtonGroup
                  options={[
                    { value: 'OUTCOME_SALES', label: 'Sales' },
                    { value: 'OUTCOME_LEADS', label: 'Lead' },
                    { value: 'OUTCOME_TRAFFIC', label: 'Traffic' },
                  ]}
                />
              </Form.Item>
              {objective === 'OUTCOME_LEADS' && (
                <Form.Item
                  label="Destination:"
                  name={['meta', 'lead_type']}
                  initialValue="website"
                >
                  <RadioButtonGroup
                    options={[
                      { value: 'website', label: 'Website' },
                      { value: 'leadform', label: 'Lead Form' },
                    ]}
                  />
                </Form.Item>
              )}
              <Form.Item
                label="Link:"
                name={['meta', 'link']}
                rules={
                  meta && [
                    {
                      required: true,
                      type: 'url',
                      message: 'Link is invalid',
                    },
                  ]
                }
              >
                <Input
                  addonAfter={
                    <Button
                      className={s.addon}
                      type="primary"
                      onClick={() => {
                        const value = form.getFieldValue(['meta', 'link'])
                        const utm =
                          'utm_source={{site_source_name}}&utm_medium={{placement}}&utm_campaign={{campaign.name}}&utm_content={{ad.id}}'
                        if (value && !value.includes(utm)) {
                          if (!value.includes('?')) {
                            form.setFieldValue(
                              ['meta', 'link'],
                              value + '?' + utm
                            )
                          } else {
                            form.setFieldValue(
                              ['meta', 'link'],
                              value + '&' + utm
                            )
                          }
                        }
                      }}
                    >
                      Add UTM
                    </Button>
                  }
                />
              </Form.Item>
              <Spin spinning={pageLoading}>
                <Form.Item
                  label="Page ID:"
                  name={['meta', 'page_id']}
                  rules={[{ required: meta, message: 'Page ID is required' }]}
                >
                  <Select
                    popupClassName="custom-select-dropdown"
                    dropdownStyle={{ zIndex: 1052 }}
                    options={pageOpts}
                    onChange={() => {
                      form.setFieldValue(['meta', 'instagram_id'], '')
                      isLeadform &&
                        form.setFieldValue(['meta', 'leadform_id'], '')
                    }}
                  />
                </Form.Item>
              </Spin>
              <Spin spinning={instagramLoading}>
                <Form.Item
                  label="Instagram ID:"
                  name={['meta', 'instagram_id']}
                  rules={[
                    {
                      required: meta,
                      message: 'Instagram ID is required',
                    },
                  ]}
                >
                  <Select
                    popupClassName="custom-select-dropdown"
                    dropdownStyle={{ zIndex: 1052 }}
                    options={instagramOpts}
                  />
                </Form.Item>
              </Spin>
              {isWebsite ? (
                <Spin spinning={pixelLoading}>
                  <Form.Item
                    label="Pixel:"
                    name={['meta', 'pixel_id']}
                    rules={[
                      {
                        required: meta,
                        message: 'Pixel is required',
                      },
                    ]}
                  >
                    <Select
                      popupClassName="custom-select-dropdown"
                      dropdownStyle={{ zIndex: 1052 }}
                      options={pixelOpts}
                    />
                  </Form.Item>
                </Spin>
              ) : isLeadform ? (
                <Spin spinning={leadformLoading}>
                  <Form.Item
                    label="Lead Form:"
                    name={['meta', 'leadform_id']}
                    rules={[
                      {
                        required: meta,
                        message: 'Lead Form is required',
                      },
                    ]}
                  >
                    <Select
                      popupClassName="custom-select-dropdown"
                      dropdownStyle={{ zIndex: 1052 }}
                      options={leadformOpts}
                    />
                  </Form.Item>
                </Spin>
              ) : null}
              <Form.Item
                label="Location:"
                name={['meta', 'locations']}
                rules={[{ required: meta, message: 'Location is required' }]}
              >
                <TargetSuggest type="location" />
              </Form.Item>
            </div>
            <div className={cls(s.block, { [s.disabled]: !google })}>
              <div className={cls(s.platform, s.google)}>
                <Google />
              </div>
              <Form.Item
                label="Link:"
                name={['google', 'link']}
                rules={
                  google && [
                    {
                      required: true,
                      type: 'url',
                      message: 'Link is invalid',
                    },
                  ]
                }
              >
                <Input />
              </Form.Item>
              <Form.Item label="Logo:" name={['google', 'logo']}>
                <LogoUpload />
              </Form.Item>
              <Form.Item
                label="Brand Name:"
                name={['google', 'brand_name']}
                rules={[
                  {
                    required: google,
                    message: 'Brand Name is required',
                  },
                ]}
              >
                <Input maxLength={25} />
              </Form.Item>
              <Form.Item
                label="Location:"
                name={['google', 'locations']}
                rules={[{ required: google, message: 'Location is required' }]}
              >
                <TargetSuggest type="location" from="google" />
              </Form.Item>
            </div>
          </Form>
          <div className={s.btns}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" loading={submitting} onClick={submit}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  )
}

const LogoUpload = ({ name = ['google', 'logo'], value, onChange }) => {
  const form = Form.useFormInstance()
  const validate = ({ ratio, width, size }) => {
    const errors = []
    if (ratio !== 1) {
      errors.push('Aspect ratio must be 1:1')
    } else if (width < 128) {
      errors.push('The resolution must not be less than 128x128')
    }
    if (size > IMG_MAX_SIZE) {
      errors.push('Logo too large')
    }
    form.setFields([{ name, errors }])
  }
  const onOk = ([media]) => {
    onChange({ id: media.id, url: media.url })
    const [width, height] = media.resolution.split('*')
    validate({
      ratio: +(width / height).toFixed(2),
      width,
    })
  }

  useEffect(() => {
    if (value?.url) {
      getMediaInfo(value.url).then(validate)
    }
  }, [])

  return (
    <MediaPicker type="image" multiple={false} onOk={onOk}>
      {({ trigger }) =>
        value?.url ? (
          <div className={cls(s.logo, 0 && s.error)} onClick={trigger}>
            <img src={value.url} />
            <Close
              onClick={e => {
                e.stopPropagation()
                onChange(null)
                form.setFields([{ name, errors: ['Logo is required'] }])
              }}
            />
          </div>
        ) : (
          <span className={s.plus} onClick={trigger}>
            <Plus />
          </span>
        )
      }
    </MediaPicker>
  )
}
