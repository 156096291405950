import Cookies from 'js-cookie'
// import store from '@/store/store'
// import { modifyUser } from '@/store/actions'

const { REACT_APP_PREFIX, NODE_ENV } = process.env

const TOKEN_KEY = REACT_APP_PREFIX + 'user_token'
const BUSINESS_ID_KEY = REACT_APP_PREFIX + 'business_id'
const GROUP_ID_KEY = REACT_APP_PREFIX + 'group_id'

const expires = 90 // 90d
const domain = NODE_ENV === 'production' ? '.cactivate.com' : location.hostname

export function getToken() {
  return Cookies.get(TOKEN_KEY)
}

export function setToken(value) {
  Cookies.set(TOKEN_KEY, value, { expires, domain })
}

export function removeToken(cb) {
  Cookies.remove(TOKEN_KEY, { domain })
  Cookies.remove(BUSINESS_ID_KEY, { domain })
  Cookies.remove(GROUP_ID_KEY, { domain })
  cb?.()
}

export function getBusinessId() {
  return Cookies.get(BUSINESS_ID_KEY)
}

export function setBusinessId(business_id, enforce = true) {
  if (!getBusinessId() || enforce) {
    Cookies.set(BUSINESS_ID_KEY, business_id || '', { expires, domain })
  }
}

export function getGroupId() {
  return Cookies.get(GROUP_ID_KEY)
}

export function setGroupId(group_id, enforce = true) {
  if (!getGroupId() || enforce) {
    Cookies.set(GROUP_ID_KEY, group_id || '', { expires, domain })
  }
  Promise.resolve().then(() => {
    const { default: store } = require('@/store/store')
    const { modifyUser } = require('@/store/actions')
    store.dispatch(
      modifyUser({
        current_campaign_group_id: getGroupId(),
      })
    )
  })
}
