import { request } from './request'

export function fetchOrder(order_id) {
  return request({
    url: 'order/fetch',
    params: { order_id },
  })
}

export function orderInit({ stage_type, platform }) {
  return request({
    url: 'order/init',
    params: { stage_type, platform },
  })
}

export function orderGenerate(params) {
  return request({
    url: 'order/generate',
    params,
  })
}

export function orderSubmit(data) {
  return request({
    method: 'post',
    url: 'order/submit',
    data,
  })
}
