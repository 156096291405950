import React, { useState } from 'react'
import styles from './index.module.scss'
import { useReactive, useRequest } from 'ahooks'
import * as api from '@/service'
import * as _ from '@/utils'
import { Modal, Form, Input, Button } from 'antd'
const { Message } = _

export default ({ children }) => {
  const state = useReactive({})
  const { loading = false, open = false } = state
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    await form.validateFields()
    state.loading = true
    const { data } = await api
      .request({
        url: '3/campaign_group/voice_set',
        method: 'post',
        data: {
          voice: form.getFieldsValue(),
        },
      })
      .finally(() => (state.loading = false))
    if (data?.result) {
      return (state.open = false)
    }
    Message.warn(data?.message ?? 'Submit Fail')
  }

  useRequest(async () => {
    const { data } = await api.request({
      url: '3/campaign_group/voice_get',
    })
    if (data?.voice) {
      form.setFieldsValue(data.voice)
    }
  })

  return (
    <div>
      {children({ state })}
      <Modal
        className={styles.customModal}
        open={open}
        closable={false}
        footer={null}
        centered
        onCancel={() => (state.open = false)}
        width="fit-content"
        destroyOnClose
      >
        <div className={styles.header}>
          Create Assistant
          <span>
            <em>></em>Brand Voice
          </span>
        </div>
        <div className={styles.main}>
          <div className={styles.title}>Configure Brand Voice</div>
          <div className={styles.warp}>
            <Form form={form} layout="vertical">
              <div className={styles.warpLeft}>
                <Form.Item
                  label="Tone of Voice"
                  name="tone"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Tone!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Brand Audience"
                  name="audience"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Tone!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Trademarks to avoid"
                  name="avoid"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter avoid!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className={styles.warpRight}>
                <Form.Item
                  label="Example Brand Headlines"
                  name="headline0"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter headline!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="headline1"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter headline!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Example Brand Primary Text"
                  name="primary_text0"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Primary Text!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="primary_text1"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Primary Text!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
        <div className={styles.btns}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              state.open = false
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            loading={loading}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  )
}
