import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Form, Button } from 'antd'
import cls from 'classnames'
import styles from './index.module.scss'
import ArrowL from 'ico/login/arrow-l.svg'
import ArrowR from 'ico/login/arrow-r.svg'
import CircleL from 'ico/login/circle-l.svg'
import CircleR from 'ico/login/circle-r.svg'
import BackIcon from 'ico/login/back.svg'
import KeyIcon from 'ico/login/key.svg'
import * as api from '@/service/login'

export default () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const onBack = () => {
    step ? setStep(v => v - 1) : history.goBack()
  }
  const onSubmit = async () => {
    setLoading(true)
    const values = form.getFieldsValue(true)
    const request = [
      api.sendVerifyCode,
      api.checkVerifyCode,
      api.resetPassword,
    ][step]
    await request(values).finally(() => setLoading(false))
    step < 2 ? setStep(v => v + 1) : history.push('/chat')
  }

  return (
    <div className={styles.wrap}>
      <Button className={styles.back} onClick={onBack}>
        <BackIcon className="fill-color" />
        Back
      </Button>
      <div className={cls(styles.container, styles.containerSM)}>
        <div className={styles.top}>
          <KeyIcon />
          <div className={styles.title}>Forgot Password</div>
          {step < 2 && (
            <div className={styles.subTitle}>No worries, let's reset it.</div>
          )}
        </div>
        <Form
          className={styles.form}
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
        >
          {step === 0 ? (
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter you email!',
                },
                {
                  type: 'email',
                  message: 'The input is not valid email!',
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          ) : step === 1 ? (
            <>
              <Form.Item
                label="Enter verification code"
                name="check_code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter verification code!',
                  },
                ]}
              >
                <Input placeholder="Enter verification code" />
              </Form.Item>
              <div className={styles.tip}>
                An email has been sent to{' '}
                <span className="primary underline">
                  {form.getFieldValue('email')}
                </span>
              </div>
            </>
          ) : (
            <>
              <Form.Item
                label="Password"
                name="passwd"
                rules={[
                  { required: true, message: 'Please enter your password!' },
                  {
                    min: 8,
                    message: 'The password must be at least 8 characters!',
                  },
                ]}
              >
                <Input.Password placeholder="At least 8 characters" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_passwd"
                dependencies={['passwd']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('passwd') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      )
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Please confirm your password" />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button
              className={styles.submit}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {step ? 'Confirm' : 'Send verification'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <ArrowL className={styles.iconA} />
      <CircleL className={styles.iconB} />
      <ArrowR className={styles.iconC} />
      <CircleR className={styles.iconD} />
    </div>
  )
}
