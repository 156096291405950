/* eslint-disable */

import _axios from './request'
import { mediaInfo, uploadMedia, uploadSubmit, uploadInfo } from './url'

/**
 * get Media
 * @returns
 */
export function fetchMediaInfo() {
  return _axios({
    url: mediaInfo,
  })
}

/**
 * upload img / video
 * @returns
 */
export function fetchUploadMedia(data) {
  return _axios({
    url: uploadMedia,
    method: 'post',
    data,
  })
}


/**
 * upload submit
 * @returns
 */
export function fetchUploadSubmit(data) {
  return _axios({
    url: uploadSubmit,
    method: 'post',
    data,
  })
}
