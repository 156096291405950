import { px2rem } from '@/utils/px2px'
import cls from 'classnames'
import s from './index.module.scss'
import EmptyIcon from 'ico/snapshot/empty.svg'

export default function Empty({ className, text = 'No data yet', iconSize }) {
  return (
    <div className={cls(s.empty, className)}>
      <EmptyIcon
        className={s.emptyImg}
        style={iconSize ? { width: px2rem(iconSize) } : {}}
      />
      <div className={s.emptyText}>{text}</div>
    </div>
  )
}
