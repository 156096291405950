import { uid } from 'uid'

export const mock = {
  1: [
    { type: 'ai', value: 'Target older women' },
    { type: 'age', value: [18, 45] },
    { type: 'gender', value: 50 },
    { type: 'interest', value: [] },
  ],
  2: [
    [
      {
        id: 1,
        url: 'https://m.360buyimg.com/babel/jfs/t1/127065/38/41373/171635/660395f6F187c75cb/8ec11cef6f93d203.jpg',
      },
      {
        id: 2,
        url: 'https://m.360buyimg.com/babel/jfs/t1/130603/9/42635/80321/6618a64fFd5298e6d/07b61e03a15a7034.jpg',
      },
      {
        id: 3,
        url: 'https://m.360buyimg.com/babel/jfs/t1/232140/33/16602/10603/661d04aaF32b6bddc/4898a4cde17743a6.png',
      },
    ],
    [
      {
        id: 4,
        url: 'https://m.360buyimg.com/babel/jfs/t1/136764/6/43736/74626/660274f5Fb19e5a6b/942dac16bb4f9e12.jpg',
      },
    ],
  ],
  3: [
    { type: 'ai', value: 'Target older women' },
    { type: 'headline', value: 'Creating Copy Tests' },
  ],
  4: [{ type: 'ai', value: 'Target older women' }],
  ai: () =>
    Array(5)
      .fill(0)
      .map(() => {
        const id = uid()
        return {
          id,
          type: 'ai',
          value: 'Target older women ' + id,
        }
      }),
}

export const campaignTypes = [
  { value: 1, label: 'Audience Test', title: 'Creating Audience Tests' },
  { value: 2, label: 'Creative Test', title: 'Creating Creative Tests' },
  { value: 3, label: 'Copy Test', title: 'Creating Copy Tests' },
  // { value: 4, label: 'New Theme', title: 'Creating Theme Tests' },
]

export const schema = {
  age: {
    initialValue: [],
    isEmpty: value => !value.length || value.some(v => !v),
    isIllegal: value => value[0] > value[1],
  },
  gender: {
    initialValue: 0,
    isEmpty: () => false,
  },
  interest: {
    initialValue: [],
    isEmpty: value => !value.length,
  },
  keywords: {
    initialValue: [],
    isEmpty: value => !value.length,
  },
  location: {
    initialValue: [],
    isEmpty: value => !value.length,
  },
  youtube: {
    isIllegal: value =>
      !/^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(
        value
      ),
  },
}

export const metaAudienceOpts = [
  { value: 'ai', label: 'AI Instructions' },
  { value: 'age', label: 'Age' },
  { value: 'gender', label: 'Gender' },
  { value: 'interest', label: 'Interests' },
]

export const googleAudienceOpts = [
  { value: 'ai', label: 'AI Instructions' },
  { value: 'keywords', label: 'Keywords' },
  { value: 'location', label: 'Location' },
]

export const metaCopyOpts = [
  { value: 'ai', label: 'AI Instructions' },
  { value: 'headline', label: 'Headline' },
  { value: 'primary_text', label: 'Primary Text' },
  { value: 'description', label: 'Description' },
]

export const googleCopyOpts = [
  { value: 'ai', label: 'AI Instructions' },
  { value: 'headline', label: 'Headline' },
  { value: 'long_headline', label: 'Long Headline' },
  { value: 'description', label: 'Description' },
  // { value: 'youtube', label: 'Youtube Links' },
]

export const CREATIVE_MAX = 10
