import axios from 'axios'
import qs from 'qs'
import {
  showLoadingCat,
  getToken,
  removeToken,
  Message,
  getGroupId,
  getBusinessId,
} from '@/utils'
import store from '@/store/store'
import { modifyUser } from '@/store/actions/user.actions'

const {
  REACT_APP_DASH_URL,
  REACT_APP_LOGIN_URL,
  REACT_APP_API_URL,
  REACT_APP_ENV,
} = process.env
export const ERROR = {
  403: 'Authorization Failed. Missing credentials. Please login again.',
  500: 'Server error, please try again later!',
  timeout: 'Timeout reached. Please try again later.',
  network: 'Network Error',
}

const pathV3 = ['campaign/list', 'group/init/save']

const __DEV__ = process.env.NODE_ENV === 'development'

const _ = axios.create({
  timeoutErrorMessage: ERROR.timeout,
  baseURL: REACT_APP_DASH_URL,
  timeout: 30 * 1000,
  // withCredentials: true,
})

const cancelMethods = {}

const determineBase = path => {
  const newPath = pathV3.find(v => path.includes(v))

  return {
    baseURL: newPath ? REACT_APP_API_URL : REACT_APP_DASH_URL,
    url: newPath ?? path,
    timeout: newPath ? 5 * 60 * 1000 : 30 * 1000,
  }
}

_.interceptors.request.use(
  config => {
    const token = getToken()
    cancelMethods[config.url]?.()
    delete cancelMethods[config.url]
    if (config.cancelable) {
      config.cancelToken = new axios.CancelToken(
        c => (cancelMethods[config.url] = c)
      )
    }

    token && (config.headers.token = token)
    getBusinessId() && (config.headers.businessid = getBusinessId())
    getGroupId() && (config.headers.groupid = getGroupId())

    if (REACT_APP_ENV != 'dev') {
      const { baseURL, timeout, url } = determineBase(config.url)
      config.baseURL = baseURL
      config.timeout = timeout
      config.url = url
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

_.interceptors.response.use(
  response => {
    const {
      data,
      config: { handleError, toastError = true, url, loadingCat },
    } = response

    if (!data) return {} // Message.warn('No Data!')
    if (
      [200, 201].includes(data.code) &&
      (!handleError || (!data.error && data.data?.success !== false))
    )
      return data
    if (data.code === 206) {
      pollTask(data.data, loadingCat)
      return data
    }
    toastError &&
      Message.warn(
        typeof handleError === 'string'
          ? handleError
          : data.message || data.data?.message || ERROR[500]
      )
    return Promise.reject(data)
  },
  err => {
    const data = err?.response?.data

    if (err?.response?.status == 403) {
      if (getToken()) {
        removeToken()
        Message.warn(ERROR[403])
      }
      console.log('%43534')
      // const url = new URL(REACT_APP_LOGIN_URL)
      // url.searchParams.set('redirect', location.href)
      // setTimeout(() => (location.href = url.href), 500)
      // return Promise.reject(err)
    }

    // when token doesnt exist we send to login page
    if ([ERROR.timeout, ERROR.network].includes(err?.message)) {
      Message.warn(err.message)
    } else if (!axios.isCancel(err)) {
      Message.warn(data?.message || ERROR[500])
    }
    return Promise.reject(err)
  }
)

let currentTask

export function setCurrentTask(task) {
  currentTask = task
}

export function pollTask({ task_id, loading_action }) {
  if (currentTask) return currentTask
  let count = 0
  const poll = (resolve, reject) => {
    count++
    request({
      method: 'POST',
      url: '/task/fetch',
      data: { task_id },
    })
      .then(res => {
        if (!res.data) return rejectTask(reject)
        const { task_status, task_info } = res.data
        if (task_status === 0) {
          count < 100
            ? setTimeout(() => poll(resolve, reject), 5000)
            : rejectTask(reject, ERROR.timeout)
        } else if (task_info?.success === false) {
          rejectTask(reject, task_info.message)
          if (task_info?.hard_refresh === true) {
            location.href = '/'
          }
        } else {
          res.data = task_info
          resolve(res)
        }
      })
      .catch(err => rejectTask(reject))
  }

  const close = showLoadingCat(loading_action)
  currentTask = new Promise((resolve, reject) => {
    setTimeout(() => poll(resolve, reject), 5000)
  }).finally(() => {
    close()
    currentTask = null
  })
  return currentTask
}

function rejectTask(reject, message = ERROR[500]) {
  Message.warn(message)
  reject(new Error(message))
}

export default function networkRequest({
  url,
  cancelable = false,
  method = 'get',
  params = null,
  data = null,
  headers = {},
  timeout = 0,
  handleError,
  ...config
}) {
  if (method === 'get')
    return _.get(url, { params, timeout, cancelable, handleError, ...config })
  return _({
    url,
    method,
    cancelable,
    timeout,
    headers,
    data: params !== null ? qs.stringify(params) : data,
    handleError,
    ...config,
  })
}

export function request(config) {
  if (config.handleError === void 0) {
    config.handleError = true
  }
  return _(config)
}

request.ERROR = ERROR
