import {
  useState,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Select, Button, InputNumber, Input, Spin } from 'antd'
import { useRequest } from 'ahooks'
import { MediaPicker, matchResolution } from '@/pages/v3-media-library'
import { GenderSlider } from '@/components/v3-create/core/controls'
import { KeywordsInput } from '@/components/v3-create/core/Google'
import TargetSuggest from '@/components/target-suggest'
import CreationModal from '@/components/v3-create'
import { IMGS_AVAILABLE_FOR_GOOGLE, stages } from '@/utils/constant'
import { useTrackState } from '@/hooks'
import produce from 'immer'
import * as _ from './config'
import { CContext } from '@/context'
import * as api from '@/service'
import { capitalize } from 'lodash'
import Message from '@/utils/message'

import Lottie from 'lottie-react'
import cls from 'classnames'
import robot from '../../robot.json'
import Icons from '../../icons'
import s from './index.module.scss'
import Tag from 'ico/v3-create/tag.svg'
import Plus from 'ico/v3-media/plus.svg'
import AI from 'ico/ai.svg'

const isEmpty = item => {
  const { isEmpty = val => !val } = _.schema[item.type] || {}
  return isEmpty(item.value)
}
const isIllegal = item => {
  const { isIllegal = () => false } = _.schema[item.type] || {}
  return isIllegal(item.value)
}

export default forwardRef(function Precreate(
  {
    isPage = false,
    stage: _s,
    platform: _p,
    onCancel = () => {},
    onChange = () => {},
  },
  ref
) {
  const { ev } = useContext(CContext)
  const [platform, setPlatform] = useState(_p || 'google')
  const [stage, setStage] = useState(_s || stages[0].value)
  const [_type, _setType] = useState()
  const [type, setType] = useState()
  const [baseCampaign, setBaseCampaign] = useState()
  const [items, setItems] = useState()
  const cancel = useRef(() => {})
  const typeName =
    _type === 2
      ? 'theme'
      : _type === 3
      ? 'scratch'
      : [, 'audience', 'creative', 'copy'][type]
  const [disabled, setDisabled] = useState(false)
  const bm = useRef()

  const { data: campaigns = [], loading: loadingCampaigns } = useRequest(
    async () => {
      const { data } = await api.fetchCampaignList()
      return data.map(v => ({ label: v.name, value: v.id }))
    }
  )

  const {
    runAsync: generate,
    loading: generating,
    data: suggestions,
    mutate: setSuggestions,
  } = useRequest(
    async order_id => {
      const { data: ret } = await api.orderGenerate({
        stage_type: stage,
        platform,
        type: typeName,
        base: baseCampaign,
        order_id: order_id || data?.order_id,
      })
      return ret
    },
    { manual: true }
  )

  const { data, loading, run, mutate } = useRequest(
    async () => {
      const { data } = await api.orderInit({ stage_type: stage, platform })
      if (_type === 3) return finish(data.order_id)
      onChange(true)
      generate(data.order_id)
      handleScroll()
      return data
    },
    { manual: true }
  )

  const { run: submit, loading: submitLoading } = useRequest(
    async () => {
      // await new Promise(r => setTimeout(r, 1500))
      await api.orderSubmit({
        stage_type: stage,
        platform,
        type: typeName,
        order_id: data.order_id,
        ideas: items,
      })
      finish(data.order_id)
    },
    { manual: true }
  )

  const reset = () => {
    setItems()
    setSuggestions()
    mutate()
  }

  const finish = order_id => {
    if (isPage) {
      setDisabled(true)
      Message.success('Saved succesfully!')
    } else {
      ev.emit('precreateSuccess', order_id)
    }
  }

  const handleScroll = async (delay = 600) => {
    await new Promise(r => setTimeout(r, delay))
    bm.current.scrollIntoView({
      behavior: 'smooth',
      // block: 'end',
    })
  }

  const disableSubmit =
    disabled ||
    !items?.length ||
    (type === 2
      ? items.some(arr => !arr.length)
      : items.some(item => isEmpty(item) || isIllegal(item)))

  return (
    <div className={s.wrap}>
      <div className={s.group}>
        <Dialog title="What platfrom are you looking --- use?">
          <RadioButtonGroup
            options={['google', 'meta'].map(value => ({
              value,
              label: capitalize(value),
            }))}
            value={platform}
            onChange={setPlatform}
            disabled={!!data || disabled}
          />
        </Dialog>
        <Dialog
          title="What stage of the funnel is this campaign for?"
          showIcon={false}
        >
          <RadioButtonGroup
            options={stages}
            value={stage}
            onChange={setStage}
            disabled={!!data || disabled}
          />
        </Dialog>
        <Dialog
          title="What type of campaigns are you looking to create?"
          showIcon={false}
        >
          <RadioButtonGroup
            options={[
              { value: 1, label: 'New Variant' },
              { value: 2, label: 'New Theme' },
              { value: 3, label: 'Start from scratch' },
            ]}
            value={_type}
            onChange={_setType}
            disabled={!!data || disabled}
          />
        </Dialog>
        {_type && _type !== 2 && (
          <Dialog
            title="What types of campaigns would you like to create?"
            showIcon={false}
          >
            <RadioButtonGroup
              options={_.campaignTypes}
              value={type}
              onChange={setType}
              disabled={!!data || disabled}
            />
          </Dialog>
        )}
        {_type && _type !== 2 && (
          <Dialog title="Please select a base campaign" showIcon={false}>
            <Spin spinning={loadingCampaigns}>
              <Select
                className={s.select}
                popupClassName={s.selectDropdown}
                options={campaigns}
                value={baseCampaign}
                onChange={val => {
                  setBaseCampaign(val)
                  cancel.current()
                  reset()
                }}
                disabled={!!data || disabled}
              />
            </Spin>
          </Dialog>
        )}
        <div className={s.btns}>
          <Button
            style={{ margin: 0 }}
            type="primary"
            disabled={
              (!baseCampaign && _type !== 2) ||
              loading ||
              !!data ||
              (_type === 3 && disabled)
            }
            loading={loading}
            onClick={run}
          >
            Confirm
          </Button>
        </div>
      </div>
      {data ? (
        <div ref={bm}>
          <Dialog
            title={
              _type === 2
                ? 'Creating Theme Tests'
                : _.campaignTypes[type - 1].title
            }
            titleAlign="center"
          >
            <div className={s.strategist}>
              <div className={s.title}>
                <AI />
                AI account strategist
              </div>
              <div className={s.desc}>{data.summary}</div>
              <div className={s.past}>
                <div className={s.title}>Past campaigns</div>
                {type === 2 ? (
                  <div className={s.list}>
                    {data.past_campaigns.map((item, index) => (
                      <div key={item.id} className={cls(s.item, s.lg)}>
                        <span className={s.order}>{index + 1}</span>
                        <div className={s.assets}>
                          {item.assets.map(asset => (
                            <div
                              key={asset.id}
                              className={cls(s.asset, s.readonly)}
                            >
                              <img src={asset.url} />
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={s.list}>
                    {data.past_campaigns.map((item, index) => (
                      <div key={item.id} className={s.item}>
                        <span className={s.order}>{index + 1}</span>
                        {item.idea}
                        <div className={s.highlight}>
                          {item.platform === 'meta' ? (
                            <Icons.Meta />
                          ) : (
                            <Icons.Google />
                          )}
                          {item.name}
                        </div>
                        <div
                          className={cls(s.status, s[item.phase])}
                          style={{ '--progress': `${item.progress * 100}%` }}
                        >
                          <span className="pr">{capitalize(item.phase)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Dialog>
          <Dialog>
            {type === 2 ? (
              <CreativeSuggestions
                platform={platform}
                value={items}
                onChange={setItems}
              />
            ) : (
              <CampaignSuggestions
                loading={generating}
                run={generate}
                type={type}
                platform={platform}
                suggestions={suggestions}
                value={items}
                onChange={setItems}
                onSuggestionsChange={setSuggestions}
              />
            )}
          </Dialog>
        </div>
      ) : null}

      {!!data && (
        <div className={s.btns}>
          {!isPage && <Button onClick={onCancel}>Cancel</Button>}
          <Button
            type="primary"
            disabled={disableSubmit}
            loading={submitLoading}
            onClick={submit}
          >
            Create
          </Button>
        </div>
      )}
    </div>
  )
})

const Dialog = ({ showIcon = true, title, titleAlign = 'left', children }) => (
  <div className={cls(s.dialog, !showIcon && s.noIcon)}>
    {/* <Icons.Robot className={s.avatar} /> */}
    <div className={s.content}>
      {showIcon && <AI />}
      {/* <div className={s.name}>Account Strategist</div> */}
      <div className={s.msg}>
        {title && (
          <div className={s.msgTitle} style={{ textAlign: titleAlign }}>
            {title}
          </div>
        )}
        {children}
      </div>
    </div>
  </div>
)

const CampaignSuggestions = ({
  type,
  platform,
  loading,
  run,
  suggestions = [],
  onSuggestionsChange,
  value = [],
  onChange,
}) => {
  const transport = index => {
    const _suggestions = [...suggestions]
    const _value = value.concat(_suggestions.splice(index, 1))
    onSuggestionsChange(_suggestions)
    onChange(_value)
  }

  const removeGroup = index => {
    onChange(produce(value, draft => void draft.splice(index, 1)))
  }

  const handleChange = (val, idx) => {
    onChange(
      produce(value, draft => {
        draft[idx].value = val
      })
    )
  }

  const handleKeyChange = (key, idx) => {
    onChange(
      produce(value, draft => {
        draft[idx] = {
          type: key,
          value: _.schema[key]?.initialValue ?? '',
        }
      })
    )
  }

  const selectOpts = {
    1: platform === 'meta' ? _.metaAudienceOpts : _.googleAudienceOpts,
    3: platform === 'meta' ? _.metaCopyOpts : _.googleCopyOpts,
    4: _.metaCopyOpts.slice(0, 1),
  }[type]

  return (
    <div className={s.suggestions}>
      <div className={s.theme}>
        <div className={s.title}>
          <Icons.Stars />
          Cactivate AI
        </div>
        <div className={s.list}>
          {suggestions.map((item, index) => (
            <div key={item.id} className={s.item}>
              <span className={s.order}>{index + 1}</span>
              {item.value}
              <Icons.Plus className={s.plus} onClick={() => transport(index)} />
            </div>
          ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            type="text"
            className={s.generate}
            loading={loading}
            onClick={() => run()}
          >
            {!loading ? 'Generate more' : 'Generating...'}
          </Button>
        </div>
      </div>
      <div className={s.detail}>
        <div className={s.title}>Create new campaigns</div>
        <div className={s.list}>
          {value.map((item, index) => (
            <div
              key={index}
              className={cls(s.item, {
                [s.danger]: isEmpty(item) || isIllegal(item),
              })}
            >
              <span className={s.order}>{index + 1}</span>
              <Select
                className={s.miniSelect}
                popupClassName={s.miniSelectPopup}
                open={selectOpts.length > 1 ? undefined : false}
                options={selectOpts}
                value={item.type}
                onChange={val => handleKeyChange(val, index)}
              />
              {[undefined, ''].includes(_.schema[item.type]?.initialValue) && (
                <Input.TextArea
                  autoSize
                  value={item.value}
                  onChange={e => handleChange(e.target.value, index)}
                />
              )}
              {item.type === 'age' && (
                <div className={s.ageRange}>
                  <InputNumber
                    controls={false}
                    min={18}
                    max={65}
                    value={item.value[0]}
                    onChange={val => handleChange([val, item.value[1]], index)}
                  />
                  <em>to</em>
                  <InputNumber
                    controls={false}
                    min={18}
                    max={65}
                    value={item.value[1]}
                    onChange={val => handleChange([item.value[0], val], index)}
                  />
                </div>
              )}
              {item.type === 'gender' && (
                <GenderSlider
                  className={s.genderControl}
                  value={item.value}
                  onChange={val => handleChange(val, index)}
                />
              )}
              {['interest', 'location'].includes(item.type) && (
                <TargetSuggest
                  type={item.type}
                  from={platform}
                  value={item.value}
                  onChange={val => handleChange(val, index)}
                />
              )}
              {item.type === 'keywords' && (
                <div className="c-target-suggest">
                  <KeywordsInput
                    value={item.value}
                    onChange={val => handleChange(val, index)}
                  />
                </div>
              )}
              <div style={{ marginLeft: 'auto' }}>
                {item.type !== 'gender' && (
                  <span
                    className={cls(s.error, {
                      [s.hide]: !isEmpty(item) && !isIllegal(item),
                    })}
                  >
                    {isEmpty(item) ? 'Missing value' : 'Illegal value'}
                  </span>
                )}
                <Icons.Trashcan
                  className={s.trashcan}
                  onClick={() => removeGroup(index)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={s.btnWrap}>
          <button
            className={s.btn}
            onClick={() => onChange(value.concat({ type: 'ai', value: '' }))}
          >
            Add new Audience
          </button>
        </div>
      </div>
    </div>
  )
}

const CreativeSuggestions = ({ platform, value = [], onChange }) => {
  const [activeIdx, setActiveIdx] = useState(0)

  const removeItem = (e, groupIdx) => {
    const { index } = e.target.dataset
    if (!index) return
    onChange(produce(value, draft => void draft[groupIdx].splice(index, 1)))
  }

  const removeGroup = index => {
    onChange(produce(value, draft => void draft.splice(index, 1)))
  }

  const onOk = async medias => {
    onChange(
      produce(value, draft => {
        draft[activeIdx] = draft[activeIdx]
          .concat(medias)
          .slice(0, _.CREATIVE_MAX)
      })
    )
  }

  return (
    <div className={cls(s.suggestions, s.creative)}>
      <div className={s.theme}>
        <div className={s.title}>
          <Icons.Stars />
          Cactivate AI
        </div>
        <div className={s.content}>
          <img src="https://ecompic0.hitv.com/ecom/6965/58f5e6c70391842a8e7dab191549801c.png" />
          <p className="tiny-scrollbar">
            Focus on visuals showing the transformative effects of the product
            on the skin. Use before and after images to showcase skin renewal
            and improvement. Incorporate elements that convey renewal and
            transformation.
          </p>
        </div>
      </div>
      <div className={s.detail}>
        <div className={s.title}>Create new creative tests</div>
        <MediaPicker
          platform={platform}
          accept={platform === 'google' ? IMGS_AVAILABLE_FOR_GOOGLE : undefined}
          defaultSelected={value[activeIdx]}
          onOk={onOk}
        >
          {({ trigger }) => (
            <div className={s.list}>
              {value.map((list, index) => (
                <div
                  key={index}
                  className={cls(s.item, s.lg, !list.length && s.danger)}
                >
                  <span className={s.order}>{index + 1}</span>
                  <div className={s.assets} onClick={e => removeItem(e, index)}>
                    {list.map((item, j) => (
                      <div key={item.id} data-index={j} className={s.asset}>
                        <img src={item.url} />
                      </div>
                    ))}
                    {list.length < _.CREATIVE_MAX && (
                      <span
                        className={s.upload}
                        onClick={() => {
                          setActiveIdx(index)
                          trigger()
                        }}
                      >
                        <Plus />
                      </span>
                    )}
                  </div>
                  <Icons.Trashcan
                    className={s.trashcan}
                    onClick={() => removeGroup(index)}
                  />
                </div>
              ))}
            </div>
          )}
        </MediaPicker>
        <div className={s.btnWrap}>
          <button className={s.btn} onClick={() => onChange([...value, []])}>
            Add new Cactivate
          </button>
        </div>
      </div>
    </div>
  )
}

const RadioButtonGroup = ({ options, disabled, value, onChange }) => {
  return (
    <div className={cls(s.radioButtonGroup, disabled && s.disabled)}>
      {options.map(v => (
        <div
          key={v.value}
          className={cls(s.button, value === v.value && s.active)}
          onClick={() => value !== v.value && !disabled && onChange(v.value)}
        >
          {v.label}
        </div>
      ))}
    </div>
  )
}

export const Campaigns = ({ data, loading }) => {
  const [state, setState] = useState()

  return (
    <div className={s.wrap}>
      {loading && <img src={Icons.Chatloading2} className={s.loader} />}
      {data.map(v => (
        <div keu={v.id} className={s.result}>
          <div className={s.headline}>
            <div className={s.title}>
              <Icons.Stars className="fill-color-strict" />
              Cactivate AI
            </div>
            <div className={s.float}>
              <span className={s.status}>
                <Tag />
                Draft
              </span>
              <span className={s.platform}>
                {v.platform === 'google' ? <Icons.Google /> : <Icons.Meta />}
              </span>
            </div>
          </div>
          <div className={s.item}>
            <span className={s.label}>{v.instruction_type}</span>
            {v.instruction_value}
          </div>
          <div className={s.content}>{v.description}</div>
          <Button type="primary" onClick={() => setState(v)}>
            Open campaign
          </Button>
        </div>
      ))}

      <CreationModal
        mode="create"
        source="chat"
        cid={state?.id}
        status={state?.status}
        platform={state?.platform}
        open={!!state}
        onCancel={() => setState()}
        onOk={({ status }) => {
          setState()
        }}
      />
    </div>
  )
}
