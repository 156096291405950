import { ERROR } from '@/service/request'
import * as api from '@/service'
import { setGroupId } from '@/utils/auth'
import { publish } from '@/subscribe'

export const UPDATE_USER = 'UPDATE_USER'
export const MODIFY_USER = 'MODIFY_USER'
export const UPDATE_BUDGET_STATUS = 'UPDATE_BUDGET_STATUS'
export const UPDATE_BUDGET = 'UPDATE_BUDGET'
export const TOGGLE_IN_USE = 'TOGGLE_IN_USE'
export const TOGGLE_IN_STRIPE = 'TOGGLE_IN_STRIPE'
export const UPDATE_STRIPE = 'UPDATE_STRIPE'
export const UPDATE_STRIPE_ID = 'UPDATE_STRIPE_ID'
export const TOGGLE_OPEN_BUDGET = 'TOGGLE_OPEN_BUDGET'
export const TOGGLE_OPEN_SERVICE_OFF_MODAL = 'TOGGLE_OPEN_SERVICE_OFF_MODAL'
export const TOGGLE_TMP_IN_USE = 'TOGGLE_TMP_IN_USE'
export const TOGGLE_CREATE_MODAL = 'TOGGLE_CREATE_MODAL'
export const UPDATE_GROUPS = 'UPDATE_GROUPS'
export const UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME'
export const SWITCH_GROUP = 'SWITCH_GROUP'
export const VISIBLE_CREATE_CHAT = 'VISIBLE_CREATE_CHAT'

const isStartedGroup = (id, groups) =>
  groups.find(group => group.id === id)?.is_started

export const updateUser = data => {
  return { type: UPDATE_USER, data }
}

export const modifyUser = data => {
  return { type: MODIFY_USER, data }
}

export const visibleCreateChat = data => {
  return { type: VISIBLE_CREATE_CHAT, data }
}

export const updateBudgetStatus = data => {
  return { type: UPDATE_BUDGET_STATUS, data }
}

export const updateBudget = (budget, fetch = true) => {
  if (!fetch) return { type: UPDATE_BUDGET, data: budget }
  return async dispatch => {
    await api.fetchBusinessBudget({ budget })
    dispatch({ type: UPDATE_BUDGET, data: budget })
  }
}

export const toggleOpenBudget = (open, tmpType) => {
  return { type: TOGGLE_OPEN_BUDGET, data: { open, tmpType } }
}

export const toggleInUse = data => ({ type: TOGGLE_IN_USE, data })
export const toggleTmpInUse = data => ({ type: TOGGLE_TMP_IN_USE, data })

export const toggleInStripe = data => ({ type: TOGGLE_IN_STRIPE, data })

export const updateStripe = data => {
  return { type: UPDATE_STRIPE, data }
}

export const updateStripeId = data => {
  return { type: UPDATE_STRIPE_ID, data }
}

export const toggleServiceOffModal = data => ({
  type: TOGGLE_OPEN_SERVICE_OFF_MODAL,
  data,
})

export const toggleCreateModal = data => ({ type: TOGGLE_CREATE_MODAL, data })

export const updateGroups = group_id => {
  return async (dispatch, getState) => {
    const { data } = await api.fetchCampaignGroupList()
    dispatch({ type: UPDATE_GROUPS, data })
    const { current_campaign_group_id: id, default_group_id: _id } =
      getState().user.current
    dispatch(
      modifyUser({
        is_started_group: isStartedGroup(group_id || id || _id, data),
      })
    )
  }
}

export const updateGroupName = (id, name) => {
  return async dispatch => {
    await api.updateCampaignGroupName(id, name)
    setTimeout(() => dispatch({ type: UPDATE_GROUP_NAME, data: { id, name } }))
  }
}

export const updateBusiness = () => {
  return async dispatch => {
    const {
      data: { id, current_campaign_group_id, ...data },
    } = await api.fetchBusiness()
    dispatch(modifyUser(data))
  }
}

export const createGroup = () => async dispatch => {
  const { data } = await api.request({
    url: 'group/init',
  })
  setGroupId(data.id)
  publish({ type: 'group_change', payload: data.id })
  await dispatch(updateGroups())
  // await dispatch(updateBusiness())
  return data.id
}

export const switchGroup = id => async (dispatch, getState) => {
  // await api.switchCampaingGroup(id)
  setGroupId(id)
  publish({ type: 'group_change', payload: id })
  dispatch(
    modifyUser({
      is_started_group: isStartedGroup(
        id,
        getState().user.shared.campaignGroups
      ),
    })
  )
  // await dispatch(updateBusiness())
}
