import { request } from './request'
import sha1 from 'sha1'

export function login({ email, passwd }) {
  return request({
    method: 'POST',
    url: '/user/login',
    data: { email, passwd: sha1(passwd) },
  })
}

export function register({ name, email, passwd }) {
  return request({
    method: 'POST',
    url: '/user/register',
    data: { name, email, passwd: sha1(passwd) },
  })
}

export function sendVerifyCode({ email }) {
  return request({
    method: 'POST',
    url: '/user/forgot_password_init',
    data: { email },
  })
}

export function checkVerifyCode({ email, check_code }) {
  return request({
    method: 'POST',
    url: '/user/forgot_password_confirm',
    data: { email, check_code },
  })
}

export function resetPassword({ email, passwd, check_code }) {
  return request({
    method: 'POST',
    url: '/user/forgot_password_submit',
    data: { email, passwd: sha1(passwd), check_code },
  })
}
