import { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import {
  Spin,
  Form,
  Input,
  InputNumber,
  Button,
  DatePicker,
  Select,
} from 'antd'
import { useRequest, useDebounceFn } from 'ahooks'
import moment, { isMoment } from 'moment'
import { isPlainObject, isArray, isNumber } from 'lodash'
import cls from 'classnames'
import TargetSuggest from '@/components/target-suggest'
import FormList from '../form-list'
import Creatives from '../creatives'
import { RadioButtonGroup, GenderSlider, TargetType } from './controls'
import * as api from '@/service/campaign'
import { fetchPlatformsLoad } from '@/service/onboard-v3'
import capitalize from 'lodash/capitalize'
import { Modal } from '@/utils'

import s from './index.module.scss'
import Check from 'ico/v3-media/check.svg'
import Lamp from 'ico/v3-create/lamp.svg'
import Rocket from 'ico/v3-create/rocket.svg'
import Lock from 'ico/v3-create/lock.svg'
import Warn from 'ico/v3-create/warn.svg'
import Edit from 'ico/v3-create/edit.svg'
import Tag from 'ico/v3-create/tag.svg'
import Meta from 'ico/meta.svg'
import assistant from '@/assets/images/assistant.png'

const noop = () => {}

let pagePromise, pixelPromise

const usePlatformLoad = form => {
  const objective = Form.useWatch('objective', form)
  const lead_type = Form.useWatch('lead_type', form)
  const page_id = Form.useWatch('page_id', form)
  const pixel_id = Form.useWatch('pixel_id', form)
  const isWebsite = lead_type === 'website' || objective === 'sales'
  const isLeadform = lead_type === 'leadform'

  const setInitialVal = field => data => {
    if (!form.getFieldValue(field) && data?.length) {
      form.setFieldValue(field, data[0].value)
    }
    return data
  }

  const { data: pageOpts, loading: pageLoading } = useRequest(() => {
    if (!pagePromise) {
      pagePromise = fetchPlatformsLoad({
        type: 'page',
      })
    }
    return pagePromise.then(setInitialVal('page_id'))
  })
  const { data: instagramOpts, loading: instagramLoading } = useRequest(
    async () =>
      page_id
        ? fetchPlatformsLoad({ type: 'instagram', page_id }).then(
            setInitialVal('instagram_id')
          )
        : instagramOpts,
    { refreshDeps: [page_id], cacheKey: 'load_instagram' }
  )
  const { data: pixelOpts, loading: pixelLoading } = useRequest(
    async () => {
      if (!isWebsite) return pixelOpts
      if (!pixelPromise) {
        pixelPromise = fetchPlatformsLoad({
          type: 'pixel',
        })
      }
      return pixelPromise.then(setInitialVal('pixel_id'))
    },
    { refreshDeps: [objective, lead_type] }
  )
  const { data: eventOpts, loading: eventLoading } = useRequest(
    async () =>
      isWebsite && pixel_id
        ? fetchPlatformsLoad({ type: 'event', pixel_id }).then(
            setInitialVal('pixel_event')
          )
        : eventOpts,
    { refreshDeps: [objective, pixel_id, lead_type], cacheKey: 'load_event' }
  )
  const { data: leadformOpts, loading: leadformLoading } = useRequest(
    async () => {
      return isLeadform && page_id
        ? fetchPlatformsLoad({ type: 'leadform', page_id }).then(
            setInitialVal('leadform_id')
          )
        : leadformOpts
    },
    { refreshDeps: [page_id, lead_type], cacheKey: 'load_leadform' }
  )

  return {
    isWebsite,
    isLeadform,
    pageOpts,
    pageLoading,
    instagramOpts,
    instagramLoading,
    pixelOpts,
    pixelLoading,
    eventOpts,
    eventLoading,
    leadformOpts,
    leadformLoading,
  }
}

const mapStatus = status => {
  const map = {
    running: 'active',
    pause: 'paused',
    error: 'error',
    draft: 'draft',
  }
  return map[status] || status
}

export const MetaCreation = ({
  source,
  status = 'draft',
  cid,
  thread,
  copy = false,
  imported,
  hasAutoSaved,
  onOk = noop,
  onCancel = noop,
  modal = true,
}) => {
  const { min_meta_budget, currency_symbol } = useSelector(
    state => state.user.current
  )
  const [form] = Form.useForm()
  const media = Form.useWatch(['creative', 'media'], form) || []
  const objective = Form.useWatch('objective', form)
  const lead_type = Form.useWatch('lead_type', form)
  const asset_suggestion = Form.useWatch('asset_suggestion', form)
  const budget = Form.useWatch('budget', form)
  const today = useRef(moment()).current
  const [suggestion, setSuggestion] = useState(
    {} /* {
    suggestion_type: 'factor', // idea | factor
    suggestion_text: 'Test Age',
    suggestion_message: 'Editing an active ad might reset your learning phase.',
    suggestion_highlight: 'active ad',
    suggestion_factor: 'text', // age | gender | interest | location
  } */
  )
  const [scheduleManual, setScheduleManual] = useState(0)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const {
    isWebsite,
    isLeadform,
    pageOpts,
    pageLoading,
    instagramOpts,
    instagramLoading,
    pixelOpts,
    pixelLoading,
    eventOpts,
    eventLoading,
    leadformOpts,
    leadformLoading,
  } = usePlatformLoad(form)

  if (process.env.NODE_ENV === 'development') {
    window.form = form
  }

  const isDraft = ['draft', 'idea'].includes(status)

  const [spinning, setSpinning] = useState(false)
  const initialData = useRef()
  useEffect(() => {
    if (cid || thread) {
      setSpinning(true)
      api
        .fetchCampaignDetail(
          Object.assign(
            {},
            cid ? { id: cid } : thread,
            source === 'chat' && { skip: true }
          )
        )
        .then(({ data }) => {
          if (copy) delete data.id
          initialData.current = data
          form.setFieldsValue(data)
          onValuesChange()
          data.suggestion && setSuggestion(data.suggestion)
          setScheduleManual(data.schedule?.startDate ? 1 : 0)
        })
        .finally(() => setSpinning(false))
    }
    return cancelAutoSave
  }, [])

  const [showToast, setShowToast] = useState(false)
  const { run: autoSave, cancel: cancelAutoSave } = useDebounceFn(
    async () => {
      const values = form.getFieldsValue()
      if (!values.name || submitFlag) return
      values.description = suggestion?.suggestion_message
      const { data } = await api.saveDraft(values)
      form.setFieldValue('id', data.id)
      hasAutoSaved && (hasAutoSaved.current = true)
      setShowToast(true)
    },
    {
      wait: 3000,
    }
  )

  const [submitFlag, setSubmitFlag] = useState('')
  /**
   * @param {'submit' | 'run'} flag
   */
  const handleSubmit = async flag => {
    cancelAutoSave()
    const values = form.getFieldsValue()
    values.description = suggestion?.suggestion_message
    setSubmitFlag(flag)
    await api.submitCampaign(values).finally(() => setSubmitFlag(''))
    !modal && setDisableSubmit(true)
    onOk()
  }

  const [pass, setPass] = useState([false, false, false, false])
  const hasError = nameList => {
    return form.getFieldsError(nameList).some(item => item.errors.length)
  }
  const isEmpty = value => [null, undefined, ''].includes(value)
  const hasEmpty = (values, areEmpty) => {
    return Object.entries(values).some(([key, value]) => {
      if (areEmpty?.[key]) return areEmpty[key](value)
      if (isMoment(value)) return false
      if (isPlainObject(value)) return hasEmpty(value, areEmpty)
      if (isArray(value)) return !value.length || hasEmpty(value)
      return isEmpty(value)
    })
  }
  const isPass = (nameList, areEmpty) =>
    !hasError(nameList) && !hasEmpty(form.getFieldsValue(nameList), areEmpty)
  const onValuesChange = async value => {
    await new Promise(r => setTimeout(r))
    const { objective, lead_type } = form.getFieldsValue([
      'objective',
      'lead_type',
    ])
    const p1 = isPass([
      'name',
      'link',
      'page_id',
      'instagram_id',
      ...(objective === 'sales' ||
      (objective === 'lead' && lead_type === 'website')
        ? ['pixel_id', 'pixel_event']
        : objective === 'lead' && lead_type === 'leadform'
        ? ['leadform_id']
        : []),
      ...(scheduleManual ? [['schedule', 'startDate']] : []),
    ])
    const p2 = isPass(
      [
        // 'audience',
        ['age', 'min'],
        ['age', 'max'],
        // 'interest',
        'location',
      ],
      {
        interest: value => !value?.length,
        location: value => !value?.length,
      }
    )
    const p3 = isPass([['creative', 'media']], {
      media: value => !value?.length,
    })
    const p4 = isPass([
      ['creative', 'headline'],
      ['creative', 'primary_text'],
      ['creative', 'description'],
    ])
    setPass([p1, p2, p3, p4])
    isDraft && value && autoSave()
  }

  const is = factor => suggestion.suggestion_factor === factor

  const disableDate = name => {
    const value = initialData.current?.schedule[name]
    return !isDraft && value && moment().isAfter(value, 'd')
  }

  const _onCancel = async () => {
    if (submitFlag === 'submit') {
      await Modal.confirm({
        type: 'delete',
        title: 'Are you sure to leave?',
        subtitle: 'Your changes may not have been saved',
      })
    }
    onCancel()
  }

  return (
    <Spin spinning={spinning} size="large">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
      >
        <div className={cls(s.wrap, 'nice-scrollbar', imported && s.imported)}>
          <CSSTransition
            in={showToast}
            classNames={{
              enter: 'animate__animated',
              enterActive: 'animate__fadeInDown',
              exit: 'animate__animated',
              exitActive: 'animate__fadeOut',
            }}
            timeout={500}
            mountOnEnter
            unmountOnExit
            onEntered={() => setTimeout(() => setShowToast(false), 2000)}
          >
            <div className={s.toastWrap}>
              <div className={s.toast}>
                <Check />
                Autosaved draft
              </div>
            </div>
          </CSSTransition>
          <div className={s.header}>
            <span className={s.platform}>
              <Meta />
            </span>
            <div className={cls(s.status, s[mapStatus(status)])}>
              <Tag className="fill-color-strict" />
              <span>{capitalize(mapStatus(status))}</span>
            </div>
          </div>
          <div className={cls(s.body)}>
            <div className={s.content}>
              <div className={s.main}>
                <div className={s.left}>
                  <div className={s.block}>
                    <div className={s.source}>
                      <Check className={cls(s.pass, !pass[0] && s.unready)} />
                      Campaign Setup:
                    </div>
                    <Form.Item noStyle name="id">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      label="Campaign Name:"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Name is required',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Objective:"
                      name="objective"
                      initialValue="sales"
                    >
                      <RadioButtonGroup
                        options={[
                          { value: 'sales', label: 'Sales' },
                          { value: 'lead', label: 'Lead' },
                          { value: 'traffic', label: 'Traffic' },
                        ]}
                        disabled={!isDraft}
                      />
                    </Form.Item>
                    {objective === 'lead' && (
                      <Form.Item
                        label="Type:"
                        name="lead_type"
                        initialValue="website"
                      >
                        <RadioButtonGroup
                          options={[
                            { value: 'website', label: 'Website' },
                            { value: 'leadform', label: 'Lead Form' },
                          ]}
                          disabled={!isDraft}
                        />
                      </Form.Item>
                    )}
                    <Form.Item noStyle name="type" initialValue="standard">
                      <Input type="hidden" />
                    </Form.Item>
                    {/* <Form.Item name="type" initialValue="standard">
                      <CampaignType disabled={!isDraft} />
                    </Form.Item> */}
                    <Form.Item
                      label="Link:"
                      name="link"
                      rules={[
                        {
                          required: true,
                          type: 'url',
                          message: 'Link is invalid',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Spin spinning={pageLoading}>
                      <Form.Item
                        label="Page ID:"
                        name="page_id"
                        rules={[
                          { required: true, message: 'Page ID is required' },
                        ]}
                      >
                        <Select
                          popupClassName="custom-select-dropdown"
                          dropdownStyle={{ zIndex: 1052 }}
                          options={pageOpts}
                          onChange={() => {
                            form.setFieldValue('instagram_id', '')
                            isLeadform && form.setFieldValue('leadform_id', '')
                          }}
                          disabled={!isDraft}
                        />
                      </Form.Item>
                    </Spin>
                    <Spin spinning={instagramLoading}>
                      <Form.Item
                        label="Instagram ID:"
                        name="instagram_id"
                        rules={[
                          {
                            required: true,
                            message: 'Instagram ID is required',
                          },
                        ]}
                      >
                        <Select
                          popupClassName="custom-select-dropdown"
                          dropdownStyle={{ zIndex: 1052 }}
                          options={instagramOpts}
                          disabled={!isDraft}
                        />
                      </Form.Item>
                    </Spin>
                    {objective === 'sales' ||
                    (objective === 'lead' && lead_type === 'website') ? (
                      <>
                        <Spin spinning={pixelLoading}>
                          <Form.Item
                            label="Pixel:"
                            name="pixel_id"
                            rules={[
                              {
                                required: true,
                                message: 'Pixel is required',
                              },
                            ]}
                          >
                            <Select
                              popupClassName="custom-select-dropdown"
                              dropdownStyle={{ zIndex: 1052 }}
                              options={pixelOpts}
                              onChange={() =>
                                form.setFieldValue('pixel_event', '')
                              }
                              disabled={!isDraft}
                            />
                          </Form.Item>
                        </Spin>
                        <Spin spinning={eventLoading}>
                          <Form.Item
                            label="Event:"
                            name="pixel_event"
                            rules={[
                              {
                                required: true,
                                message: 'Event is required',
                              },
                            ]}
                          >
                            <Select
                              popupClassName="custom-select-dropdown"
                              dropdownStyle={{ zIndex: 1052 }}
                              options={eventOpts}
                              disabled={!isDraft}
                            />
                          </Form.Item>
                        </Spin>
                      </>
                    ) : objective === 'lead' && lead_type === 'leadform' ? (
                      <Spin spinning={leadformLoading}>
                        <Form.Item
                          label="Lead Form:"
                          name="leadform_id"
                          rules={[
                            {
                              required: true,
                              message: 'Lead Form is required',
                            },
                          ]}
                        >
                          <Select
                            popupClassName="custom-select-dropdown"
                            dropdownStyle={{ zIndex: 1052 }}
                            options={leadformOpts}
                          />
                        </Form.Item>
                      </Spin>
                    ) : null}
                    {/* <Form.Item label="Schedule:">
                      <RadioButtonGroup
                        disabled={!isDraft}
                        options={[
                          { value: 0, label: 'Let AI Decide' },
                          { value: 1, label: 'Set Start & End Date' },
                        ]}
                        value={scheduleManual}
                        onChange={setScheduleManual}
                      />
                    </Form.Item> */}
                    <Form.Item label="Schedule:" className={s.rangePicker}>
                      <Form.Item
                        className={s.pickerWrap}
                        name={['schedule', 'startDate']}
                        initialValue={today}
                      >
                        <DatePicker
                          placeholder="Start Date"
                          disabledDate={current =>
                            current &&
                            ((form.getFieldValue(['schedule', 'endDate']) &&
                              current >
                                form.getFieldValue(['schedule', 'endDate'])) ||
                              current < today)
                          }
                          getPopupContainer={() =>
                            document.querySelector(`.${s.body}`)
                          }
                          allowClear={false}
                          disabled={disableDate('startDate')}
                        />
                      </Form.Item>
                      <Form.Item
                        className={s.pickerWrap}
                        name={['schedule', 'endDate']}
                      >
                        <DatePicker
                          placeholder="End Date"
                          disabledDate={current =>
                            current &&
                            (current <
                              form.getFieldValue(['schedule', 'startDate']) ||
                              current < today)
                          }
                          getPopupContainer={() =>
                            document.querySelector(`.${s.body}`)
                          }
                          disabled={disableDate('endDate')}
                        />
                      </Form.Item>
                    </Form.Item>
                  </div>

                  <div className={s.block}>
                    <Check className={cls(s.pass, !pass[1] && s.unready)} />
                    <Form.Item
                      className={s.inline}
                      label="Audience:"
                      // name="audience"
                      // rules={[
                      //   { required: true, message: 'Audience is required' },
                      // ]}
                    >
                      {suggestion.suggestion_type === 'factor' && !is('text') && (
                        <div className={s.factor}>
                          <Rocket />
                          {suggestion.suggestion_text}
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item
                      className={is('age') && s.highlight}
                      label="Age:"
                      style={{ marginBottom: 0 }}
                    >
                      <Form.Item
                        className={s.ageInput}
                        name={['age', 'min']}
                        initialValue={18}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value)
                                return Promise.reject(
                                  new Error('Min age is required')
                                )
                              if (+getFieldValue(['age', 'max']) < +value) {
                                return Promise.reject(
                                  new Error('Min age <= Max age')
                                )
                              }
                              if (+value < 18)
                                return Promise.reject(
                                  new Error('Min age >= 18')
                                )
                              return Promise.resolve()
                            },
                          }),
                        ]}
                      >
                        <InputNumber controls={false} />
                      </Form.Item>
                      <span className={s.to}>to</span>
                      <Form.Item
                        className={s.ageInput}
                        name={['age', 'max']}
                        initialValue={65}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value)
                                return Promise.reject(
                                  new Error('Max age is required')
                                )
                              if (+getFieldValue(['age', 'min']) > +value) {
                                return Promise.reject(
                                  new Error('Max age >= Min age')
                                )
                              }
                              if (+value > 65)
                                return Promise.reject(
                                  new Error('Max age <= 65')
                                )
                              return Promise.resolve()
                            },
                          }),
                        ]}
                      >
                        <InputNumber controls={false} />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      className={is('gender') && s.highlight}
                      label="Gender:"
                      name="gender_ratio"
                      initialValue={50}
                    >
                      <GenderSlider />
                    </Form.Item>
                    <Form.Item
                      className={is('interest') && s.highlight}
                      label="Interests:"
                      name="interest"
                    >
                      <TargetSuggest type="interest" />
                    </Form.Item>
                    <Form.Item
                      className={is('location') && s.highlight}
                      label="Location:"
                      name="location"
                      rules={[
                        { required: true, message: 'Location is required' },
                      ]}
                    >
                      <TargetSuggest type="location" />
                    </Form.Item>
                    {/* {type === 'retargeting' && (
                      <Form.Item
                        name="retargeting_type"
                        rules={[
                          {
                            required: true,
                            message: 'Please Choose Target Audience!',
                          },
                        ]}
                      >
                        <TargetType />
                      </Form.Item>
                    )} */}
                  </div>
                </div>
                <div className={s.right}>
                  <div className={s.block}>
                    <Check className={cls(s.pass, !pass[2] && s.unready)} />
                    <Form.Item noStyle name="asset_suggestion" />
                    <Form.Item
                      className={s.creatives}
                      label={`Creatives: ${media.length} of 10 max`}
                      name={['creative', 'media']}
                      rules={[
                        {
                          required: true,
                          message: 'At least one creative is required',
                        },
                      ]}
                    >
                      <Creatives
                        platform="meta"
                        suggestion={asset_suggestion}
                      />
                    </Form.Item>
                  </div>
                  <div className={s.block}>
                    <Check className={cls(s.pass, !pass[3] && s.unready)} />
                    <Form.Item
                      className={cls(
                        'copy',
                        s.creatives,
                        is('text') && 'highlight'
                      )}
                      label={
                        <div className="flex-ac">
                          <span style={{ marginRight: 8 }}>
                            Copy (Up to 5 each)
                          </span>
                          {suggestion.suggestion_type === 'factor' &&
                            is('text') && (
                              <div className={s.factor}>
                                <Rocket />
                                {suggestion.suggestion_text}
                              </div>
                            )}
                        </div>
                      }
                    >
                      <FormList
                        label="Headline:"
                        name={['creative', 'headline']}
                        itemRules={[
                          {
                            required: true,
                            message: 'Headline is required',
                          },
                        ]}
                      >
                        <Input />
                      </FormList>
                      <FormList
                        label="Primary Text:"
                        name={['creative', 'primary_text']}
                        itemRules={[
                          {
                            required: true,
                            message: 'Primary text is required',
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </FormList>
                      <FormList
                        label="Description:"
                        name={['creative', 'description']}
                        itemRules={[
                          {
                            required: true,
                            message: 'Description is required',
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </FormList>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className={s.btnWrap}>
                {modal && (
                  <Button className={s.cancelBtn} onClick={_onCancel}>
                    Cancel
                  </Button>
                )}
                {isDraft ? (
                  <div className={cls(s.run)}>
                    <Form.Item
                      name="budget"
                      noStyle
                      initialValue={min_meta_budget}
                    >
                      <InputNumber
                        controls={false}
                        addonBefore={<Lock />}
                        formatter={value => value && currency_symbol + value}
                        parser={value => value.replace(currency_symbol, '')}
                      />
                    </Form.Item>
                    <Button
                      className={s.runBtn}
                      type="primary"
                      loading={submitFlag === 'submit'}
                      disabled={
                        !(pass.every(Boolean) && budget >= min_meta_budget) ||
                        disableSubmit
                      }
                      onClick={() => handleSubmit('submit')}
                    >
                      Submit
                    </Button>
                    {isNumber(budget) && budget < min_meta_budget && (
                      <div className={s.runErr}>
                        <Warn />
                        <span>
                          Minimum budget is {currency_symbol}
                          {min_meta_budget}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <Button
                    className={s.queneBtn}
                    type="primary"
                    loading={submitFlag === 'submit'}
                    disabled={!pass.every(Boolean) || disableSubmit}
                    onClick={() => handleSubmit('submit')}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  )
}
